import React from 'react';
import BaseText from '../ui/typography/BaseText';
import H3HeaderText from '../ui/typography/H3HeaderText';
import CheckCircle from '../../images/icons/check-circle.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import Image from '../../images/graphics/mobileApp/detailedCta.png';
import PropTypes from 'prop-types';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import '../../styles/detailed-cta.scss';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function DetailedCTA({
  title,
  subTitle,
  description,
  isPointsRequired,
  backgroundColor,
  subTitleColor,
  titleColor,
  CTAImage,
  buttonType,
  buttonBackgroundColor,
  isButtonBorderReqiured,
  buttonCaptionColor,
  buttonCaption,
  buttonFontWeight,
  isCtaLayoutTwo,
  url,
  justifyImage,
  fromCompanyCreationProductPage,
  backgroundColorImage,
  ctaPoints,
  width,
  height,
  CTAImageClassName,
  buttonMargin
}) {
  const image = {
    url: CTAImage,
    width: width,
    height: height
  };
  const image2 = {
    url: Image,
    width: 431,
    height: 416
  };
  return (
    <>
      <div
        className={`flex md:flex-row flex-col w-full mt-10 ${backgroundColor} rounded-lg overflow-hidden md:max-w-6xl w-full mx-auto`}
      >
        <div className={`w-full flex flex-col`}>
          <div className="md:py-12 py-6 px-5 md:px-10 h-full">
            <H3HeaderText
              title={title}
              className="md:whitespace-pre-line"
              fontWeight="text-bold"
              textColor={titleColor}
            />

            {subTitle && (
              <MDSubtitleText
                className="mt-4 whitespace-pre-line"
                fontWeight="text-medium"
                textColor={subTitleColor}
                title={<div dangerouslySetInnerHTML={{ __html: subTitle }}></div>}
              />
            )}
            {isPointsRequired && (
              <div className="flex flex-col">
                {ctaPoints?.map((point, index) => {
                  return (
                    <div className="flex flex-row md:items-center mt-4" key={index}>
                      <div className={`${point?.icon ? 'md:mr-4 my-2' : 'home-page-cta-icon'}`}>
                        {point?.icon ? (
                          point?.icon
                        ) : (
                          <img src={CheckCircle} width="23" height="23" loading="lazy" alt="CheckCircle" />
                        )}
                      </div>
                      <div className="flex flex-col">
                        <BaseText title={point.text} className="ml-2 md:whitespace-pre-line" textColor="text-white" />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={`flex flex-col md:items-start items-center ${!isPointsRequired && 'mt-10'}`}>
              <PrimaryButton
                urlLink={url}
                className={`${buttonMargin} open-account-btn`}
                caption={buttonCaption}
                bgColor={buttonBackgroundColor}
                color={buttonCaptionColor}
                fontWeight={buttonFontWeight}
                type={buttonType}
                isBorderRequired={isButtonBorderReqiured}
                fromCompanyCreationProductPage={fromCompanyCreationProductPage}
              />
              {description && <XSText title={description} className="mt-3" />}
            </div>
          </div>
        </div>
        <div className="relative md:block hidden">
          <div className={`${backgroundColorImage} md:block hidden`}></div>
          {isCtaLayoutTwo || CTAImage ? (
            <>
              <div className="pt-11">
                <GatsbyImageWrapper
                  layout="FIXED"
                  image={image}
                  alt="CTAImageh"
                  objectFit="contain"
                  className={CTAImageClassName}
                />
              </div>
            </>
          ) : (
            <div className={`${justifyImage} pt-10`}>
              <GatsbyImageWrapper
                image={image2}
                className="registration-cta-image w-full"
                objectFit="contain"
                loading="lazy"
                alt="content image"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

DetailedCTA.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.any,
  description: PropTypes.string,
  isPointsRequired: PropTypes.bool,
  backgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  subTitleColor: PropTypes.string,
  CTAImage: PropTypes.any,
  buttonType: PropTypes.bool,
  buttonBackgroundColor: PropTypes.string,
  isButtonBorderReqiured: PropTypes.bool,
  buttonCaptionColor: PropTypes.string,
  buttonCaption: PropTypes.string,
  buttonFontWeight: PropTypes.string,
  isCtaLayoutTwo: PropTypes.bool,
  url: PropTypes.string,
  justifyImage: PropTypes.string,
  fromCompanyCreationProductPage: PropTypes.bool,
  backgroundColorImage: PropTypes.string,
  ctaPoints: PropTypes.array,
  CTAImageClassName: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  buttonMargin: PropTypes.string
};

DetailedCTA.defaultProps = {
  title: `Get your Hong Kong business \naccount open in a few days`,
  isPointsRequired: true,
  backgroundColor: 'bg-coral-500',
  titleColor: 'text-white',
  subTitleColor: 'text-green-500',
  CTAImage: Image,
  buttonType: '',
  buttonBackgroundColor: 'bg-white',
  isButtonBorderReqiured: false,
  buttonCaptionColor: 'text-coral-500',
  buttonCaption: 'Open account',
  buttonFontWeight: 'text-bold',
  isCtaLayoutTwo: false,
  url: process.env.SIGN_UP,
  justifyImage: 'justify-center',
  fromCompanyCreationProductPage: 'false',
  ctaPoints: [
    {
      id: 0,
      text: '100% online application'
    },
    {
      id: 1,
      text: 'No account opening fee, no initial deposit'
    },
    {
      id: 2,
      text: 'Physical and virtual cards'
    }
  ],
  CTAImageClassName: 'registration-cta-image',
  width: '431',
  height: '410',
  buttonMargin: 'mt-32'
};
