import { Link } from 'gatsby';
import React from 'react';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import IconLinkedIn from '../../images/icons/computer-logo-linkedin.svg';
import IconEmailAction from '../../images/icons/email-action-at.svg';
import { stringTruncate } from '../../helper/helpers';
import PropTypes from 'prop-types';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function AuthorDetails({ user, className }) {
  return (
    <div className={`${className} p-8`}>
      <div className="flex justify-between">
        <div className="flex">
          <Link to={`/team/${user?.document?.uid}`} className="md:mr-4 mr-2 h-16">
            <GatsbyImageWrapper
              image={{ url: user?.document?.data?.avatar?.url, width: 64, height: 64 }}
              className="rounded-full blog-author-img"
              alt="author img"
              loading="eager"
            />
          </Link>
          <div className="flex items-center">
            <Link to={`/team/${user?.document?.uid}`}>
              <div className="text-2xl text-bold leading-5">{user?.document?.data?.full_name?.text}</div>
              <div className="text-xl leading-5 my-1 ">{`${user?.document?.data?.role?.text}`}</div>
            </Link>
          </div>
        </div>
        <div className="flex gap-2 mt-2">
          <a href={user?.document?.data?.email.url}>
            <img src={IconEmailAction} className="h-6 w-6" alt="emil icon" />
          </a>
          <a href={user?.document?.data?.linkedin.url} target="_blank" rel="noopener noreferrer">
            <img src={IconLinkedIn} className="h-6 w-6" alt="linkedin icon" />
          </a>
        </div>
      </div>
      <hr className="border-t-1 border-gray-400 my-5" />
      <XXSText title="Bio" fontWeight="text-bold" className="mb-3" />
      <XXSText title={stringTruncate(user?.document?.data?.bio?.text, 300)} />
      <Link to={`/team/${user?.document?.uid}`}>
        <BaseText className="mt-4" textColor="text-coral-500" fontWeight="text-bold underline" title="Read full Bio" />
      </Link>
    </div>
  );
}

AuthorDetails.propTypes = {
  user: PropTypes.any,
  className: PropTypes.string
};
