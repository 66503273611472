import React from 'react';
import PropTypes from 'prop-types';

const PText = ({ title, className, fontWeight, fontSize, textColor, textAlign, letterSpacing, lineHeight, style }) => {
  return (
    <p
      className={`${className} ${fontWeight}  ${textColor} ${fontSize} ${textAlign} ${letterSpacing} ${lineHeight}`}
      style={style}
    >
      {title}
    </p>
  );
};

export default PText;

PText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
  style: PropTypes.object
};

PText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-medium',
  fontSize: 'text-xl',
  letterSpacing: 'tracking-[-0.5px]',
  lineHeight: 'leading-[100%]'
};
