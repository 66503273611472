import React, { useEffect, useState } from 'react';
import BlogArticleHeaderInfo from './BlogArticleHeaderInfo';
import PropTypes from 'prop-types';
import { heightOfElement, pxToVh, getLocalStorageValue } from '../../helper/helpers';

const BlogArticleHeader = ({ data }) => {
  const [heightShouldSubtract, setHeightShouldSubtract] = useState(0);

  useEffect(() => {
    const isBannerHidden = getLocalStorageValue('isBannerHidden');
    const bannerHeight = pxToVh(heightOfElement('announcement-banner'));
    const topNavigationHeight = pxToVh(heightOfElement('top-navigation'));
    if (isBannerHidden === null) {
      setHeightShouldSubtract(topNavigationHeight);
    } else {
      setHeightShouldSubtract(bannerHeight + topNavigationHeight);
    }
  }, []);

  const containerStyle = {
    height: `calc(100vh - ${heightShouldSubtract}vh)`
  };

  return (
    <div className="relative">
      <img
        src={data?.report_cover_image?.url ? data?.report_cover_image?.url : data?.meta_image?.url}
        alt="blog header image"
        className="w-full object-cover object-bottom"
        style={containerStyle}
      />
      <BlogArticleHeaderInfo post={data} />
    </div>
  );
};

export default BlogArticleHeader;

BlogArticleHeader.propTypes = {
  data: PropTypes.object
};
