import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import IconArrowUp from '../../../images/icons/black-arrow-up.svg';
import IconArrowUp2 from '../../../images/icons/coral-arrow-up.svg';
import BaseText from '../../ui/typography/BaseText';
import H3HeaderText from '../typography/H3HeaderText';
import XSText from '../typography/XSText';
export default function ReadMoreLink({
  uid,
  title,
  textColor,
  className,
  blog,
  fontWeight,
  fontSize,
  textAlign,
  hideArrow,
  style
}) {
  const [handleLink, setHandleLink] = useState(false);
  return (
    <Link to={blog ? `/blog/${uid}` : uid}>
      <div
        onMouseEnter={() => setHandleLink(true)}
        onMouseLeave={() => setHandleLink(false)}
        className={`flex items-center ${fontSize ? 'mt-0' : 'mt-4'} ${className}`}
      >
        {fontSize === 'H3' ? (
          <H3HeaderText
            className="w-full"
            fontWeight={fontWeight}
            textColor={` ${handleLink ? 'text-coral-500' : textColor} `}
            title={
              <div className="w-full">
                <Link to={blog ? `/blog/${uid}` : uid}>{title}</Link>
                {!hideArrow && (
                  <button>
                    <img
                      src={handleLink ? IconArrowUp2 : textColor == 'text-coral-500' ? IconArrowUp2 : IconArrowUp}
                      className={`${
                        handleLink
                          ? `transform rotate-45 ml-6 ${textColor == 'text-coral-500' ? 'ml-3 h-4 w-h' : 'w-3 h-3'}`
                          : 'ml-3 h-4 w-4'
                      }  `}
                      alt="arrow icon"
                      loading="lazy"
                      width="16"
                      height="16"
                    />
                  </button>
                )}
              </div>
            }
          />
        ) : fontSize === 'XS' ? (
          <XSText
            fontWeight={fontWeight}
            textColor={` ${handleLink ? 'text-coral-500' : textColor} `}
            title={
              <div>
                <Link to={blog ? `/blog/${uid}` : uid}>{title}</Link>
                {!hideArrow && (
                  <button>
                    <img
                      src={handleLink ? IconArrowUp2 : textColor == 'text-coral-500' ? IconArrowUp2 : IconArrowUp}
                      className={`${
                        handleLink
                          ? `transform rotate-45 ml-6 ${textColor == 'text-coral-500' ? 'ml-3 h-3 w-3' : 'w-3 h-3'}`
                          : 'ml-3 h-3 w-3'
                      }  `}
                      alt="arrow icon"
                      width="16"
                      height="16"
                    />
                  </button>
                )}
              </div>
            }
          />
        ) : (
          <BaseText
            fontWeight={fontWeight}
            textColor={` ${handleLink ? 'text-coral-500' : textColor} `}
            textAlign={textAlign}
            style={style}
            title={
              <div>
                <Link to={blog ? `/blog/${uid}` : uid}>{title}</Link>
                {!hideArrow && (
                  <button>
                    <img
                      src={handleLink ? IconArrowUp2 : textColor == 'text-coral-500' ? IconArrowUp2 : IconArrowUp}
                      className={`${
                        handleLink
                          ? `transform rotate-45 ml-6 ${textColor == 'text-coral-500' ? 'ml-3 h-4 w-h' : 'w-3 h-3'}`
                          : 'ml-3 h-4 w-4'
                      }  `}
                      alt="arrow icon"
                      width="16"
                      height="16"
                    />
                  </button>
                )}
              </div>
            }
          />
        )}
      </div>
    </Link>
  );
}

ReadMoreLink.propTypes = {
  uid: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  fontWeight: PropTypes.string,
  blog: PropTypes.bool,
  fontSize: PropTypes.string,
  textAlign: PropTypes.string,
  hideArrow: PropTypes.string,
  style: PropTypes.any
};
ReadMoreLink.defaultProps = {
  title: 'Read more',
  textColor: 'text-gray-500',
  fontWeight: 'text-bold'
};
