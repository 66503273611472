import React from 'react';

function Monitor() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_4287_5932)">
        <path
          d="M14.1543 21.0328H7.5918L8.24805 17.0953H13.498L14.1543 21.0328Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.62305 21.0328H16.123"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.0293 13.1578H20.7168"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9668 1.34534H2.7793C1.8128 1.34534 1.0293 2.12884 1.0293 3.09534V15.3453C1.0293 16.3118 1.8128 17.0953 2.7793 17.0953H18.9668C19.9333 17.0953 20.7168 16.3118 20.7168 15.3453V3.09534C20.7168 2.12884 19.9333 1.34534 18.9668 1.34534Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.62305 10.5328V7.47034C5.62305 7.23827 5.71523 7.01571 5.87933 6.85162C6.04342 6.68752 6.26598 6.59534 6.49805 6.59534H15.248C15.4801 6.59534 15.7027 6.68752 15.8668 6.85162C16.0309 7.01571 16.123 7.23827 16.123 7.47034V10.5328"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8402 6.82724L14.1978 4.36411C14.1179 4.24431 14.0096 4.14609 13.8826 4.07817C13.7556 4.01024 13.6139 3.97471 13.4698 3.97474H8.27846C8.13399 3.97428 7.99167 4.0096 7.86418 4.07754C7.73669 4.14549 7.62801 4.24394 7.54783 4.36411L5.90283 6.83161"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.873 3.97034V6.59534"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.498 9.87659H12.1855"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4287_5932">
          <rect width="21" height="21" fill="white" transform="translate(0.373047 0.689087)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Monitor;
