import React, { useEffect, useRef, useState } from 'react';
import { getAvatarBackgroundColor } from '../../../helper/helpers';
import PropTypes from 'prop-types';
export default function Avatar({ title, className, borderRadius, size, textSize }) {
  const [avatarTextColor, setAvatarTextColor] = useState('text-white');
  const box = useRef(0);
  const avatarText = title?.charAt(0);
  useEffect(() => {
    const avatarBG = getAvatarBackgroundColor(avatarText);
    box.current.style.backgroundColor = getAvatarBackgroundColor(avatarText);
    if (avatarBG == '#FFC730' || avatarBG == '#F9DBD1' || avatarBG == '#DAD2BC' || avatarBG == '#E2FBFA') {
      setAvatarTextColor('text-gray-300');
    }
  }, []);
  return (
    <div>
      <div ref={box} className={`${className} ${borderRadius} flex items-center justify-center ${size}`}>
        <span className={`uppercase ${avatarTextColor} ${textSize} text-bold `}>{avatarText}</span>
      </div>
    </div>
  );
}
Avatar.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  borderRadius: PropTypes.string,
  size: PropTypes.string,
  textSize: PropTypes.string
};
Avatar.defaultProps = {
  borderRadius: 'rounded-full',
  size: 'w-20 h-20'
};
