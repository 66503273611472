import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

export default function BlockPoints({ content }) {
  return (
    <div className="flex flex-wrap gap-2 justify-center items-center md:justify-start">
      {content?.map((blockData, index) => {
        return (
          <div
            key={index}
            className="p-4 bg-salmon-light rounded-lg flex gap-4 items-center w-[378px] border border-salmon-pink"
          >
            <div className="h-16 w-16 min-w-[64px] bg-white rounded-full flex justify-center items-center">
              <GatsbyImage
                image={getImage(blockData?.block_point_icon?.gatsbyImageData)}
                alt={blockData?.block_point_icon?.alt}
                loading="lazy"
                className="min-w-32 max-w-32"
                objectFit="contain"
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: blockData?.block_points_title?.html }}></div>
          </div>
        );
      })}
    </div>
  );
}

BlockPoints.propTypes = {
  content: PropTypes?.object
};
