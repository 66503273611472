import React from 'react';
import PropTypes from 'prop-types';

export default function Heart() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2103_4625)">
        <path
          d="M4.5 18.1819H3.75C2.95435 18.1819 2.19129 17.8658 1.62868 17.3032C1.06607 16.7406 0.75 15.9775 0.75 15.1819V12.1819C0.75 11.3862 1.06607 10.6232 1.62868 10.0606C2.19129 9.49795 2.95435 9.18188 3.75 9.18188H4.5C4.69891 9.18188 4.88968 9.2609 5.03033 9.40156C5.17098 9.54221 5.25 9.73297 5.25 9.93188V17.4319C5.25 17.6308 5.17098 17.8216 5.03033 17.9622C4.88968 18.1029 4.69891 18.1819 4.5 18.1819Z"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.25 18.1819H19.5C19.3011 18.1819 19.1103 18.1029 18.9697 17.9622C18.829 17.8216 18.75 17.6308 18.75 17.4319V9.93188C18.75 9.73297 18.829 9.54221 18.9697 9.40156C19.1103 9.2609 19.3011 9.18188 19.5 9.18188H20.25C21.0456 9.18188 21.8087 9.49795 22.3713 10.0606C22.9339 10.6232 23.25 11.3862 23.25 12.1819V15.1819C23.25 15.9775 22.9339 16.7406 22.3713 17.3032C21.8087 17.8658 21.0456 18.1819 20.25 18.1819Z"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.75 9.18188C3.75 6.99385 4.61919 4.89543 6.16637 3.34825C7.71354 1.80108 9.81196 0.931885 12 0.931885C14.188 0.931885 16.2865 1.80108 17.8336 3.34825C19.3808 4.89543 20.25 6.99385 20.25 9.18188"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 21.9319H17.25C18.0456 21.9319 18.8087 21.6158 19.3713 21.0532C19.9339 20.4906 20.25 19.7275 20.25 18.9319V18.1819"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 23.4319H12C11.6022 23.4319 11.2206 23.2738 10.9393 22.9925C10.658 22.7112 10.5 22.3297 10.5 21.9319C10.5 21.5341 10.658 21.1525 10.9393 20.8712C11.2206 20.5899 11.6022 20.4319 12 20.4319H13.5C13.8978 20.4319 14.2794 20.5899 14.5607 20.8712C14.842 21.1525 15 21.5341 15 21.9319C15 22.3297 14.842 22.7112 14.5607 22.9925C14.2794 23.2738 13.8978 23.4319 13.5 23.4319Z"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 15.6239C9.82032 16.3714 10.8902 16.7858 12 16.7858C13.1098 16.7858 14.1797 16.3714 15 15.6239"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9 10.1669V11.4789" stroke="#E15741" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 10.1669V11.4789" stroke="#E15741" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2103_4625">
          <rect width="24" height="24" fill="white" transform="translate(0 0.181885)" />
        </clipPath>
      </defs>
    </svg>
  );
}

Heart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
Heart.defaultProps = {
  width: '26',
  height: '26'
};
