import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import config from '../config/seo.json';
import { graphql, Link } from 'gatsby';
import CustomHeading from '../components/ui/typography/CustomHeading';
import TermsAndConditionsContent from '../components/terms-and-conditions/TermsAndConditionsContent';
import '../styles/pages/terms-and-condition.scss';
import BaseText from '../components/ui/typography/BaseText';

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicNewTermsAndConditions(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        title {
          text
        }
        back_button
        body {
          ... on PrismicNewTermsAndConditionsDataBodyRichText {
            id
            primary {
              page_content {
                html
              }
              page_content2 {
                html
              }
            }
            slice_type
          }
          ... on PrismicNewTermsAndConditionsDataBodyTable {
            id
            slice_type
            primary {
              is_table_full_width
              table_data {
                html
                text
              }
            }
          }
          ... on PrismicNewTermsAndConditionsDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              blog_image {
                url
                alt
              }
            }
          }
        }
      }
      uid
    }
    allPrismicNewTermsAndConditions(
      filter: { data: { featured: { eq: true } } }
      sort: { data: { featured_position: ASC } }
    ) {
      nodes {
        _previewable
        uid
        data {
          title {
            text
          }
          featured_position
        }
      }
    }
  }
`;
function termsAndConditions({ data }) {
  const newTermsAndConditionsData = data?.prismicNewTermsAndConditions?.data;
  const termsAndConditionsData = data?.allPrismicNewTermsAndConditions?.nodes;

  return (
    <Layout>
      <SEO
        noIndex={true}
        keywords={[`Statrys`]}
        title={config.terms_conditions.title}
        description={config.terms_conditions.description}
      />
      <div>
        <CustomHeading
          title={'Terms and Policies'}
          fontWeight="text-bold"
          customFontSizeClass="terms-policies-heading"
        />
        <div className="flex md:flex-row md:gap-0 gap-8 flex-col mt-10 justify-between md:overflow-scroll lg:overflow-auto mb-24">
          {termsAndConditionsData?.map((termsDetails, index) => (
            <Link
              to={
                termsDetails?.uid === 'terms-and-conditions'
                  ? `/${termsDetails?.uid}`
                  : `/terms-and-conditions/${termsDetails?.uid}`
              }
              key={termsDetails?.uid}
              className={`flex md:flex-row ${
                index > 2 ? 'w-32' : index === 1 ? 'md:w-full w-4/5' : 'w-4/5'
              } flex-col justify-between hover:no-underline ${
                index > 0 ? 'md:px-8 pr-8' : 'pr-8'
              } border-r border-transparent ${index < 4 && 'md:border-r md:border-gray-200'}`}
            >
              <div
                className={`${
                  termsDetails?.uid === data?.prismicNewTermsAndConditions?.uid &&
                  'terms-and-policies-first-selected-tab'
                } w-full cursor-pointer`}
              >
                <BaseText title={termsDetails?.data?.title?.text} />
              </div>
            </Link>
          ))}
        </div>
        <TermsAndConditionsContent
          uid={data?.prismicNewTermsAndConditions?.uid}
          termsAndConditionsDocument={newTermsAndConditionsData}
        />
      </div>
    </Layout>
  );
}

export default termsAndConditions;
