import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import IconAddCircle from '../../images/icons/inline/add-circle.inline.svg';
import IconMinusCircle from '../../images/icons/inline/minus-circle.inline.svg';
export default function accordionPoint({ accordionImage, accordionTitle, accordionDescription, index }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      key={index}
      className="p-4 cursor-pointer bg-salmon-light rounded-lg flex flex-col gap-4 border border-salmon-pink justify-between w-full"
    >
      <div className="flex md:gap-4 gap-2 items-center justify-between w-full">
        <div className="flex md:gap-4 gap-2 items-center justify-between">
          <div className="md:h-16 h-8 md:w-16 w-8 min-w-[32px] md:min-w-[64px] bg-white rounded-full flex justify-center items-center">
            <GatsbyImage
              image={getImage(accordionImage)}
              alt={'accordion image'}
              loading="lazy"
              className="md:min-w-[32px] md:max-w-[32px] min-w-[16px] max-w-[16px]"
              objectFit="contain"
            />
          </div>
          <div>
            <h4 className="md:text-[26px] text-[20px] text-bold">{accordionTitle}</h4>
          </div>
        </div>
        {isOpen ? <IconMinusCircle /> : <IconAddCircle />}
      </div>
      <div
        className={`${isOpen ? 'block' : 'hidden'} mt-2 md:ml-20`}
        dangerouslySetInnerHTML={{ __html: accordionDescription }}
      />
    </div>
  );
}
