import React from 'react';
import Layout from '../components/layout';
import Header from '../components/resource/header';
import '../styles/pages/resource.scss';
import { graphql } from 'gatsby';
import SEO from '../components/seo';

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicResources(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        title {
          text
        }
        link_banner {
          document {
            ... on PrismicBanner {
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        short_description {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        resource_url {
          url
        }
        preview_image {
          url
          alt
        }
        main_image {
          alt
          url
        }
        long_description {
          text
        }
        website_activity
        button_caption {
          text
        }
      }
      id
      uid
      lang
    }
  }
`;
export default function resource({ data }) {
  const resourcesData = data?.prismicResources?.data;
  return (
    <Layout fullwidth={true} bannerData={resourcesData?.link_banner?.document?.data}>
      <SEO description={resourcesData?.seo_description.text} title={resourcesData?.seo_title.text} />
      <Header resourcesData={resourcesData} />
    </Layout>
  );
}
