import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function LongArticleSection({ sectionData }) {
  const sectionImage = getImage(sectionData?.section_image?.gatsbyImageData);
  const imagePosition = 'Right';
  return (
    <div
      className={`flex long-article-richtext ${
        sectionData?.image_position === imagePosition ? 'md:flex-row flex-col' : 'md:flex-row-reverse flex-col'
      } items-end  md:gap-20`}
    >
      <div dangerouslySetInnerHTML={{ __html: sectionData?.section_content?.html }} />
      <GatsbyImage
        image={sectionImage}
        className="mb-8 md:min-w-[266px] md:max-w-[266px] mx-auto md:max-h-[100%] max-h-[200px]"
        alt="section image"
        loading="lazy"
        objectFit="contain"
      />
    </div>
  );
}

LongArticleSection.propTypes = {
  sectionData: PropTypes?.object
};
