import React from 'react';
import PropTypes from 'prop-types';

function H2HeaderText({
  title,
  className,
  fontWeight,
  fontSize,
  textColor,
  textAlign,
  textItalic,
  customFontSizeClass,
  letterSpacing,
  lineHeight,
  style
}) {
  return (
    <div className={`${className}`}>
      <h2
        className={`${fontWeight} ${customFontSizeClass} ${textColor} ${fontSize} ${textAlign} ${letterSpacing} ${lineHeight}`}
        style={style}
      >
        {title} <span className="italic">{textItalic}</span>
      </h2>
    </div>
  );
}

export default H2HeaderText;

H2HeaderText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  textItalic: PropTypes.string,
  customFontSizeClass: PropTypes.string,
  fontSize: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
  style: PropTypes.object
};

H2HeaderText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-bold'
};
