import React, { useState, useEffect } from 'react';
import { SPECIAL_CHARACTER_VALIDATION, SLICE_TYPE } from '../../constants/enum';
import PrivacyPolicyTableOfContent from '../ui/PrivacyPolicyTableOfContent';
import '../../styles/pages/terms-and-condition.scss';
import PropTypes from 'prop-types';
import BaseText from '../../components/ui/typography/BaseText';
import PrimaryButton from '../ui/buttons/PrimaryButton';

export default function TermsAndConditionsContent({ termsAndConditions, termsAndConditionsDocument, uid }) {
  const [tocs, setTocs] = useState([]);
  const [selectedTab, setSelectedTab] = useState('HongKong');
  useEffect(() => {
    const divArticle = document.querySelector('.article-content');
    const divArticleContent = divArticle?.innerHTML;
    const h2headings = divArticleContent?.match(new RegExp('<h2>(.*?)</h2>', 'sg'));
    if (h2headings && h2headings.length) {
      const h2headingsID = document.getElementsByTagName('h2');
      let h2tocs = h2headings.map((heading, index) => {
        const labelString = heading?.match(/<h2(.*?)>(.*?)<\/h2>/)[0];
        const label = labelString.substring(labelString.indexOf('>') + 1, labelString.lastIndexOf('<'));
        const labelText = label.replace(/<[^>]+>/g, '') || label.replace(/(&nbsp;)*/g, '');

        return {
          id: index,
          label,
          labelText
        };
      });
      setTocs(h2tocs);
      if (h2tocs[0].labelText) {
        for (let i = 0; i < h2headingsID.length; i++) {
          if (h2headingsID) {
            h2headingsID[i].setAttribute(
              'id',
              h2tocs[i]?.labelText
                .replace(SPECIAL_CHARACTER_VALIDATION.FOR_SPACE, '-')
                .replace(SPECIAL_CHARACTER_VALIDATION.FOR_NBSP, '')
                .replace(SPECIAL_CHARACTER_VALIDATION.FOR_COMMA, '')
                .replace(SPECIAL_CHARACTER_VALIDATION.FOR_NAMES, '')
                .toLocaleLowerCase()
            );
          }
        }
      }
    }
  }, [termsAndConditions]);
  return (
    <div className={`custom-grid gap-28 mx-auto flex flex-col md:flex-row`}>
      <div>
        {termsAndConditionsDocument.back_button && (
          <PrimaryButton
            className="terms-and-conditions-button mb-6"
            urlLink={'/terms-and-conditions'}
            caption={
              <div className="flex gap-2 justify-center">
                <img src="../../../arrow-left.svg" />
                Back to previous page
              </div>
            }
          />
        )}

        <PrivacyPolicyTableOfContent tocs={tocs} />
      </div>
      <div className="terms-and-conditions">
        <div className="article-content">
          {uid === 'company-secretary-services' && (
            <div className="flex w-full">
              <div
                className={`w-full cursor-pointer ${
                  selectedTab === 'HongKong' ? 'border-b-4 border-coral-500' : 'border-b border-gray-400'
                }`}
                onClick={() => setSelectedTab('HongKong')}
              >
                <BaseText title="HongKong" />
              </div>
              <div
                className={`w-full cursor-pointer ${
                  selectedTab === 'Singapore' ? 'border-b-4 border-coral-500' : 'border-b border-gray-400'
                }`}
                onClick={() => setSelectedTab('Singapore')}
              >
                <BaseText title="Singapore" />
              </div>
            </div>
          )}
          {termsAndConditionsDocument?.body?.map((slice, index) => {
            if (slice?.slice_type === SLICE_TYPE.RICH_TEXT) {
              return (
                <div className={`my-8 break-words ${index === 0 && 'top-alignment'}`} key={index}>
                  <BaseText
                    title={
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            selectedTab === 'HongKong'
                              ? slice?.primary?.page_content?.html
                              : slice?.primary?.page_content2?.html
                        }}
                      ></div>
                    }
                  />
                </div>
              );
            }
            if (slice?.slice_type === SLICE_TYPE.TABLE) {
              return (
                <div className="my-8" key={index}>
                  <div
                    className="terms-and-conditions-table"
                    dangerouslySetInnerHTML={{ __html: slice?.primary?.table_data?.text }}
                  />
                </div>
              );
            }
            if (slice?.slice_type === SLICE_TYPE.IMAGE) {
              return (
                <div className="my-8" key={index}>
                  <img
                    src={slice?.primary?.blog_image?.url}
                    className="w-full rounded-lg"
                    alt={slice?.primary?.blog_image?.alt}
                    loading="lazy"
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

TermsAndConditionsContent.propTypes = {
  termsAndConditions: PropTypes.object,
  termsAndConditionsDocument: PropTypes.any,
  uid: PropTypes.string
};
