import React from 'react';
import { Provider } from 'react-redux';
import store from '../store/store';
import PropTypes from 'prop-types';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from '../../config/prismic/link-resolver';

const ReduxWrapper = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    <Provider store={store}>{element}</Provider>
  </PrismicPreviewProvider>
);

export default ReduxWrapper;

ReduxWrapper.propTypes = {
  element: PropTypes.string
};
