import React,{useState} from 'react'
import { Link } from "gatsby"
import PropTypes from "prop-types";
import H3HeaderText from '../typography/H3HeaderText';
import ArrowCornerLeft from "../../../images/icons/arrow-corner-left.svg"
import ArrowCornerLeft2 from "../../../images/icons/arrow-corner-left-2.svg"
export default function BlogCategoryLink({to, title, state }) {
    const [handleLink, setHandleLink] = useState(false)

    return (
        <div>
         <Link onMouseEnter={() => setHandleLink(true)} onMouseLeave={() => setHandleLink(false)} to={to} className="mt-4 flex" state={state} >
             <H3HeaderText title={title} textColor={handleLink ? "text-coral-500" : null} />
             <div className="w-8 h-8">
             <img src={ handleLink ? ArrowCornerLeft2 : ArrowCornerLeft } className={`p-1 ${handleLink ? "ml-6" : "ml-4"}`}  alt = "link"/>
             </div>
             </Link>
            
        </div>
    )
}

BlogCategoryLink.propTypes = {
    to: PropTypes.string,
    title: PropTypes.string,
    state: PropTypes.any,
    };