import React, { useEffect, useState } from 'react';
import '../../styles/components/horizontal-progress-bar.scss';
import PropTypes from 'prop-types';
export default function HorizontalProgressBar({ className }) {
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const blogContainer = document.getElementsByTagName('article');
      if (blogContainer) {
        const windowHeight = blogContainer?.[0]?.scrollHeight - document.documentElement.clientHeight;
        const scroll = `${totalScroll / windowHeight}`;
        if (totalScroll < windowHeight) {
          setScroll(scroll);
        }
      }
    };
    window.addEventListener('scroll', progressBarHandler);
    return () => window.removeEventListener('scroll', progressBarHandler);
  });

  return (
    <div id="progressBarContainer" className={`${className}`}>
      <div id="progressBar" style={{ transform: `scale(${scroll}, 1)` }} />
    </div>
  );
}

HorizontalProgressBar.propTypes = {
  className: PropTypes.string
};
