import { combineReducers } from 'redux';
import manageCustomer from '../../components/signup/reduxStore/reducer';
import AccountSetup from '../../components/account-setup/reduxStore/reducer';
import allUserApplicationsDetails from '../../components/login/reduxStore/reducer';
import ResetPassword from '../../components/reset-password/reduxStore/reducer';
import WrapperComponents from '../../components/Wrappers/reduxStore/reducer';
import ReferralReducer from '../../components/refer-a-friend/redux/reducer';

const appReducer = combineReducers({
  customerInfo: manageCustomer,
  AccountSetupInfo: AccountSetup,
  allUserApplicationsDetails: allUserApplicationsDetails,
  ResetPassword: ResetPassword,
  WrapperComponents: WrapperComponents,
  referralReducer: ReferralReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT' || action.type === 'RESET_REDUX_DATA') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
