import './src/styles/global.css';
import './src/styles/global.scss';
import './src/styles/typography.scss';
import './src/styles/header-navigation.scss';
import './src/styles/buttons.scss';
import './src/styles/footer.scss';
import browserHydrateFunction from './src/utilities/gatsby/browser-hydrate-function';
import ErrorModal from './src/components/ui/modal/ErrorModal';
import React from 'react';
import { fetchAppVersion } from './src/helper/helpers';
import InvoiceApiErrorModal from './src/components/ui/modal/InvoiceApiErrorModal';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { repositoryConfigs } from './config/prismic/preview';
import { STATUS } from './src/constants/enum';
export const replaceHydrateFunction = browserHydrateFunction;
export const onRouteUpdate = ({ location }) => {
  if (location.hash || location.search) return;
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 200);
};
fetchAppVersion();
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <ErrorModal />
      <InvoiceApiErrorModal />
      {process.env.LOAD_PRISMIC_PREVIEWS === STATUS.TRUE ? (
        <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
      ) : (
        element
      )}
    </>
  );
};

export { default as wrapRootElement } from './src/state/reduxWrapper';
