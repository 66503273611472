import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
import InputAdornment from '@material-ui/core/InputAdornment';
import XSText from '../typography/XSText';

export default function TextInput({
  margin,
  textFieldContainerClass,
  className,
  name,
  label,
  placeholder,
  inputRef,
  error,
  helperText,
  onChange,
  value,
  type,
  onKeyDown,
  max,
  defaultValue,
  disabled,
  onKeyPress,
  backgroundColor,
  InputLabelProps,
  startAdornment,
  endAdornment,
  children,
  inputLabel,
  autoComplete,
  id
}) {
  const useStyles = makeStyles(
    {
      InputNoIconStyle: {
        '& .MuiFilledInput-root': {
          border: `1px solid ${MUI_COLORS.SILVER_MEDAL}`,
          overflow: 'hidden',
          borderRadius: 8,
          color: 'black',
          backgroundColor: backgroundColor,
          height: '68px',
          '&:hover': {
            backgroundColor: !disabled && MUI_COLORS.WHITE
          },
          '&.Mui-focused': {
            color: MUI_COLORS.DARK_CHARCOAL,
            backgroundColor: MUI_COLORS.WHITE,
            borderColor: MUI_COLORS.PURPLE,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)'
          }
        },
        '& .Mui-disabled': {
          background: MUI_COLORS?.LIGHT_GRAY,
          cursor: 'not-allowed'
        },
        '& .MuiInputAdornment-positionStart': {
          marginRight: '0px',
          marginLeft: '0px',
          marginTop: '11px !important'
        },
        '& .MuiFilledInput-input': {
          padding: startAdornment ? '12px 0px 10px' : '27px 16px 10px',
          marginTop: startAdornment ? '9px' : '0px'
        },
        '& .MuiInputAdornment-positionEnd': {
          marginTop: '0px'
        },
        '& .MuiFilledInput-adornedStart': {
          paddingTop: '16px',
          paddingBottom: '10px',
          paddingLeft: '16px'
        },
        '& .MuiInputBase-input': {
          background: 'content-box',
          boxSizing: 'inherit',
          letterSpacing: '-0.5px',
          fontFamily: 'HKGrotesk-Medium',
          color: '#474747'
        },
        '& .MuiFormLabel-root': {
          fontFamily: 'HKGrotesk-Regular',
          fontWeight: 400,
          fontSize: '14px',
          paddingTop: '1px',
          lineHeight: '16px',
          color: MUI_COLORS.GRAY
        },
        '& .MuiInputLabel-filled': {
          margin: 6,
          marginLeft: 5,
          letterSpacing: '-0.5px'
        },
        '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
          transform: 'translate(12px, 10px) scale(1)'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(14px, 5px) scale(1)',
          marginTop: 6
        },
        '& .PrivateNotchedOutline-legendLabelled-7 > span': {
          display: 'none'
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: 'black'
        },
        '& .MuiFormLabel-root.Mui-focused': {
          color: MUI_COLORS.GRAY
        },
        '& .MuiFilledInput-root.Mui-error': {
          backgroundColor: MUI_COLORS.LIGHT_CREAM,
          border: `1px solid ${MUI_COLORS.SUNSET_ORANGE}`
        },
        '& .MuiFormLabel-root.Mui-error': {
          color: MUI_COLORS.SUNSET_ORANGE
        },
        '& .MuiFormHelperText-root': {
          minWidth: '176px',
          fontFamily: 'HKGrotesk-Regular',
          letterSpacing: '-0.5px',
          fontSize: '14px',
          lineHeight: '16px',
          marginTop: '8px'
        },
        '& .MuiFormHelperText-contained': {
          marginRight: '0px',
          marginLeft: '16px'
        },
        '& input[type=number]': {
          '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        }
      }
    },
    { index: 1 }
  );
  const classes = useStyles();
  return (
    <div className={`${margin} ${textFieldContainerClass}`}>
      {inputLabel && <XSText title={inputLabel} className="leading-[18px] mb-2" fontWeight="text-medium" />}
      <TextField
        injectFirst={true}
        className={`${classes.InputNoIconStyle} jss1 ${className}`}
        InputProps={{
          disableUnderline: true,
          startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
          endAdornment: endAdornment && <InputAdornment position="end">{children}</InputAdornment>
        }}
        inputProps={{ maxLength: max }}
        name={name}
        label={label}
        placeholder={placeholder}
        variant="filled"
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        error={error}
        helperText={helperText}
        type={type}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        disabled={disabled}
        onKeyPress={onKeyPress}
        InputLabelProps={InputLabelProps}
        autoComplete={autoComplete}
        id={id}
      />
    </div>
  );
}

TextInput.propTypes = {
  margin: PropTypes.string,
  textFieldContainerClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  onKeyDown: PropTypes.any,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.any,
  backgroundColor: PropTypes.string,
  InputLabelProps: PropTypes.object,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  children: PropTypes.any,
  inputLabel: PropTypes.string,
  autoComplete: PropTypes.bool,
  id: PropTypes.string
};

TextInput.defaultProps = {
  className: 'w-full',
  type: 'string',
  disabled: false,
  backgroundColor: MUI_COLORS.WHITE
};
