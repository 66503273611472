export const ENDPOINTS = {
  SEND_REFERRAL: 'invoice/referral',
  CHECK_REFERRAL: 'invoice/checkReferral',
  LOGIN: 'invoice/login',
  BUSINESS_ACCOUNT: '/businessAccount/create',
  BUSINESS_ACCOUNT_UPDATE: '/businessAccount/update',
  CHECK_USER_ACCOUNT: '/businessAccount/check-user-account',
  CREATE_POLL: 'polling/createPoll',
  GET_POLL_VOTES: 'polling/get-poll-votes'
};
