import React from 'react';
import '../../styles/pages/home.scss';
import XLSubtitleText from '../ui/typography/XLSubtitleText';
import BaseText from '../ui/typography/BaseText';
import TrustPilotLogo from '../../images/icons/trustpilot-logo.svg';
import TrustPilotStars from '../../images/icons/trustpilot-stars.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import circleImg from '../../images/graphics/circleImg.png';
import { EXTERNAL_LINKS } from '../../constants/enum';
function Header({ rewardsData }) {
  return (
    <>
      <div className="w-full flex flex-col md:flex-row justify-between items-center my-auto -mt-16">
        <div className="md:w-3/6 md:py-40 py-20">
          <XLSubtitleText className="w-10/12" title={rewardsData?.title?.text} />
          <BaseText className="mt-4 whitespace-pre-line" title={rewardsData?.description?.text} />
          <div className="flex flex-col md:flex-row gap-2 mt-6">
            <PrimaryButton
              urlLink={process.env.SIGN_UP}
              caption="Open account"
              className="md:w-44 w-full medium-button"
            />
            <PrimaryButton
              urlLink={'/contact'}
              caption="Talk to our team"
              className="md:w-44 w-full medium-button"
              bgColor="transparent"
              color="text-coral-500"
            />
          </div>
          <a href={EXTERNAL_LINKS.TRUST_PILOT} target="_blank" rel="noopener noreferrer">
            <div className="flex gap-4 mt-6 items-center">
              <img src={TrustPilotLogo} alt="trustpilot logo" />
              <div className="vl"></div>
              <div className="mt-1 text-bold text-base">Rated Excellent</div>
              <img src={TrustPilotStars} alt="trust pilot stars" />
            </div>
          </a>
        </div>
        <div className="relative">
          <img className="rewards-outer-circle" src={circleImg} />
          <div className="rewards-company-logo-container flex justify-center">
            <img
              className="self-center"
              src={rewardsData?.reward_header_image?.url}
              alt={rewardsData?.reward_header_img?.alt}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;

Header.propTypes = {
  rewardsData: PropTypes.object
};
