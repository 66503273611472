import React from 'react';
import H2HeaderText from '../ui/typography/H2HeaderText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import IconFaqTitle from '../../images/icons/faq-title.svg';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import FaqDropDown from '../ui/dropdowns/FaqDropDown';
import FaqPageDropDown from '../ui/dropdowns/FaqPageDropDown';
import { StaticImage } from 'gatsby-plugin-image';

function Faq({ faqData, faqPageDropDown }) {
  return (
    <>
      <div className=" lg:mt-52 md:mt-36 mt-44 flex gap-12 lg:flex-row flex-col ">
        <div className="md:w-96 mx-auto">
          <div className="pb-8">
            <img src={IconFaqTitle} alt="IconFaq" width="32" height="32" loading="lazy" className="pb-6" />
            <H2HeaderText className="whitespace-pre-line" title={`Frequently \nAsked \nQuestions`} />
            <MDSubtitleText
              className="whitespace-pre-line mt-4"
              title={`Our team is standing by and \nready to help. Get live support \nfrom our team by phone, chat, \nor email.`}
            />
            <MDSubtitleText
              className="mt-14"
              fontWeight="text-bold"
              textColor="text-coral-500"
              title={<Link to="/faq">Read FAQs</Link>}
            />
          </div>
          <div className="md:w-96">
            <div className="div-img">
              <StaticImage src="../../images/graphics/euro-faq.png" loading="lazy" alt="customer support" />
            </div>
          </div>
        </div>
        <div className="w-full">
          {faqData.map((data, index) => {
            if (faqPageDropDown) {
              return (
                <div key={index}>
                  <FaqPageDropDown data={data} />
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <FaqDropDown data={data} />
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
}
export default Faq;

Faq.propTypes = {
  faqData: PropTypes.array,
  faqPageDropDown: PropTypes.bool
};
