import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CHAR_CODE, MUI_COLORS } from '../../../constants/enum';
function SearchField(props) {
  const {
    type,
    name,
    onChange,
    defaultValue,
    register,
    error,
    showInputBorder,
    showIconSearchCloseButton,
    placeholder,
    onKeyPress,
    isFaq
  } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const updateValue = (value) => {
    if (onChange) {
      onChange(value);
    }
    setValue(value);
  };

  const onResetField = (value) => {
    if (onChange) {
      onChange(value);
    }
    setValue('');
  };
  const handleKeyPress = (key) => {
    if (key?.charCode === CHAR_CODE.ENTER_KEY_CODE) {
      onKeyPress();
    }
  };
  return (
    <div
      className={`relative flex ${
        !isFaq ? 'rounded-l-3xl main-bg  py-2' : 'bg-white w-full rounded-l-full py-4'
      } border-gray-200 pl-4 text-xl`}
    >
      <input
        onKeyPress={(e) => handleKeyPress(e)}
        onChange={(event) => updateValue(event.currentTarget.value)}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        style={{ outline: 'none', color: !isFaq && MUI_COLORS.GRAY, fontSize: '16px', width: '90%' }}
        className={`${!isFaq ? 'main-bg' : 'bg-white placeholder-gray-300'} ${error ? '' : ''}
         ${showInputBorder ? 'border-none' : ''}`}
        ref={register}
      ></input>

      {error ? (
        <>
          <svg
            className="w-8 absolute left-0 top-0 mt-1 mr-1"
            fill="red"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="white"
          >
            <path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
        </>
      ) : null}

      {showIconSearchCloseButton ? (
        <div className="pr-4 mt-2">
          {value && value.length > 0 ? (
            <button onClick={() => onResetField()}>
              <svg viewBox="0 0 20 20" fill="#5e5e5e" className="x w-6 h-6">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default SearchField;

SearchField.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  register: PropTypes.any,
  error: PropTypes.any,
  showInputBorder: PropTypes.bool,
  showIconSearchCloseButton: PropTypes.bool,
  onKeyPress: PropTypes.any,
  isFaq: PropTypes.bool
};

SearchField.defaultProps = {
  showInputBorder: false,
  showIconSearchCloseButton: false
};
