import React from 'react';
import '../../styles/line.scss';
import PropTypes from 'prop-types';

export default function Line({ className, color }) {
  return <div className={`line-container ${color} ${className}`}></div>;
}

Line.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};
Line.defaultProps = {
  color: 'bg-gray-400'
};
