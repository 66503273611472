import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/heading.scss';
import H2Text from '../ui/typography/H2Text';
import H3Text from '../ui/typography/H3Text';
import H4Text from '../ui/typography/H4Text';
import PText from '../ui/typography/PText';

const tagTypes = {
  H2: 'H2',
  H3: 'H3',
  H4: 'H4'
};

const Headings = ({ data }) => {
  let whichHeading = (primary) => {
    switch (primary?.heading_tag_type) {
      default:
        return <H2Text title={primary?.heading_text?.text} letterSpacing="tracking-[-0.5px]" className="heading-tag" />;
      case tagTypes.H2:
        return (
          <H2Text
            title={primary?.heading_text?.text}
            style={{ color: primary?.text_color, fontWeight: primary?.bold && 'bold' }}
            letterSpacing="tracking-[-0.5px]"
            className="h2-heading-tag heading-tag"
          />
        );
      case tagTypes.H3:
        return (
          <H3Text
            title={primary?.heading_text?.text}
            style={{ color: primary?.text_color, fontWeight: primary?.bold && 'bold' }}
            letterSpacing="tracking-[-0.5px]"
            className="h3-heading-tag heading-tag"
          />
        );
      case tagTypes.H4:
        return (
          <H4Text
            title={primary?.heading_text?.text}
            style={{ color: primary?.text_color }}
            letterSpacing="tracking-[-0.5px]"
            className={`h4-heading-tag heading-tag ${!primary?.bold && 'normalFontFamily'}`}
          />
        );
    }
  };

  let styleForP = (tags) => {
    switch (tags) {
      default:
        return { fontSize: '32px', width: '42px' };
      case tagTypes.H2:
        return { fontSize: '32px', width: '42px', paddingLeft: '9px' };
      case tagTypes.H3:
        return { fontSize: '23.62px', width: '36px', paddingLeft: '8px' };
      case tagTypes.H4:
        return { fontSize: '21px', width: '32px', paddingLeft: '7.5px' };
    }
  };
  return (
    <div className="flex flex-row gap-2 items-start pt-16 pb-8">
      {data?.primary?.number_field && (
        <PText
          title={data?.primary?.number_field}
          fontWeight="text-medium"
          style={{
            color: data?.primary?.text_color,
            borderColor: data?.primary?.text_color,
            fontSize: styleForP(data?.primary?.heading_tag_type).fontSize,
            minWidth: styleForP(data?.primary?.heading_tag_type).width,
            minHeight: styleForP(data?.primary?.heading_tag_type).width,
            paddingLeft: data?.primary?.number_field == 1 && styleForP(data?.primary?.heading_tag_type).paddingLeft
          }}
          className={`headings rounded-full border-2 border-gray-500 flex ${
            data?.primary?.number_field !== 1 && 'justify-center'
          }  items-center`}
        />
      )}

      {whichHeading(data?.primary)}
    </div>
  );
};

export default Headings;
Headings.propTypes = {
  data: PropTypes.object
};
