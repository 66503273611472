import React from 'react';
import { PropTypes } from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';

export default function StripeIcon({ stroke }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_308_16238)">
        <path
          d="M13.3901 10C11.5601 9.29001 11.5601 8.93001 11.5601 8.54001C11.5601 8.15001 11.9101 7.69001 12.8901 7.69001C13.5492 7.68857 14.1984 7.85002 14.7801 8.16001L15.4101 8.44001C15.4848 8.47267 15.5663 8.48657 15.6476 8.48051C15.7289 8.47445 15.8075 8.4486 15.8765 8.40521C15.9455 8.36183 16.0029 8.30223 16.0436 8.23161C16.0843 8.16098 16.1071 8.08147 16.1101 8.00001V5.28001C16.109 5.18018 16.0792 5.08277 16.0242 4.99942C15.9692 4.91608 15.8914 4.85032 15.8001 4.81001C14.8951 4.43161 13.9207 4.24764 12.9401 4.27001C9.9401 4.27001 7.8501 6.14001 7.8501 8.71001C7.8501 11.82 10.8501 12.89 11.8501 13.23C13.5501 13.88 13.5501 14.35 13.5501 14.76C13.5501 15.76 12.5501 15.87 11.9601 15.87C11.1279 15.86 10.3134 15.6288 9.6001 15.2L8.3801 14.5V18.5C9.4608 19.0262 10.6481 19.2965 11.8501 19.29C15.6001 19.29 17.2701 16.96 17.2701 14.65C17.2501 11.82 15.1801 10.66 13.3901 10Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7402 0.75H2.24023C1.68795 0.75 1.24023 1.19772 1.24023 1.75V22.25C1.24023 22.8023 1.68795 23.25 2.24023 23.25H22.7402C23.2925 23.25 23.7402 22.8023 23.7402 22.25V1.75C23.7402 1.19772 23.2925 0.75 22.7402 0.75Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_308_16238">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
StripeIcon.propTypes = {
  stroke: PropTypes.stroke
};
StripeIcon.defaultProps = {
  stroke: MUI_COLORS.GREEN
};
