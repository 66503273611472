import React from 'react';

export default function EqualTo() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2103_4603)">
        <path
          d="M18.2499 4.43188H16.2499C15.9357 4.42777 15.6299 4.53429 15.3862 4.73279C15.1425 4.93129 14.9763 5.20914 14.9168 5.51777C14.8572 5.82639 14.908 6.14613 15.0603 6.42108C15.2126 6.69604 15.4567 6.9087 15.7499 7.02188L17.8099 7.84188C18.1032 7.95506 18.3473 8.16772 18.4996 8.44267C18.6519 8.71762 18.7027 9.03737 18.6431 9.34599C18.5836 9.65461 18.4174 9.93247 18.1737 10.131C17.93 10.3295 17.6242 10.436 17.3099 10.4319H15.31"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.75 4.43188V3.68188"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.75 11.1819V10.4319"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.75 13.9319C18.0356 13.9319 19.2923 13.5507 20.3612 12.8364C21.4301 12.1222 22.2632 11.107 22.7552 9.91933C23.2472 8.73161 23.3759 7.42468 23.1251 6.1638C22.8743 4.90292 22.2552 3.74473 21.3462 2.83569C20.4372 1.92665 19.279 1.30759 18.0181 1.05678C16.7572 0.805979 15.4503 0.934701 14.2626 1.42667C13.0748 1.91864 12.0597 2.75176 11.3454 3.82068C10.6312 4.8896 10.25 6.14631 10.25 7.43189"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 23.4319C10.8399 23.4319 13.75 20.5217 13.75 16.9319C13.75 13.342 10.8399 10.4319 7.25 10.4319C3.66015 10.4319 0.75 13.342 0.75 16.9319C0.75 20.5217 3.66015 23.4319 7.25 23.4319Z"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.88 20.1819C8.01805 20.1819 7.1914 19.8395 6.58191 19.23C5.97241 18.6205 5.63 17.7938 5.63 16.9319C5.63 16.0699 5.97241 15.2433 6.58191 14.6338C7.1914 14.0243 8.01805 13.6819 8.88 13.6819"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.81006 16.9319H8.88006"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.75 4.68188L3 8.43188L6.75 6.18188"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.18996 1.08191C6.79823 1.58468 5.61099 2.53214 4.81199 3.77764C4.01298 5.02315 3.64675 6.49728 3.76996 7.97191"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.25 19.6819L21 15.9319L17.25 18.1819"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.8101 23.2818C17.2018 22.7791 18.389 21.8316 19.188 20.5861C19.987 19.3406 20.3533 17.8665 20.2301 16.3918"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2103_4603">
          <rect width="24" height="24" fill="white" transform="translate(0 0.181885)" />
        </clipPath>
      </defs>
    </svg>
  );
}
