export const JURISDICTION_COUNTRY_REQUEST_SUCCESS = 'JURISDICTION_COUNTRY_REQUEST_SUCCESS';

export const SENT_OTP_REQUEST_START = 'SENT_OTP_REQUEST_START';
export const SENT_OTP_REQUEST_FAIL = 'SENT_OTP_REQUEST_FAIL';
export const SENT_OTP_REQUEST_SUCCESS = 'SENT_OTP_REQUEST_SUCCESS';

export const VERIFY_OTP_REQUEST_START = 'VERIFY_OTP_REQUEST_START';
export const VERIFY_OTP_REQUEST_FAIL = 'VERIFY_OTP_REQUEST_FAIL';
export const VERIFY_OTP_REQUEST_SUCCESS = 'VERIFY_OTP_REQUEST_SUCCESS';

export const CUSTOMER_UID_REQUEST_SUCCESS = 'CUSTOMER_UID_REQUEST_SUCCESS';

export const VALIDATE_TOKEN_REQUEST_START = 'VALIDATE_TOKEN_REQUEST_START';
export const VALIDATE_TOKEN_REQUEST_FAIL = 'VALIDATE_TOKEN_REQUEST_FAIL';
export const VALIDATE_TOKEN_REQUEST_SUCCESS = 'VALIDATE_TOKEN_REQUEST_SUCCESS';

export const SET_PASSWORD_REQUEST_START = 'SET_PASSWORD_REQUEST_START';
export const SET_PASSWORD_REQUEST_FAIL = 'SET_PASSWORD_REQUEST_FAIL';
export const SET_PASSWORD_REQUEST_SUCCESS = 'SET_PASSWORD_REQUEST_SUCCESS';

export const USER_LOGIN_REQUEST_START = 'USER_LOGIN_REQUEST_START';
export const USER_LOGIN_REQUEST_FAIL = 'USER_LOGIN_REQUEST_FAIL';
export const USER_LOGIN_REQUEST_SUCCESS = 'USER_LOGIN_REQUEST_SUCCESS';

export const GET_TOKEN_REQUEST_START = 'GET_TOKEN_REQUEST_START';
export const GET_TOKEN_REQUEST_FAIL = 'GET_TOKEN_REQUEST_FAIL';
export const GET_TOKEN_REQUEST_SUCCESS = 'GET_TOKEN_REQUEST_SUCCESS';

export const USER_ONBOARDING_REQUEST_START = 'USER_ONBOARDING_REQUEST_START';
export const USER_ONBOARDING_REQUEST_FAIL = 'USER_ONBOARDING_REQUEST_FAIL';
export const USER_ONBOARDING_REQUEST_SUCCESS = 'USER_ONBOARDING_REQUEST_SUCCESS';

export const NATIONALITIES_LIST_REQUEST_START = 'NATIONALITIES_LIST_REQUEST_START';
export const NATIONALITIES_LIST_REQUEST_FAIL = 'NATIONALITIES_LIST_REQUEST_FAIL';
export const NATIONALITIES_LIST_REQUEST_SUCCESS = 'NATIONALITIES_LIST_REQUEST_SUCCESS';

export const OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_START = 'OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_START';
export const OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_FAIL = 'OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_FAIL';
export const OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_SUCCESS = 'OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_SUCCESS';

export const RESET_REDUX_DATA = 'RESET_REDUX_DATA';