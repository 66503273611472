import React, { useState } from 'react';
import ArrowRightIcon from '../../images/icons/inline/arrow-right.inline.svg';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const LongArticleToc = ({ tocData }) => {
  const [expandedId, setExpandedId] = useState(null);

  const toggleExpand = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  const scrollToSection = (id) => {
    const element = document?.getElementById(id);
    if (element) {
      const headerHeight = 100;
      const elementPosition = element?.getBoundingClientRect()?.top + window?.scrollY;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="bg-salmon-light p-8 flex md:flex-row flex-col md:gap-20 gap-6 rounded-xl mt-8 mb-14">
      <div className="flex flex-col justify-between">
        <p className="md:text-[48px] md:leading-[48px] text-[32px] text-bold ">Content</p>
        <StaticImage src="./images/books-stack.png" alt="books-stack" className="w-[147px] md:block !hidden" />
      </div>
      <ul>
        {tocData?.map((section, index) => (
          <li
            key={section?.id}
            onMouseEnter={() => toggleExpand(section?.id)}
            style={{ marginBottom: '1rem' }}
            className={`border-b border-coral-200 pb-4 ${index === 0 && 'border-t pt-4'}`}
          >
            <div
              onClick={() => scrollToSection(section?.id)}
              className="flex items-center cursor-pointer"
              href={`#${section?.id}`}
            >
              <div
                className={` ${
                  expandedId === section?.id ? 'flex' : 'hidden'
                } border rounded-full items-center justify-center border-black mr-4 min-w-[32px] min-h-[32px] w-8`}
              >
                <ArrowRightIcon />
              </div>

              <p className="text-[26px] hover:underline">{section?.title}</p>
            </div>
            {/* Items (visible when the section is expanded) */}
            {/* {expandedId === section?.id && (
              <ul className="ml-12">
                {section?.items?.map((item) => (
                  <li
                    onClick={() => scrollToSection(item?.id)}
                    key={item?.id}
                    className="text-[26px] md:ml-8 ml-2 hover:underline cursor-pointer "
                  >
                    {item?.title}
                  </li>
                ))}
              </ul>
            )} */}
          </li>
        ))}
      </ul>
    </div>
  );
};

LongArticleToc.propTypes = {
  tocData: PropTypes?.object
};
export default LongArticleToc;
