import { Link } from 'gatsby';
import React, { useState } from 'react';
import { stringTruncate, guidesCustomUrl } from '../../helper/helpers';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import PropTypes from 'prop-types';

function ArticleTopRightSide({ articleData }) {
  const [handleLink, setHandleLink] = useState(false);
  return (
    <div>
      <Link
        onMouseEnter={() => setHandleLink(true)}
        onMouseLeave={() => setHandleLink(false)}
        to={guidesCustomUrl(articleData[1]?.linked_article?.document)}
      >
        <div className="cursor-pointer">
          <img
            className={`rounded-lg blog-top-right-card-img object-cover`}
            src={
              articleData[1]?.linked_article?.document?.data?.meta_image?.url
                ? articleData[1]?.linked_article?.document?.data?.meta_image?.url
                : articleData[1]?.linked_article?.document?.data?.header_image?.url ||
                  articleData[0]?.linked_article?.document?.data?.silos_category?.document?.data
                    ?.section_and_article_image?.url
            }
            alt={
              articleData[1]?.linked_article?.document?.data?.meta_image?.alt
                ? articleData[1]?.linked_article?.document?.data?.meta_image?.alt
                : 'card image'
            }
            loading="lazy"
            width="436"
            height="198"
          />
        </div>

        <div>
          <XXSText
            className="mb-2 mt-4"
            textColor="text-coral-500"
            fontWeight="text-bold"
            title={articleData[1]?.linked_article?.document?.data?.category?.document?.data?.title?.text}
          />

          <Link onMouseEnter={() => setHandleLink(true)} onMouseLeave={() => setHandleLink(false)}>
            <BaseText
              title={stringTruncate(articleData[1]?.linked_article?.document?.data?.title?.text, 60)}
              fontSize="text-xl"
              fontWeight="text-normal"
              className={`${handleLink && 'underline text-coral-500'} blog-top-right-title-container`}
              textColor={`${handleLink ? 'text-coral-500' : 'text-charcoal-dark'}`}
              lineHeight="leading-6"
              letterSpacing="new-blog-card-letter-spacing"
            />
          </Link>
        </div>
      </Link>
    </div>
  );
}

ArticleTopRightSide.propTypes = {
  articleData: PropTypes.object
};
export default ArticleTopRightSide;
