import React from 'react';
import { useState, useEffect } from 'react';
import MultiTextInput from '../ui/inputs/MultiTextInput';
import AlertTriangle from '../../images/icons/alert-triangle.svg';
import axios from 'axios';
import ButtonWithLoader from '../ui/buttons/ButtonWithLoader';
import ThumbsUp from '../../images/icons/inline/thumbs-up.inline.svg';
import ThumbsDown from '../../images/icons/inline/thumbs-down.inline.svg';
import { useLocation } from '@reach/router';
import XXSText from '../ui/typography/XXSText';
export default function FaqSurvey() {
  const [surveySelection, setSurveySelection] = useState();
  const [surveyAnswer, setSurveyAnswer] = useState();
  const [submissionStatus, setSubmissionStatus] = useState();
  const [noOptionSelectedError, setNoOptionSelectedError] = useState(false);
  const [customMessageError, setCustomMessageError] = useState(false);
  const [customMessage, setCustomMessage] = useState();
  const [loading, setLoading] = useState(false);
  const YES = 'yes';
  const NO = 'no';
  const Other = 'Other';
  const helpfulOptions =
    surveySelection === YES
      ? [
          'The clarity of the information',
          'The relevance of the content',
          'The completeness of the answer',
          'Easy navigation and format',
          Other
        ]
      : [
          'The information was unclear',
          'The content was outdated',
          'The answer was incomplete',
          "I couldn't find what I was looking for",
          Other
        ];
  const location = useLocation();
  useEffect(() => {
    if (surveyAnswer) {
      setNoOptionSelectedError(false);
    }
    if (surveyAnswer && surveyAnswer !== Other) {
      setCustomMessageError(false);
    }
    if (customMessage) {
      setCustomMessageError(false);
    }
  }, [customMessage, surveyAnswer]);
  const surveyOptions = () => {
    return helpfulOptions.map((selectedOption) => (
      <div
        key={selectedOption}
        onClick={() => setSurveyAnswer(selectedOption)}
        className="border border-gray-400 rounded-lg p-6 flex items-center gap-4 cursor-pointer"
      >
        <div
          className={`w-4 h-4 border-2 border-gray-400 rounded-full ${
            selectedOption === surveyAnswer && 'bg-coral-500'
          }`}
        ></div>
        <XXSText title={selectedOption} />
      </div>
    ));
  };
  const handleSubmit = async () => {
    if ((surveyAnswer && surveyAnswer !== Other) || (surveyAnswer === Other && customMessage)) {
      setLoading(true);
      setCustomMessageError(false);
      setNoOptionSelectedError(false);
      try {
        await axios.put(`${process.env.INVOICE_BASE_API_URL}businessAccount/add-FAQ-data`, {
          articleUrl: location.href,
          responseType: surveySelection,
          response: `${surveyAnswer}: ${customMessage ? customMessage : ''}`
        });
        setSubmissionStatus(true);
        setLoading(false);
      } catch (error) {
        alert(error.message);
        setLoading(false);
      }
    } else if (!surveyAnswer) setNoOptionSelectedError('No option selected');
    else if (surveyAnswer === Other && !customMessage) setCustomMessageError('Please enter a valid message');
  };
  return (
    <>
      {!surveySelection && (
        <div className="flex flex-col justify-center">
          <p style={{ fontSize: '24px' }} className="text-bold mb-7 text-center">
            Was this article helpful?{' '}
          </p>
          <div className="flex gap-4 justify-center">
            <div
              id="helpful-yes"
              onClick={() => setSurveySelection(YES)}
              className="border border-gray-400 rounded-lg text-base px-8 py-3 cursor-pointer flex gap-4 justify-center items-center"
            >
              <ThumbsUp />
              <p>Yes</p>
            </div>
            <div
              id="helpful-no"
              onClick={() => setSurveySelection(NO)}
              className="border border-gray-400 rounded-lg text-base px-8 py-3 cursor-pointer flex gap-4 justify-center items-center"
            >
              <ThumbsDown />
              <p>No</p>
            </div>
          </div>
        </div>
      )}

      {surveySelection === YES && !submissionStatus && (
        <div className="flex flex-col items-center">
          <p style={{ fontSize: '20px' }} className="text-bold text-left w-full">
            Great! What did you find most helpful?
          </p>
          <div className="flex flex-col text-lg gap-2 mt-6 w-full">{surveyOptions()}</div>
        </div>
      )}
      {surveySelection === NO && !submissionStatus && (
        <div className="flex flex-col items-center">
          <p style={{ fontSize: '20px' }} className="text-bold text-left w-full">
            Sorry about that, what went wrong?
          </p>
          <div className="flex flex-col text-lg gap-2 mt-6 w-full">{surveyOptions()}</div>
        </div>
      )}
      {surveyAnswer === Other && !submissionStatus && (
        <div className="w-full">
          <MultiTextInput
            value={customMessage}
            onChange={(e) => setCustomMessage(e.target.value)}
            label={'Custom Message'}
            className={'w-full'}
          />
        </div>
      )}
      {noOptionSelectedError && (
        <p className="text-red-500 flex items-center ml-2 gap-2 mt-2">
          <img src={AlertTriangle} alt="AlertTriangle" />
          {noOptionSelectedError}
        </p>
      )}
      {customMessageError && (
        <p className="text-red-500 flex items-center ml-2 gap-2 mt-2">
          <img src={AlertTriangle} alt="AlertTriangle" />
          {customMessageError}
        </p>
      )}

      {surveySelection && !submissionStatus && (
        <div className="flex w-full justify-center">
          {' '}
          <ButtonWithLoader
            isLoading={loading}
            id="faq-survey-submit"
            onClick={() => handleSubmit()}
            className="mt-8 bg-coral-500 px-6 text-lg py-3 text-white rounded-lg"
            title="Submit"
          />
        </div>
      )}
      {submissionStatus && (
        <p className="text-bold text-2xl text-green-500 text-center w-full">Thank you for your feedback!</p>
      )}
    </>
  );
}
