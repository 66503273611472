import React from 'react';
import AccordionPoint from '../common/AccordionPoint';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
export default function AccordionPoints({ content, primaryContent }) {
  return (
    <div className="flex flex-col md:flex-row justify-between gap-8 items-end">
      <div className="md:w-[70%] w-full flex flex-col gap-2">
        {content?.map((accordionData, index) => {
          return (
            <AccordionPoint
              key={index}
              accordionTitle={accordionData?.accordion_title?.text}
              accordionDescription={accordionData?.accordion_description?.html}
              accordionImage={accordionData?.long_article_accordion_icon?.gatsbyImageData}
            />
          );
        })}
      </div>
      <GatsbyImage
        image={getImage(primaryContent?.accordion_side_image?.gatsbyImageData)}
        alt={primaryContent?.accordion_side_image?.alt}
        loading="lazy"
        className="md:min-w-[266px] md:max-w-[266px] md:max-h-[100%] max-h-[200px]"
        objectFit="contain"
      />
    </div>
  );
}

AccordionPoints.propTypes = {
  content: PropTypes?.object,
  primaryContent: PropTypes?.object
};
