import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/report-article.scss';
import LGText from '../ui/typography/LGText';
import { stringTruncate } from '../../helper/helpers';
import { useHeadsObserver } from '../../customHooks/useHeadsObserver';

const ReportArticleToc = ({ tocs }) => {
  let NUMBER_OF_HEADING_SHOW_IN_TOC = 8;
  let LENGTH_OF_H2_TOC = 70;
  let HEIGHT_TO_SHOW_NORMAL_NAVIGATION = 73;
  let HIDE_NORMAL_NAVIGATION = 350;

  const [hoverOnTocPoints, setHoverOnTocPoints] = useState(false);
  const [showToc, setShowToc] = useState(false);
  const { activeId } = useHeadsObserver(true);

  const listenScrollEvent = () => {
    if (window.scrollY < HEIGHT_TO_SHOW_NORMAL_NAVIGATION) {
      return setShowToc(false);
    } else if (window.scrollY > HIDE_NORMAL_NAVIGATION) {
      return setShowToc(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <>
      <div className={`report-toc-overlay ${hoverOnTocPoints ? 'block' : 'hidden'}`}></div>
      <div className="relative md:block hidden">
        <div
          className={`report-toc-container z-10 flex items-center ${showToc ? 'block' : 'hidden'}`}
          onMouseEnter={() => setHoverOnTocPoints(true)}
          onMouseLeave={() => setHoverOnTocPoints(false)}
        >
          <div className="flex flex-col gap gap-2">
            {tocs?.slice(0, NUMBER_OF_HEADING_SHOW_IN_TOC)?.map((toc, index) => {
              return (
                <div key={index} className="flex flex-row gap-4 items-center h-12">
                  {hoverOnTocPoints && (
                    <LGText
                      title={
                        <a href={`#${toc?.id}`} className="hover:no-underline hover:text-white">
                          {stringTruncate(toc?.title, LENGTH_OF_H2_TOC)}
                        </a>
                      }
                      textColor="text-silver-100"
                      className="toc-heading flex justify-end"
                      textAlign="text-right"
                    />
                  )}
                  <div
                    className={`flex justify-center items-center w-6 h-6 rounded-full ${
                      toc?.id === activeId ? 'bg-coral-500' : 'bg-white'
                    }  cursor-pointer`}
                  >
                    <p className={`toc-point-number ${toc?.id === activeId && 'text-white'}`}>{index + 1}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportArticleToc;

ReportArticleToc.propTypes = {
  tocs: PropTypes.any
};
