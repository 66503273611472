export const FETCH_CLIENTS_START = 'FETCH_CLIENTS_START';
export const FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL';
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS';

export const FETCH_PAYMENT_CARD_APPLICATIONS_START = 'FETCH_PAYMENT_CARD_APPLICATIONS_START';
export const FETCH_PAYMENT_CARD_APPLICATIONS_FAIL = 'FETCH_PAYMENT_CARD_APPLICATIONS_FAIL';
export const FETCH_PAYMENT_CARD_APPLICATIONS_SUCCESS = 'FETCH_PAYMENT_CARD_APPLICATIONS_SUCCESS';

export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_START =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_START';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_FAIL =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_FAIL';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_SUCCESS =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_SUCCESS';

export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_START =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_START';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_FAIL =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_FAIL';
export const FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_SUCCESS =
  'FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_SUCCESS';

export const FETCH_DEFAULT_TOKEN_START = 'FETCH_DEFAULT_TOKEN_START';
export const FETCH_DEFAULT_TOKEN_FAIL = 'FETCH_DEFAULT_TOKEN_FAIL';
export const FETCH_DEFAULT_TOKEN_SUCCESS = 'FETCH_DEFAULT_TOKEN_SUCCESS';

export const GENERATE_MFA_OTP_START = 'GENERATE_MFA_OTP_START';
export const GENERATE_MFA_OTP_FAIL = 'GENERATE_MFA_OTP_FAIL';
export const GENERATE_MFA_OTP_SUCCESS = 'GENERATE_MFA_OTP_SUCCESS';

export const VERIFY_MFA_OTP_START = 'VERIFY_MFA_OTP_START';
export const VERIFY_MFA_OTP_FAIL = 'VERIFY_MFA_OTP_FAIL';
export const VERIFY_MFA_OTP_SUCCESS = 'VERIFY_MFA_OTP_SUCCESS';

export const VERIFY_MFA_NOTIFICATION_STATUS_START = 'VERIFY_MFA_NOTIFICATION_STATUS_START';
export const VERIFY_MFA_NOTIFICATION_STATUS_FAIL = 'VERIFY_MFA_NOTIFICATION_STATUS_FAIL';
export const VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS = 'VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS';

export const GENERATE_MFA_NOTIFICATION_START = 'GENERATE_MFA_NOTIFICATION_START';
export const GENERATE_MFA_NOTIFICATION_FAIL = 'GENERATE_MFA_NOTIFICATION_FAIL';
export const GENERATE_MFA_NOTIFICATION_SUCCESS = 'GENERATE_MFA_NOTIFICATION_SUCCESS';

export const VALIDATE_SETUP_PASSWORD_TOKEN_START = 'VALIDATE_SETUP_PASSWORD_TOKEN_START';
export const VALIDATE_SETUP_PASSWORD_TOKEN_FAIL = 'VALIDATE_SETUP_PASSWORD_TOKEN_FAIL';
export const VALIDATE_SETUP_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_SETUP_PASSWORD_TOKEN_SUCCESS';

export const UPDATE_USER_PASSWORD_START = 'UPDATE_USER_PASSWORD_START';
export const UPDATE_USER_PASSWORD_FAIL = 'UPDATE_USER_PASSWORD_FAIL';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';

export const RESEND_PASSWORD_SETUP_LINK_START = 'RESEND_PASSWORD_SETUP_LINK_START';
export const RESEND_PASSWORD_SETUP_LINK_FAIL = 'RESEND_PASSWORD_SETUP_LINK_FAIL';
export const RESEND_PASSWORD_SETUP_LINK_SUCCESS = 'RESEND_PASSWORD_SETUP_LINK_SUCCESS';
