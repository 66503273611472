import React from 'react';
import Bullet from '../../common/Bullet';
import BaseText from '../typography/BaseText';
import XSText from '../typography/XSText';
import PropTypes from 'prop-types';

const KeyTakeAways = ({ post, slice }) => {
  return (
    <div
      className={`hover:bg-white border-2 border-coral-500 rounded-lg mb-8 ${
        post?.is_report_article_blog && 'padding-for-report-article mx-auto'
      }`}
    >
      <div className="py-8 px-8">
        <BaseText title={slice?.primary?.key_takaways_title?.text} fontWeight="text-bold" textColor="text-gray-500" />

        {slice?.items?.map((takeAwayPoints, index) => {
          return (
            <>
              <div key={index} className={`flex flex-row items-start gap-2`}>
                {slice?.primary?.list_type ? (
                  <XSText
                    title={`${index + 1}.`}
                    className="takeaways-points"
                    textColor="text-gray-150"
                    fontWeight="text-bold"
                  />
                ) : (
                  <div className="mt-2">
                    <Bullet />
                  </div>
                )}
                {takeAwayPoints?.takeaway_point_link?.url ? (
                  <a
                    href={takeAwayPoints?.takeaway_point_link?.url}
                    target={takeAwayPoints?.takeaway_point_link?.target && takeAwayPoints?.takeaway_point_link?.target}
                    alt="take away points"
                    className={`takeaways-points-with-url mb-2`}
                  >
                    {takeAwayPoints?.takeaways_points?.text}
                  </a>
                ) : (
                  <XSText
                    title={takeAwayPoints?.takeaways_points?.text}
                    className="takeaways-points"
                    textColor="text-gray-150"
                  />
                )}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default KeyTakeAways;

KeyTakeAways.propTypes = {
  post: PropTypes.object,
  slice: PropTypes.array
};
