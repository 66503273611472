import React from 'react';
import PropTypes from 'prop-types';

function XLSubtitleText({ title, className, textColor, textAlign, style }) {
  return (
    <div className={`${className}`}>
      <h1 style={style} className={`xl-subtitle-text text-bold ${textColor} ${textAlign} letter-spacing-1`}>
        {title}
      </h1>
    </div>
  );
}

export default XLSubtitleText;

XLSubtitleText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  style: PropTypes.object
};

XLSubtitleText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left'
};
