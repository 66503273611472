import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseText from '../../ui/typography/BaseText';
import addCircle from '../../../images/icons/addCircle.svg';
import SubtractCircle from '../../../images/icons/subtractCircle.svg';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';

export default function FaqDropDown({ data, blog, open, silos }) {
  const [handleDropDown, setHandleDropDown] = useState(open ? true : false);

  return (
    <div
      className={`
        ${
          handleDropDown ? 'p-6 transition-all duration-500' : 'px-6 pt-6 pb-6 transition-all duration-500'
        } rounded-lg ${silos ? 'bg-customBg-whitesmoke' : 'bg-white'} mb-4 
      `}
    >
      <div className="flex justify-between cursor-pointer" onClick={() => setHandleDropDown(!handleDropDown)}>
        <MDSubtitleText
          className=" w-10/12 whitespace-pre-line faq-text"
          fontWeight="text-bold"
          title={blog || silos ? data?.question?.text : data?.question}
        />
        <div className="cursor-pointer">
          <img
            className="w-7 mt-4 mx-auto"
            width="28"
            height="28"
            alt="arrow"
            loading="lazy"
            src={handleDropDown ? SubtractCircle : addCircle}
          />
        </div>
      </div>
      {data?.answer?.html ? (
        <BaseText
          className={`
          ${
            handleDropDown
              ? 'w-11/12 mt-4 whitespace-pre-line transition-all duration-500'
              : 'hidden mt-0 transition-all duration-500'
          }
        `}
          lineHeight="leading-8"
          title={<div dangerouslySetInnerHTML={{ __html: data?.answer?.html }}></div>}
        />
      ) : (
        <BaseText
          className={`
        ${
          handleDropDown
            ? 'w-11/12 mt-4 whitespace-pre-line transition-all duration-500'
            : 'hidden mt-0 transition-all duration-500'
        }
      `}
          lineHeight="leading-8"
          title={blog || silos ? data?.answer?.text : data?.answer}
        />
      )}
    </div>
  );
}

FaqDropDown.propTypes = {
  data: PropTypes.array,
  blog: PropTypes.bool,
  open: PropTypes.bool,
  silos: PropTypes.bool
};
