import React, { useState } from 'react';
import '../../styles/pages/blogs.scss';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import { Link } from 'gatsby';
import { stringTruncate, guidesCustomUrl } from '../../helper/helpers';
export default function LatestArticleCard({ data }) {
  const [handleLink, setHandleLink] = useState(false);
  return (
    <Link
      onMouseEnter={() => setHandleLink(true)}
      onMouseLeave={() => setHandleLink(false)}
      to={guidesCustomUrl(data)}
      className="hover:no-underline rounded-lg new-blog-card-container cursor-pointer"
    >
      <div className="latest-blog-card-img-container">
        <img
          className={`rounded-lg latest-blog-card-img`}
          src={
            data?.data?.header_image?.url
              ? data?.data?.header_image?.url
              : data?.data?.silos_category?.document?.data?.section_and_article_image?.url
          }
          alt={data?.data?.header_image?.alt ? data?.header_image?.alt : 'card image'}
          loading="lazy"
          width="260"
          height="260"
        />
      </div>
      <div className="latest-blog-card-heading pt-6">
        <BaseText
          title={stringTruncate(data?.title?.text ? data?.title?.text : data?.data?.title?.text, 60)}
          fontWeight="text-normal"
          className={`${handleLink && 'underline text-coral-500'}`}
          textColor={`${handleLink ? 'text-coral-500' : 'text-charcoal-dark'}`}
          lineHeight="leading-6"
          letterSpacing="new-blog-card-letter-spacing"
        />
      </div>
    </Link>
  );
}

LatestArticleCard.propTypes = {
  data: PropTypes.object
};
