import React from 'react';
import BaseText from '../ui/typography/BaseText';
import '../../styles/pages/real-people-support.scss';
import singleNeutral from '../../images/icons/real-person.svg';
import CustomerSupport from '../../images/icons/contact-us-customer-support-chat.svg';
import TranslateBlue from '../../images/icons/translate.svg';
import { navigate } from 'gatsby';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { ENDPOINTS } from '../../service/end-points';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
import axelImg from '../../images/graphics/managerPeople/axel.webp';
import chalisaImg from '../../images/graphics/managerPeople/chalisa.webp';
import popoImg from '../../images/graphics/managerPeople/Popo@2x.png';
import cherImg from '../../images/graphics/managerPeople/Cher@2x.png';
import pearImg from '../../images/graphics/managerPeople/Pear@2x.png';
import jonathanImg from '../../images/graphics/managerPeople/jonathan.webp';
import aaronImg from '../../images/graphics/managerPeople/aaron.webp';
import amImg from '../../images/graphics/managerPeople/Am@2x.png';
import beatriceImg from '../../images/graphics/managerPeople/beatrice.webp';
import H2HeaderText from '../ui/typography/H2HeaderText';

const supportPeople = [
  {
    image: 'jonathan',
    backgroundColor: 'bg-blue-200'
  },
  {
    image: 'axel',
    backgroundColor: 'bg-green-500'
  },
  {
    image: 'chalisa',
    backgroundColor: 'bg-purple-200'
  },
  {
    image: 'pear',
    backgroundColor: 'bg-blue-200'
  },
  {
    image: 'popo',
    backgroundColor: 'bg-coral-200'
  },
  {
    image: 'cher',
    backgroundColor: 'bg-blue-200'
  },
  {
    image: 'aaron',
    backgroundColor: 'bg-green-500'
  },
  {
    image: 'tatch',
    backgroundColor: 'bg-yellow-500'
  },
  {
    image: 'beatrice',
    backgroundColor: 'bg-blue-600'
  }
];

const supportPoints = [
  {
    heading: 'We focus on people',
    subHeading: `Receive personalized support whenever you need it.`,
    icon: singleNeutral
  },
  {
    heading: 'Connect with us in any way you want',
    subHeading: `WhatsApp, WeChat, email, phone, we’ll get back to you no matter what.`,
    icon: CustomerSupport
  },
  {
    heading: 'We speak your language',
    subHeading: `English, Cantonese, Mandarin, \nFrench, Spanish, Hindi & Thai.`,
    icon: TranslateBlue
  }
];
const requiredContentImg = (image) => {
  switch (image) {
    case 'jonathan':
      return jonathanImg;
    case 'pear':
      return pearImg;
    case 'axel':
      return axelImg;
    case 'chalisa':
      return chalisaImg;
    case 'popo':
      return popoImg;
    case 'cher':
      return cherImg;
    case 'aaron':
      return aaronImg;
    case 'tatch':
      return amImg;
    case 'beatrice':
      return beatriceImg;
  }
};
export default function RealPeopleSupport({ location }) {
  const redirectToManager = () => {
    navigate(ENDPOINTS?.SELECT_MANAGER);
  };
  return (
    <div className="max-w-6xl md:mx-auto real-support-container w-full" id="account-manager">
      <div className="support-container componentPadding">
        <div className="relative">
          <div
            className="flex flex-wrap gap gap-4 md:justify-start justify-center relative zindex1 cursor-pointer"
            onClick={redirectToManager}
          >
            {supportPeople?.map((people, index) => {
              return (
                <div
                  className={`support-people-image flex md:items-end justify-center ${
                    (index === 0 || index === 6) && 'md:ml-24 ml-0'
                  }  ${people?.backgroundColor}`}
                  key={index}
                >
                  <GatsbyImageWrapper
                    image={{ url: requiredContentImg(people.image), width: 170, height: 170 }}
                    className="w-full"
                    objectFit="contain"
                    alt="content-image"
                    layout="FIXED"
                  />
                </div>
              );
            })}
          </div>
          <div className="formula-blur-bg"></div>
        </div>
      </div>

      <div className="support-text">
        <div className="pb-8 pt-2 flex flex-col justify-center h-full">
          <div>
            <H2HeaderText
              title={`Choose your account manager`}
              fontWeight="text-bold"
              textColor="text-blue-800"
              className="md:whitespace-pre-line"
            />
            {supportPoints?.map((point, index) => {
              return (
                <div className={`flex md:flex-row flex-col pt-6`} key={index}>
                  <img
                    src={point?.icon}
                    alt={'AccountingBills'}
                    loading="lazy"
                    width="32"
                    height="32"
                    className="w-8 h-8 "
                  />
                  <div className="md:ml-4 ml-0 md:my-auto my-2  flex flex-col">
                    <p className="support-point whitespace-pre-line">{point?.heading}</p>
                    <BaseText
                      title={point?.subHeading}
                      textColor="text-blue-800"
                      className="mt-2 md:whitespace-pre-line"
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <PrimaryButton
            caption="Select your account manager"
            color="text-coral-500"
            bgColor="bg-white"
            urlLink={ENDPOINTS?.SELECT_MANAGER}
            className="mt-10 select-manager-button"
            redirectToSelectManager={location?.pathname}
          />
        </div>
      </div>
    </div>
  );
}

RealPeopleSupport.propTypes = {
  location: PropTypes.any
};
