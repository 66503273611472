import React from 'react';
import PropTypes from 'prop-types';

export default function Breadcrumbs({ content }) {
  return (
    <div className="text-lg flex gap-2 md:items-center flex-wrap mt-10">
      {content?.items?.map((breadcrumbsData, index) => {
        return (
          <div key={index} className={`text-lg gap-2 flex items-center ${index === 1 && 'md:flex hidden'}`}>
            {index !== 0 && <div className={`bg-coral-500 h-2 w-2  rounded-full md:mx-2 mx-1`} />}
            {breadcrumbsData?.breadcrumb_url?.url ? (
              <a className="cursor-pointer" href={breadcrumbsData?.breadcrumb_url?.url}>
                {breadcrumbsData?.breadcrumb_title?.text}
              </a>
            ) : (
              <p>{breadcrumbsData?.breadcrumb_title?.text}</p>
            )}
          </div>
        );
      })}
    </div>
  );
}

Breadcrumbs.propTypes = {
  content: PropTypes?.object
};
