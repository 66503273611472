import React from 'react';
import PropTypes from 'prop-types';
import QuoteImg from '../../images/icons/quote.svg';
import IconLinkedIn from '../../images/icons/computer-logo-linkedin.svg';
import HyperLink from '../../images/icons/hyper-link.svg';
import '../../styles/components/quote.scss';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import FaqDropDown from '../ui/dropdowns/FaqDropDown';
import PrimaryButton from '../ui/buttons/PrimaryButton';

const Quote = ({ quoteData, className, quoteItems }) => {
  return (
    <div className={`flex flex-col my-8 py-8 relative ${className}`}>
      <img
        src={QuoteImg}
        alt="quote"
        width="92"
        height="61"
        loading="lazy"
        className="quote-icon quote-start-icon absolute"
      />
      <div
        className="quote-text-container relative zindex1"
        dangerouslySetInnerHTML={{ __html: quoteData?.quote_text?.html }}
      />
      <div className="flex flex-row">
        <div className="flex flex-row gap gap-2 justify-between w-full">
          <div className="flex flex-row gap gap-2 items-center">
            <img
              src={quoteData?.author_image?.url}
              alt={quoteData?.author_image?.alt}
              width="64"
              height="64"
              className="rounded-full"
              loading="lazy"
            />
            <div className="flex flex-col">
              <div className="text-lg  text-gray-500 text-bold">{quoteData?.author_name?.text}</div>
              <div className="flex flex-row gap gap-2 items-center">
                {quoteData?.designation?.text && (
                  <div className="text-lg text-gray-500 leading-5 my-1">{quoteData?.designation?.text}</div>
                )}
                <div className="flex gap-2">
                  {quoteData?.url?.url && (
                    <a href={quoteData?.url?.url}>
                      <img src={HyperLink} alt="IconEmailAction" loading="lazy" width="16" height="16" />
                    </a>
                  )}
                  {quoteData?.linked_in_url?.url && (
                    <a href={quoteData?.linked_in_url?.url} target="_blank" rel="noreferrer">
                      <img src={IconLinkedIn} alt="IconLinkedIn" loading="lazy" width="16" height="16" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <img
            src={QuoteImg}
            alt="quote"
            width="92"
            height="61"
            loading="lazy"
            className="quote-icon quote-end-icon transform-quote absolute"
          />
        </div>
      </div>
      <div className={`w-full ${quoteData?.button_title?.text ? 'mb-2' : 'mb-12'}`}>
        {quoteItems[0]?.question?.text ? (
          <div>
            <MDSubtitleText fontWeight="text-bold" className="mt-4" title="Q&A" />
            {quoteItems?.map((data, index) => {
              return (
                <div key={index}>
                  <FaqDropDown blog={true} open={index === 0 ? true : false} data={data} />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {quoteData?.button_title?.text && (
        <PrimaryButton
          urlLink={quoteData?.button_link?.url}
          type={true}
          target="_blank"
          caption={quoteData?.button_title?.text}
        />
      )}
    </div>
  );
};

export default Quote;

Quote.propTypes = {
  quoteData: PropTypes.object,
  quoteItems: PropTypes.array,
  className: PropTypes.string
};
