import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export default function CustomLink({ children, to }) {
  const [clicked, setClicked] = useState(false);

  return (
    <Link to={to} className={clicked ? 'text-purple-500' : null} onClick={() => setClicked(true)}>
      {children}
    </Link>
  );
}

CustomLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string
};
