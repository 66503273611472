import * as actionTypes from './actionTypes';

let initialState = {
  fetchClients: {},
  fetchPaymentCardApplications: {},
  mfaOtpResponse: {},
  verifyMfaOtpResponse: {},
  generateMfaNotificationResponse: {},
  validateSetupPasswordTokenResponse: {},
  updateUserPasswordResponse: {},
  resendPasswordSetupLinkResponse: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CLIENTS_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_CLIENTS_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_CLIENTS_SUCCESS:
      return { ...state, fetchClients: { ...action.fetchClients }, loading: false, error: false };

    case actionTypes.FETCH_PAYMENT_CARD_APPLICATIONS_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_PAYMENT_CARD_APPLICATIONS_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_PAYMENT_CARD_APPLICATIONS_SUCCESS:
      return {
        ...state,
        fetchPaymentCardApplications: { ...action.fetchPaymentCardApplications },
        loading: false,
        error: false
      };

    case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_COMPLETED_SUCCESS:
      return {
        ...state,
        fetchProspectApplicationProcessStatusCompleted: { ...action.fetchProspectApplicationProcessStatusCompleted },
        loading: false,
        error: false
      };

    case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_PROSPECT_APPLICATION_PROCESS_STATUS_INCOMPLETE_SUCCESS:
      return {
        ...state,
        fetchProspectApplicationProcessStatusIncomplete: { ...action.fetchProspectApplicationProcessStatusIncomplete },
        loading: false,
        error: false
      };

    case actionTypes.GENERATE_MFA_OTP_START:
      return { ...state, loading: true };
    case actionTypes.GENERATE_MFA_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GENERATE_MFA_OTP_SUCCESS:
      return { ...state, mfaOtpResponse: { ...action.mfaOtpResponse }, loading: false, error: false };
    case actionTypes.VERIFY_MFA_OTP_START:
      return { ...state, loading: true };
    case actionTypes.VERIFY_MFA_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VERIFY_MFA_OTP_SUCCESS:
      return { ...state, verifyMfaOtpResponse: { ...action.verifyMfaOtpResponse }, loading: false, error: false };

    case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_START:
      return { ...state, loading: true };
    case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        verifyMfaNotificationStatusResponse: { ...action.verifyMfaNotificationStatusResponse },
        loading: false,
        error: false
      };

    case actionTypes.GENERATE_MFA_NOTIFICATION_START:
      return { ...state, loading: true };
    case actionTypes.GENERATE_MFA_NOTIFICATION_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GENERATE_MFA_NOTIFICATION_SUCCESS:
      return {
        ...state,
        generateMfaNotificationResponse: { ...action.generateMfaNotificationResponse },
        loading: false,
        error: false
      };

    case actionTypes.VALIDATE_SETUP_PASSWORD_TOKEN_START:
      return { ...state, loading: true };
    case actionTypes.VALIDATE_SETUP_PASSWORD_TOKEN_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VALIDATE_SETUP_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        validateSetupPasswordTokenResponse: { ...action.validateSetupPasswordTokenResponse },
        loading: false,
        error: false
      };

    case actionTypes.UPDATE_USER_PASSWORD_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_USER_PASSWORD_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        updateUserPasswordResponse: { ...action.updateUserPasswordResponse },
        loading: false,
        error: false
      };

    case actionTypes.RESEND_PASSWORD_SETUP_LINK_START:
      return { ...state, loading: true };
    case actionTypes.RESEND_PASSWORD_SETUP_LINK_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.RESEND_PASSWORD_SETUP_LINK_SUCCESS:
      return {
        ...state,
        resendPasswordSetupLinkResponse: { ...action.resendPasswordSetupLinkResponse },
        loading: false,
        error: false
      };

    default:
      return state;
  }
};
export default reducer;
