import React from 'react';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
import { relatedGuidesCustomUrl } from '../../helper/helpers';

export default function relatedArticles({ relatedArticles }) {
  return (
    <div className=" bg-salmon-light p-6 mt-10 rounded-lg ">
      <p className="text-[26px] text-bold md:text-left text-center">Related Articles</p>
      <div className="flex flex-col md:flex-row flex-wrap gap-4 md:justify-start md:items-start items-center mt-4">
        {relatedArticles?.items?.map((relatedArticleData, index) => {
          return (
            <a
              key={index}
              target="_blank"
              rel="noreferrer"
              href={relatedGuidesCustomUrl(relatedArticleData?.related_article)}
              className="flex flex-col rounded-lg overflow-hidden w-[264px]"
            >
              <GatsbyImageWrapper
                objectFit={'cover'}
                className="min-w-[264px] max-h-[264px] min-h-[155px rounded-lg"
                image={{
                  url: relatedArticleData?.related_article?.document?.data?.header_image?.url
                    ? relatedArticleData?.related_article?.document?.data?.header_image?.url
                    : relatedArticleData?.related_article?.document?.data?.silos_category?.document?.data
                        ?.section_and_article_image?.url,
                  width: 130,
                  height: 130
                }}
              />
              <p className="md:text-xl text-base text-bold md:leading-6 leading-4 mt-2">
                {relatedArticleData?.related_article?.document?.data?.title?.text}
              </p>
            </a>
          );
        })}
      </div>
    </div>
  );
}
