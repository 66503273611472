import React from 'react';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import H2HeaderText from '../../components/ui/typography/H2HeaderText';
export default function RewardSection({ rewardsData }) {
  return (
    <div className="mt-28">
      <H2HeaderText
        className="md:whitespace-pre-line mb-8 flex"
        textAlign="text-center"
        fontWeight="text-regular"
        title="Reward"
      />
      <div className="w-full flex md:flex-row flex-col gap gap-8">
        <div className="md:w-1/2 w-full flex items-center">
          <div className="pb-2 flex justify-center">
            <div className="silos-section-text rewards-video-dimensions">
              <BaseText
                className="flex flex-col"
                title={
                  <div
                    className="flex flex-col blog"
                    dangerouslySetInnerHTML={{ __html: rewardsData?.reward_description?.html }}
                  ></div>
                }
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full">
          <img src={rewardsData?.reward_image?.url} alt={rewardsData?.reward_image.url} className="w-full rounded-lg" />
        </div>
      </div>
    </div>
  );
}
RewardSection.propTypes = {
  rewardsData: PropTypes.object
};
