import React from 'react';
import BusinessFaq from '../components/business-account/BusinessFaq';
import VirtualAccounts from '../components/home/VirtualAccounts';
import RealPeopleSupport from '../components/common/RealPeopleSupport';
import Layout from '../components/layout';
import Header from '../components/use-case/header';
import { graphql } from 'gatsby';
import '../styles/pages/use-case.scss';
import SEO from '../components/seo';

export const query = graphql`
  query ($uid: String, $lang: String) {
    prismicUseCase(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        title {
          text
        }
        link_banner {
          document {
            ... on PrismicBanner {
              id
              data {
                background_color
                link {
                  url
                }
                link_text {
                  text
                }
                text_color
                link_text_background_color
                title {
                  text
                }
              }
            }
          }
        }
        card_title {
          text
        }
        card_info {
          card_info_points {
            html
          }
          card_info_title {
            text
          }
        }
        average_payment {
          text
        }
        business {
          text
        }
        card_description {
          text
        }
        clients {
          text
        }
        currencies {
          text
        }
        description {
          text
        }
        scroller_description {
          text
        }
        incorporated {
          text
        }
        payees {
          text
        }
        profile {
          text
        }
        turnover {
          text
        }
        image {
          url
          alt
        }
        icon {
          url
          alt
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
      id
      uid
      lang
    }
    allPrismicUseCase {
      edges {
        node {
          _previewable
          data {
            title {
              text
            }
            figure {
              text
            }
            scroller_description {
              text
            }
            image {
              url
              alt
            }
            icon {
              url
              alt
            }
            tab_background_color
            button_background_color
            link_color
          }
          id
          uid
          lang
        }
      }
    }
  }
`;

export default function useCase({ data }) {
  let useCaseData = data?.allPrismicUseCase?.edges;
  let useCaseByUid = data?.prismicUseCase?.data;
  return (
    <Layout fullwidth={true} bannerData={useCaseByUid?.link_banner?.document?.data}>
      <SEO description={useCaseByUid?.seo_description.text} title={useCaseByUid?.seo_title.text} />
      <Header useCaseData={useCaseByUid} />
      <div className="max-w-6xl pt-4 mx-auto">
        <div className="mt-44">
          <VirtualAccounts useCase={useCaseData} />
        </div>
        <BusinessFaq />
        <RealPeopleSupport />
      </div>
    </Layout>
  );
}
