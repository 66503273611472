import React, { useState, useRef } from 'react';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BaseText from '../ui/typography/BaseText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XMSText from '../ui/typography/XMSText';
import PropTypes from 'prop-types';
import XSText from '../ui/typography/XSText';
import { useForm, Controller } from 'react-hook-form';
import TextInput from '../ui/inputs/TextInput';
import MultiTextInput from '../ui/inputs/MultiTextInput';
import CustomStarRating from '../ui/star-rating/CustomStarRating';
import { handleEnter, makeFirstLetterCapitalize } from '../../helper/helpers';
import GuyOnCycle from '../../images/graphics/guy-on-cycle.png';
import { Link } from 'gatsby';
import ValidationMessage from '../ui/ValidationMessage';
import { sendForm } from '@emailjs/browser';
import { EMAIL_JS, REGEX, INTERNAL_LINKS } from '../../constants/enum';
import FileUpload from '../../images/graphics/file-upload.png';
import MSText from '../ui/typography/MSText';
import DynamicModal from '../ui/modal/DynamicModal';
import StatrysLucky from '../ui/loaders/StatrysLucky';
export default function LeaveReview({ companySecName }) {
  const [openReviewForm, setOpenReviewForm] = useState(false);
  const [openThankyouBanner, setOpenThankyouBanner] = useState(false);
  const [reviewError, setReviewError] = useState(false);
  const [reviewErrorModal, setReviewErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();
  const multiTextInputHeight = 208;
  const maximumFileSize = 10;
  const { register, handleSubmit, control, errors } = useForm();
  const onSubmit = async () => {
    setIsLoading(true);
    setOpenReviewForm(false);
    window.scrollTo(0, 100);
    await sendForm(EMAIL_JS.SERVICE_ID, EMAIL_JS.LEAVE_REVIEW_TEMPLATE_ID, form.current, EMAIL_JS.PUBLIC_KEY).then(
      () => {
        setOpenReviewForm(false);
        setOpenThankyouBanner(true);
      },
      (error) => {
        setReviewError(error);
        setReviewErrorModal(true);
      }
    );
    setIsLoading(false);
  };
  const getSelectedFileName = (filePath) => {
    let splitFilePath = filePath.split('\\');
    let selectedFileName = splitFilePath[splitFilePath.length - 1];
    return selectedFileName;
  };
  const closeDynamicModal = () => {
    setReviewErrorModal(false);
  };
  return (
    <>
      <form
        id="review-form"
        ref={form}
        className={`${openReviewForm ? 'block' : 'hidden'} p-8 border border-purple-500 rounded-lg bg-white`}
      >
        <MDSubtitleText
          fontWeight="text-bold"
          title={`Leave a review about ${companySecName} and help other entrepreneurs find a Company secretary that fit their needs.`}
        />
        <XSText className="mt-6 mb-4" title={`Rate the services of ${companySecName}`} />
        <Controller
          control={control}
          name="starRating"
          render={({ value, onChange }) => (
            <CustomStarRating error={errors.starRating} value={value} onChange={onChange} />
          )}
          rules={{ required: true }}
        />
        {errors.starRating && <ValidationMessage title="Please provide a score" />}
        <XSText title={`You can also describe your experience with ${companySecName}`} className="mt-6 mb-4" />
        <Controller
          control={control}
          name="review"
          render={({ value, onChange }) => (
            <MultiTextInput
              className="w-full h-52"
              name="review"
              label="Review"
              variant="filled"
              value={value}
              onChange={onChange}
              height={multiTextInputHeight}
              showValidation={true}
            />
          )}
        />
        <hr className="border-t border-gray-400 my-6" />
        <XSText title="Your information" />
        <Controller
          control={control}
          name="name"
          render={({ value, onChange }) => (
            <TextInput
              onKeyDown={handleEnter}
              margin="mt-4"
              value={makeFirstLetterCapitalize(value)}
              onChange={onChange}
              name="name"
              label="Your name"
              variant="filled"
              error={errors.name ? true : false}
              helperText={errors.name ? <ValidationMessage title="Enter a valid name" /> : null}
            />
          )}
          rules={{ required: true }}
        />

        <TextInput
          onKeyDown={handleEnter}
          margin="mt-4"
          name="email"
          label="Email Address"
          variant="filled"
          inputRef={register({
            required: true,
            pattern: {
              value: REGEX.EMAIL_REGEX,
              message: 'Please fill in a valid Email Address'
            }
          })}
          error={errors.email ? true : false}
          helperText={errors.email ? <ValidationMessage title="Enter a email address" /> : null}
        />
        <TextInput
          onKeyDown={handleEnter}
          margin="mt-4"
          name="company_name"
          label="Company name"
          variant="filled"
          inputRef={register({
            required: true
          })}
          error={errors.company_name ? true : false}
          helperText={errors.company_name ? <ValidationMessage title="Enter a valid company name" /> : null}
        />
        <div className="hidden">
          <TextInput
            onKeyDown={handleEnter}
            margin="mt-4"
            value={companySecName}
            name="comsec_name"
            label="Comsec name"
            variant="filled"
            inputRef={register({
              required: true
            })}
            error={errors.company_name ? true : false}
          />
        </div>
        <XSText
          className="mr-2 my-4"
          title="Upload a copy of your NNC1 or NAR1 to prove your company is using or used in the past the services of this Company Secretary."
        />
        <Controller
          control={control}
          name="document_upload"
          render={({ value, onChange }) => (
            <div>
              <input
                value={value}
                onChange={onChange}
                type="file"
                name="document_upload"
                id="document_up"
                className="custom-file-input"
              ></input>
              <label htmlFor="document_up">
                <div
                  className={`${
                    errors.document_upload ? 'dropzone-error' : 'dropzone'
                  } flex gap-4 items-center review-dropzone`}
                >
                  <img src={FileUpload} className="w-8" />
                  {value ? (
                    <MSText textColor="text-coral-500" title={getSelectedFileName(value)} />
                  ) : (
                    <div>
                      <MSText
                        className="mb-2"
                        textColor={`${errors.document_upload ? 'text-red-alert' : 'text-light-gray'}`}
                        title={'Click here to upload your file'}
                      />
                      <MSText
                        textColor={`${errors.document_upload ? 'text-red-alert' : 'text-light-gray'}`}
                        title={`Only Docx, Doc, PDF, JPG, and PNG files with max size of ${maximumFileSize} MB`}
                      />
                    </div>
                  )}
                </div>
              </label>
            </div>
          )}
          rules={{ required: true }}
        />
        {errors.document_upload && (
          <div className="ml-4">
            <ValidationMessage title="Please provide a valid document" />
          </div>
        )}

        <div className="md:mr-8">
          <XSText
            className="md:mr-1 my-4"
            title={
              <div>
                By clicking on Continue, you acknowledge having read our{' '}
                <a
                  href={INTERNAL_LINKS.PRIVACY_POLICY}
                  target="_blank"
                  rel="noreferrer"
                  className="text-bold text-coral-500"
                >
                  Privacy Policy
                </a>{' '}
                and agree to the{' '}
                <Link
                  href={INTERNAL_LINKS.TERMS_AND_CONDITIONS}
                  target="_blank"
                  rel="noreferrer"
                  className=" text-bold text-coral-500"
                >
                  Terms and Conditions{' '}
                </Link>
              </div>
            }
          />
        </div>
        <PrimaryButton
          className="capitalize w-32 my-4"
          fontSize="text-base"
          caption="Continue"
          onClick={handleSubmit(onSubmit)}
        />
      </form>
      <div
        className={`${
          !openReviewForm && !openThankyouBanner && !isLoading ? 'flex flex-col md:flex-row' : 'hidden'
        } justify-between items-center py-12 px-8 bg-salmon-500 rounded-lg`}
      >
        <div className="md:w-8/12">
          <MDSubtitleText fontWeight="text-bold" title={`Are you using the services of ${companySecName}`} />
          <BaseText
            className="mt-4 "
            title={'Leave a review and help other entrepreneurs find the Company secretary that fit their needs.'}
          />
        </div>
        <div>
          <PrimaryButton
            className="leave-a-review-button"
            onClick={() => setOpenReviewForm(true)}
            fontSize="text-sm"
            caption="Leave a review"
          />
          <XMSText className="mt-1" textAlign="text-center" title="It only takes 2 minutes" />
        </div>
      </div>
      {openThankyouBanner && (
        <div className="bg-green-200 flex flex-col md:flex-row justify-between rounded-lg pt-8 px-8 mt-4 md:gap-0 gap-8">
          <div>
            <MDSubtitleText fontWeight="text-bold" title="Thank you!" />
            <BaseText
              className="mt-4"
              title={
                <div>
                  Your review is pending—it may take up to 24 hours to appear on our website. If you have any questions,
                  please <spna className="text-coral-500">contact us.</spna>
                </div>
              }
            />
          </div>
          <div>
            <img src={GuyOnCycle} alt="GuyOnCycle" />
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-full p-20 flex items-center justify-center">
          <StatrysLucky />
        </div>
      )}
      <DynamicModal openDynamicModal={reviewErrorModal} closeDynamicModal={closeDynamicModal}>
        <MDSubtitleText fontWeight="text-bold" title="Oops! There has been an error" />
        <MSText className="my-6" title={`Error code: ${reviewError?.status}`} />
        <MSText title={reviewError?.text} />
      </DynamicModal>
    </>
  );
}
LeaveReview.propTypes = {
  companySecName: PropTypes.bool
};
