import React from 'react';
import { PropTypes } from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';

export default function PaypalIcon({ stroke }) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4502 7.12002C14.5445 6.86128 14.5733 6.58323 14.5342 6.31063C14.495 6.03803 14.3891 5.77935 14.2258 5.55761C14.0624 5.33588 13.8468 5.15798 13.5981 5.03976C13.3493 4.92154 13.0752 4.86666 12.8002 4.88002H10.4102L9.41016 9.36002H11.7002C12.3371 9.32746 12.9471 9.09274 13.4416 8.68994C13.9361 8.28714 14.2894 7.73723 14.4502 7.12002Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.31006 18.74L6.31006 1.73999H14.6001C17.0901 1.73999 19.8601 3.52999 19.0301 7.31999C18.7063 8.88575 17.8411 10.2874 16.5865 11.2786C15.332 12.2698 13.7683 12.7872 12.1701 12.74H8.68006L7.19006 18.74H2.31006Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1798 7.42999C23.6798 10.09 21.7998 16.15 15.8298 16.24H12.2198L10.6998 22.24H5.81982L6.05982 21"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
PaypalIcon.propTypes = {
  stroke: PropTypes.stroke
};
PaypalIcon.defaultProps = {
  stroke: MUI_COLORS.GREEN
};
