import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import LightBulbIcon from '../../images/icons/inline/light-bulb.inline.svg';
const DocumentWithInfo = ({ documentContent }) => {
  return (
    <div className="flex flex-col gap-16">
      {documentContent?.map((content, index) => {
        return (
          <div key={index}>
            <div
              className="mb-4 long-article-richtext"
              dangerouslySetInnerHTML={{ __html: content?.document_title?.html }}
            ></div>
            <div className="flex flex-col md:flex-row gap-10">
              <GatsbyImage
                image={getImage(content?.document_image?.gatsbyImageData)}
                alt={content?.document_image?.alt}
                loading="lazy"
                className="md:min-w-[470px] md:max-w-[470px] rounded-lg"
                objectFit="contain"
              />
              <div>
                <div dangerouslySetInnerHTML={{ __html: content?.document_table_data?.text }}></div>
                <div
                  className="my-4 long-article-richtext"
                  dangerouslySetInnerHTML={{ __html: content?.document_details?.html }}
                ></div>
                {content?.document_highlight_box?.html && (
                  <div className="flex md:flex-row flex-col border border-green-500 bg-green-200 gap-4 rounded-lg p-6">
                    <div className="min-w-[32px]">
                      <LightBulbIcon Width={32} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: content?.document_highlight_box?.html }}></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

DocumentWithInfo.propTypes = {
  documentContent: PropTypes?.object
};

export default DocumentWithInfo;
