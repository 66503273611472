import React from 'react';
import '../../styles/components/blog-article-header.scss';
import { Link } from 'gatsby';
import XXSText from '../ui/typography/XXSText';
import BaseText from '../ui/typography/BaseText';
import Calendar from '../../images/icons/calendar.svg';
import TimeClock from '../../images/icons/Time_Clock_Circle.svg';
import { socialLinks } from '../../constants/social-links';
import PropTypes from 'prop-types';
const BlogArticleHeaderInfo = ({ post }) => {
  return (
    <div className="bg-white rounded-lg blog-article-header-info-container absolute">
      <div className="flex flex-col md:p-10 p-4">
        <div className="flex flex-row items-center">
          <Link className="hover:no-underline" to="/blog">
            <XXSText fontWeight="text-bold" title="Blog" />
          </Link>
          <div className="bg-coral-500 h-2 w-2 rounded-full mx-2" />
          <Link
            to={`/blog/${
              post?.linked_blog?.document?.uid ? post?.linked_blog?.document?.uid : post?.category?.document?.uid
            }`}
            className="hover:no-underline"
          >
            <div className="text-lg text-light blog-type text-gray-300">
              {post?.linked_blog?.document?.data?.title?.text
                ? post?.linked_blog?.document?.data?.title?.text
                : post?.category?.document?.data?.title?.text}
            </div>
          </Link>
        </div>
        <h1 className="mt-6 blog-article-main-title text-charcoal-sealBrown text-bold">{post?.title?.text}</h1>
        {post?.subtitle?.text && (
          <BaseText
            title={post?.subtitle?.text}
            className="blog-article-description mt-4"
            textColor="text-charcoal-sealBrown"
          />
        )}
        <div className="flex md:flex-row flex-col mt-6 justify-between md:items-center items-start">
          <div className="flex md:flex-row flex-col gap md:gap-6 gap-2 items-center">
            <Link to={`/team`} className="blog-author-img flex flex-row items-center gap gap-1">
              <img
                src={post?.author?.document?.data?.avatar?.url}
                className="profile-icon rounded-full"
                alt="author img"
                loading="eager"
                width="64"
                height="64"
              />
              <div className="text-xl text-bold leading-5">{post?.author?.document?.data?.full_name?.text}</div>
            </Link>
            <div className="flex flex-row gap gap-2">
              <img src={Calendar} alt="calendar icon" width="16" height="16" loading="eager" />
              <div className="text-lg text-light blog-type text-gray-300">{post?.published_date}</div>
            </div>
            <div className="flex flex-row gap gap-2">
              <img src={TimeClock} alt="clock time icon" width="16" height="16" loading="eager" />
              <div className="text-lg text-light blog-type text-gray-300">
                {post?.time_to_read?.text ? post?.time_to_read?.text : '6 minute read'}
              </div>
            </div>
          </div>
          <div className="flex flex-row gap gap-2 md:mt-0 mt-2 md:ml-0 ml-2">
            {socialLinks.map((social, index) => {
              return (
                <a href={social.link} key={index}>
                  <img src={social?.icon} alt="social links" width="14.8" height="16" />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogArticleHeaderInfo;
BlogArticleHeaderInfo.propTypes = {
  post: PropTypes.object
};
