import React from 'react';
import BaseText from '../ui/typography/BaseText';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import H3HeaderText from '../ui/typography/H3HeaderText';
import PropTypes from 'prop-types';
import StripeIcon from '../../images/icons/customSvg/StripeIcon';
import PaypalIcon from '../../images/icons/customSvg/PaypalIcon';

function DynamicCta({
  title,
  subHeading,
  ctaPointsWithIcon,
  icons,
  image,
  ctaContainerClass,
  titleColor,
  subHeaderColor,
  bgButtonColor,
  bottomTagText,
  bottomTextColor,
  imageBackgroundColor,
  buttonCaption,
  imageStyleClass,
  urlLink,
  pointsTextColor,
  buttonClass
}) {
  return (
    <div className={`${ctaContainerClass} rounded-2xl w-full h-full overflow-hidden`}>
      <div className="flex md:flex-row flex-col w-full">
        <div className="md:w-1/2 w-full">
          <div className="md:pt-12 pt-8 md:pl-12 pl-4 ">
            <div className="flex flex-row gap gap-2 relative zindex1 md:w-full w-11/12">
              <H3HeaderText title={title} fontWeight="text-bold" textColor={titleColor} />
            </div>
            <H3HeaderText
              title={subHeading}
              className="mt-4 relative zindex1"
              textColor={subHeaderColor}
              fontWeight="text-regular"
            />
          </div>

          <div className="flex flex-col mt-6 md:ml-12 ml-4 md:mr-0 mr-4 md:gap-4 gap-2">
            {ctaPointsWithIcon &&
              ctaPointsWithIcon?.map((points, index) => {
                return (
                  <div className="flex flex-row gap gap-4 items-center" key={index}>
                    <div className="rounded-lg p-1">{points?.icon}</div>
                    <BaseText textColor={pointsTextColor} title={points?.text} />
                    {index === 2 && icons && (
                      <div className="flex flex-row gap gap-2">
                        <StripeIcon />
                        <PaypalIcon />
                      </div>
                    )}
                  </div>
                );
              })}
            <div className="flex lg:flex-row md:flex-row flex-col mt-8 gap gap-4 md:pb-12 pb-8 md:items-center w-full">
              <PrimaryButton
                caption={buttonCaption}
                bgColor={bgButtonColor}
                color="text-white"
                className={buttonClass}
                urlLink={urlLink}
                isBorderRequired={true}
              />
              <BaseText
                className="z-10 relative"
                textAlign="md:text-left text-center"
                title={bottomTagText}
                textColor={bottomTextColor}
              />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full relative bg-transparent invoice-cta-image-continer">
          <img src={image} alt="dynamic cta image" className={`zindex1 absolute ${imageStyleClass}`} />
          <div className={`${imageBackgroundColor}`}></div>
        </div>
      </div>
    </div>
  );
}

DynamicCta.propTypes = {
  title: PropTypes.string,
  subHeading: PropTypes.string,
  ctaPointsWithIcon: PropTypes.array,
  icons: PropTypes.string,
  image: PropTypes.string,
  ctaContainerClass: PropTypes.string,
  titleColor: PropTypes.string,
  subHeaderColor: PropTypes.string,
  bgButtonColor: PropTypes.string,
  bottomTagText: PropTypes.string,
  bottomTextColor: PropTypes.string,
  imageBackgroundColor: PropTypes.string,
  buttonCaption: PropTypes.string,
  imageStyleClass: PropTypes.string,
  urlLink: PropTypes.string,
  pointsTextColor: PropTypes.string,
  buttonClass: PropTypes.string
};

DynamicCta.defaultProps = {
  buttonClass: 'w-full'
};

export default DynamicCta;
