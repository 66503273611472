import React from 'react';

export default function CheckInCircle() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2103_4621)">
        <path
          d="M16.874 23.4329C17.4445 23.4328 18.0057 23.2882 18.5051 23.0125C19.0046 22.7367 19.426 22.3388 19.7299 21.856C20.0339 21.3732 20.2105 20.8213 20.2433 20.2517C20.2761 19.6821 20.1639 19.1135 19.9173 18.5991C19.6707 18.0846 19.2977 17.641 18.8332 17.3098C18.3687 16.9786 17.8278 16.7706 17.261 16.7051C16.6943 16.6397 16.1202 16.719 15.5924 16.9356C15.0646 17.1522 14.6003 17.4991 14.243 17.9439C14.1962 16.9167 13.8488 15.9259 13.2439 15.0944C12.639 14.2629 11.8032 13.6273 10.8403 13.2666C9.87746 12.9058 8.82984 12.8358 7.8275 13.0651C6.82516 13.2944 5.91223 13.8131 5.20201 14.5566C4.49179 15.3001 4.01554 16.2359 3.83238 17.2477C3.64922 18.2595 3.7672 19.3028 4.17169 20.2481C4.57618 21.1935 5.24937 21.9992 6.10771 22.5654C6.96604 23.1315 7.97173 23.4332 8.99997 23.4329H16.874Z"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.651 7.28191C13.9476 6.58015 12.9945 6.18604 12.001 6.18604C11.0074 6.18604 10.0543 6.58015 9.35095 7.28191"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.363 3.56894C17.5273 2.7332 16.5351 2.07026 15.4432 1.61796C14.3512 1.16566 13.1809 0.932861 11.999 0.932861C10.8171 0.932861 9.64677 1.16566 8.55483 1.61796C7.4629 2.07026 6.47074 2.7332 5.63501 3.56894"
          stroke="#E15741"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2103_4621">
          <rect width="24" height="24" fill="white" transform="translate(0 0.181885)" />
        </clipPath>
      </defs>
    </svg>
  );
}
