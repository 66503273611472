import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseText from './typography/BaseText';
import '../../styles/table-content.scss';
import XSText from './typography/XSText';
import { replaceSpaceAndSpecialCharacter } from '../../helper/helpers';

function TableofContents({ tocs, silos }) {
  useEffect(() => {
    window.addEventListener('scroll', updateScrollposition);
    return () => {
      window.removeEventListener('scroll', updateScrollposition);
    };
  }, []);

  const updateScrollposition = () => {
    let currentIndex = -1;
    const h2Elelemnts = document.querySelectorAll('h2');
    for (let index = 0; index < h2Elelemnts.length; index++) {
      const element = h2Elelemnts[index];
      if (element.getBoundingClientRect().y > -50) {
        currentIndex = index;
        break;
      }
    }
    highlightIndex(currentIndex);
  };

  const highlightIndex = (index) => {
    const links = document.querySelectorAll('.toc-text');
    links.forEach((item) => {
      item?.classList.remove('text-coral-500');
      item?.classList.remove('text-bold');
    });
    if (index > -1) {
      links[index]?.classList.add('text-coral-500');
      links[index]?.classList.add('text-bold');
    }
    const tocListLinks = document.querySelectorAll('.toc-list');
    tocListLinks.forEach((item) => {
      item?.classList.remove('bg-customBg-mainBg');
      item?.classList.remove('rounded-lg');
    });
    if (index > -1) {
      tocListLinks[index]?.classList.add('bg-customBg-mainBg');
      tocListLinks[index]?.classList.add('rounded-lg');
    }
  };

  return (
    <div className="privacy-policy-toc">
      <ul className="list-none pl-0">
        {tocs.map((toc) => (
          <li key={toc.id}>
            {!silos ? (
              <BaseText
                className="mb-6"
                title={
                  <a
                    href={`#${replaceSpaceAndSpecialCharacter(toc.labelText)}`}
                    id="toc-header"
                    dangerouslySetInnerHTML={{ __html: toc.labelText }}
                  ></a>
                }
              />
            ) : (
              <XSText
                title={
                  <a
                    id="toc-header"
                    href={`#${toc?.labelText
                      .replace(/\s/g, '-')
                      .replace(/(&nbsp;)*/g, '')
                      .replace(/[,.]/g, '')
                      .toLocaleLowerCase()}`}
                    dangerouslySetInnerHTML={{ __html: toc.labelText }}
                    className="toc-text"
                  ></a>
                }
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TableofContents;

TableofContents.propTypes = {
  tocs: PropTypes.any,
  silos: PropTypes.bool,
  fullwidth: PropTypes.bool,
  longToc: PropTypes.bool
};
