import React from 'react';
import PropTypes from 'prop-types';

function BaseText({
  title,
  className,
  textClassName,
  textColor,
  textAlign,
  fontWeight,
  link,
  lineHeight,
  fontSize,
  style,
  letterSpacing,
  id
}) {
  return (
    <div className={`${className}`} id={id}>
      <p
        className={`${fontWeight} ${fontSize} ${textColor} ${textAlign} ${
          link ? 'link-class' : 'no-link'
        } ${letterSpacing} ${lineHeight} ${textClassName}`}
        style={style}
      >
        {title}
      </p>
    </div>
  );
}

export default BaseText;

BaseText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  link: PropTypes.bool,
  lineHeight: PropTypes.string,
  fontSize: PropTypes.string,
  style: PropTypes.any,
  letterSpacing: PropTypes.string,
  id: PropTypes.string,
  textClassName: PropTypes.string
};

BaseText.defaultProps = {
  textColor: 'text-gray-300',
  textAlign: 'text-left',
  fontWeight: 'text-regular',
  lineHeight: 'leading-5',
  fontSize: 'text-xl',
  letterSpacing: 'letter-spacing-p'
};
