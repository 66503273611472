import React from 'react';
import XLSubtitleText from '../../components/ui/typography/XLSubtitleText';
import StarRating from '../ui/star-rating/StarRating';
import MSText from '../ui/typography/MSText';
import { getLastPublishedDate } from '../../helper/helpers';
import H3HeaderText from '../ui/typography/H3HeaderText';
import Avatar from '../../components/ui/avatar/Avatar';
import { Link } from 'gatsby';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { MUI_COLORS, PRODUCT_PAGES_URL } from '../../constants/enum';
import InfoPopUp from '../ui/InfoPopUp';

export default function header({ reviewData, totalCount, averageScore, totalRating }) {
  const reviewDetails = reviewData?.data;
  let totalCountOfReviews = totalCount;
  if (
    reviewDetails?.customer_review?.[0]?.customer_name?.text !== '' &&
    reviewDetails?.customer_review?.[0]?.published_on !== '' &&
    reviewDetails?.customer_review?.[0]?.review_description?.text !== '' &&
    reviewDetails?.customer_review?.[0]?.rating_value !== ''
  ) {
    totalCountOfReviews = totalCount + reviewDetails?.customer_review.length;
  }
  return (
    <div>
      <div className="md:flex hidden items-center gap-6 pt-6 mb-12 justify-between">
        <div className="flex items-center gap-3">
          <Link to={`${PRODUCT_PAGES_URL.COMPANY_SECRETARY_URL}`}>
            <div className={`text-xl text-light letter-spacing-1`}>Company Secretary Reviews</div>
          </Link>
          <div className={`bg-coral-500 h-2 w-2 rounded-full `}></div>
          <div className={`text-xl text-bold letter-spacing-1`}>{reviewDetails?.title?.text}</div>
        </div>
      </div>
      <div>
        <div className="flex gap-4 md:pt-0 pt-12">
          {reviewDetails?.company_logo?.url ? (
            <div className="w-24 h-24 bg-white rounded-lg flex justify-center items-center">
              <img
                src={reviewDetails?.company_logo?.url}
                className="w-full p-1 h-auto overflow-hidden"
                alt="company sec image"
                loading="lazy"
              />
            </div>
          ) : (
            <Avatar
              className="rounded-lg overflow-hidden"
              title={reviewDetails?.title?.text}
              borderRadius="rounded-lg"
              size="Company-sec-details-avatar-size"
            />
          )}
          <div>
            <XLSubtitleText title={reviewDetails?.title?.text} />
            <div className="mb-6 flex flex-row flex-wrap gap-6 mt-4">
              <div className="flex flex-col gap-1">
                <MSText title="Average" />
                <div className="md:text-right flex company-sec-heading">
                  {totalRating ? (
                    <div className="flex gap-2">
                      <StarRating value={averageScore} />
                      <MDSubtitleText className="md:mt-1" fontWeight="text-bold" title={averageScore} />
                    </div>
                  ) : (
                    <H3HeaderText fontWeight="text-bold" title={'No reviews yet'} />
                  )}
                </div>
              </div>
              <div className="flex flex-col justify-between gap-1 items-center">
                <div className="flex flex-row items-start">
                  <MSText title="Reviews" />
                  {totalCountOfReviews ? (
                    <InfoPopUp
                      popUpClass={'small-popup'}
                      info={
                        'The total number of reviews received by this company secretary. Some reviews may include comments from clients.'
                      }
                      infoIconColor={MUI_COLORS.GREEN}
                      iconWidth={10}
                      iconHeight={10}
                    />
                  ) : null}
                </div>

                <H3HeaderText
                  fontSize="md:text-2xl text-lg"
                  textAlign="md:text-right text-left"
                  fontWeight="text-bold"
                  title={totalRating ? totalCountOfReviews : 0}
                />
              </div>
              <div className="flex flex-col gap-1">
                <MSText title="Last Published" />
                <MDSubtitleText
                  fontWeight="text-bold"
                  textAlign="md:text-right text-left"
                  title={getLastPublishedDate(reviewData?.last_publication_date)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
