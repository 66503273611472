import React from 'react';
import XLSubtitleText from '../ui/typography/XLSubtitleText';
import H3HeaderText from '../ui/typography/H3HeaderText';
import IconProductsBriefcase from '../../images/icons/products-briefcase.svg';
import IconPinCoral from '../../images/icons/pin-coral.svg';
import IconShipmentBox from '../../images/icons/shipment-box.svg';
import IconCurrencyExchange from '../../images/icons/currency-exchange.svg';
import IconCashPaymentCoins from '../../images/icons/cash-payment-coins.svg';
import IconCashPaymentBagCoral from '../../images/icons/cash-payment-bag-coral.svg';
import IconEscalatorPeople from '../../images/icons/escalator-people.svg';
import IconAppWindowUser from '../../images/icons/app-window-user.svg';
import BaseText from '../ui/typography/BaseText';
import H2HeaderText from '../ui/typography/H2HeaderText';
import H4HeaderText from '../ui/typography/H4HeaderText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BackLink from '../ui/Link/BackLink';
export default function header({ useCaseData }) {
  const useCaseInfo = [
    { icon: IconProductsBriefcase, field: 'Profile', info: useCaseData?.profile?.text },
    { icon: IconPinCoral, field: 'Incorporated', info: useCaseData?.incorporated?.text },
    { icon: IconShipmentBox, field: 'Business', info: useCaseData?.business?.text },
    { icon: IconEscalatorPeople, field: 'Clients', info: useCaseData?.clients?.text },
    { icon: IconAppWindowUser, field: 'Payees', info: useCaseData?.payees?.text },
    { icon: IconCurrencyExchange, field: 'Currencies', info: useCaseData?.currencies?.text },
    { icon: IconCashPaymentCoins, field: 'Average payment', info: useCaseData?.average_payment?.text },
    { icon: IconCashPaymentBagCoral, field: 'Turnover', info: useCaseData?.turnover?.text }
  ];
  return (
    <div className="use-case-container secondary-bg">
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row md:gap-10">
        <div className="w-full use-case-left-section">
          <H3HeaderText title="Use case" fontWeight="text-regular" />
          <div className="mt-4 mb-8">
            <XLSubtitleText title={useCaseData?.title?.text} />
          </div>
          <BaseText className="w-10/12" title={useCaseData?.description?.text} />
          <div className="use-case-info">
            {useCaseInfo.map((useCase, index) => {
              return (
                <div key={index}>
                  <div className="flex gap-2">
                    <div className="flex items-center gap-4 use-case-info-field">
                      <img src={useCase.icon} alt="IconCarDashboardSpeed" className="h-6 w-6" />
                      <BaseText fontWeight="text-bold" title={useCase?.field} />
                    </div>
                    <BaseText className="use-case-details" title={useCase?.info} />
                  </div>
                  <hr className="w-full field-divider my-6" />
                </div>
              );
            })}
          </div>
          <BackLink className="mt-16" textColor="text-coral-500" title="back to homepage" uid="/" />
        </div>
        <div className="w-full mt-16">
          <img src={useCaseData?.image?.url} className="w-4/5 ml-auto" alt={useCaseData?.image?.alt} />
          <div className="use-case-card flex flex-col items-center mx-auto">
            <H2HeaderText fontWeight="text-regular" title={useCaseData?.card_title?.text} />
            <BaseText className="mt-4" title={useCaseData?.card_description?.text} />
            <hr className="w-full field-divider mt-8 mb-4" />
            {useCaseData.card_info.map((cardData, index) => {
              return (
                <div key={index} className="w-full">
                  <H4HeaderText textColor="text-coral-500" title={cardData.card_info_title.text} />
                  <BaseText
                    className="my-4"
                    title={<div dangerouslySetInnerHTML={{ __html: cardData.card_info_points.html }}></div>}
                  />
                </div>
              );
            })}
            <PrimaryButton
              className="card-button mt-8 mb-2"
              urlLink={process.env.SIGN_UP}
              type="signup"
              caption="Open your account"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
