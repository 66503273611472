import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
const ContactPhone = ({ stroke }) => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M20.053 29.8537L20.069 29.8631C21.4851 30.7647 23.1664 31.1563 24.8353 30.9733C26.5041 30.7903 28.0605 30.0435 29.2476 28.8564L30.2796 27.8244C30.7405 27.3629 30.9994 26.7373 30.9994 26.0851C30.9994 25.4328 30.7405 24.8072 30.2796 24.3457L25.9316 20.0004C25.4701 19.5395 24.8445 19.2806 24.1923 19.2806C23.54 19.2806 22.9145 19.5395 22.453 20.0004V20.0004C22.2247 20.2289 21.9537 20.4102 21.6554 20.5339C21.357 20.6575 21.0372 20.7212 20.7143 20.7212C20.3913 20.7212 20.0715 20.6575 19.7732 20.5339C19.4749 20.4102 19.2038 20.2289 18.9756 20.0004L12.0183 13.0417C11.5574 12.5802 11.2985 11.9547 11.2985 11.3024C11.2985 10.6501 11.5574 10.0246 12.0183 9.56307V9.56307C12.2468 9.33484 12.4281 9.06381 12.5518 8.76548C12.6754 8.46714 12.7391 8.14736 12.7391 7.8244C12.7391 7.50144 12.6754 7.18165 12.5518 6.88332C12.4281 6.58499 12.2468 6.31396 12.0183 6.08573L7.67162 1.73373C7.21012 1.27281 6.58454 1.01392 5.93229 1.01392C5.28004 1.01392 4.65445 1.27281 4.19295 1.73373L3.16095 2.76573C1.97406 3.95291 1.22737 5.50926 1.04412 7.17794C0.860861 8.84663 1.25199 10.5279 2.15295 11.9444L2.16362 11.9604C6.92817 19.0135 13.0009 25.0876 20.053 29.8537V29.8537Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" transform="translate(0 0.00219727)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ContactPhone;

ContactPhone.propTypes = {
  stroke: PropTypes.string
};

ContactPhone.defaultProps = {
  stroke: MUI_COLORS.CORAL
};
