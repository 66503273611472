import { useEffect, useState, useRef } from 'react';

export function useHeadsObserver(onlyH2Heading) {
  const observer = useRef();
  const [activeId, setActiveId] = useState('');

  const intersectorOptions = {
    root: null,
    rootMargin: '0% 0% -80% 0px',
    threshold: 0
  };

  useEffect(() => {
    const handleObsever = (entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id);
        }
      });
    };
    observer.current = new IntersectionObserver(handleObsever, intersectorOptions);

    const elements = document.querySelectorAll(onlyH2Heading ? 'h2' : 'h2, h3');
    elements.forEach((elem) => observer.current.observe(elem));
    return () => observer.current?.disconnect();
  }, []);
  return { activeId, setActiveId };
}
