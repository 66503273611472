import React from "react";
import Play from "./play";
import Pause from "./pause";
import Bar from "./bar";
import "../../../styles/audio-player-style.scss"
import useAudioPlayer from './useAudioPlayer';
import PropTypes from "prop-types";

function Audio({url}) {
  const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer();

  return (
    <div className="player">
      <audio id="audio">
        <source src={url} />
        Your browser does not support the <code>audio</code> element.
      </audio>
      <div className="controls">
        {playing ? 
          <Pause handleClick={() => setPlaying(false)} /> :
          <Play handleClick={() => setPlaying(true)} />
        }
        <Bar curTime={curTime} duration={duration} onTimeUpdate={(time) => setClickedTime(time)}/>
      </div>
    </div>
  );
}

export default Audio;

Audio.propTypes = {
  url: PropTypes.string,
  
}