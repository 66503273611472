import React, { useState } from 'react';
import InformtionCircle from '../../images/icons/customSvg/informationCircle';
import XXSText from './typography/XXSText';
import PropTypes from 'prop-types';
import ReadMoreLink from './Link/ReadMoreLink';
import { Link } from 'gatsby';
import { MUI_COLORS } from '../../constants/enum';
import '../../styles/info-pop-up.scss';
import PText from './typography/PText';

export default function InfoPopUp({
  info,
  popUpClass,
  uid,
  LinkTitle,
  infoIconColor,
  iconWidth,
  iconheight,
  children,
  alwaysShow,
  iconContainerClass,
  showTextInMobileView
}) {
  const [handleLink, setHandleLink] = useState(false);

  return (
    <>
      <div className={`${iconContainerClass} 'h-full items-center flex'`}>
        {handleLink ? (
          <div className={`rounded-lg w-96 absolute p-6  ${popUpClass} shadow-2xl bg-white `}>
            <XXSText title={<div dangerouslySetInnerHTML={{ __html: info }}></div>} />
            {uid && <ReadMoreLink fontSize="XS" uid={uid} textColor="text-coral-500" title={LinkTitle} blog />}
            <div className=" absolute w-full left-0 right-0 mx-auto mt-5 triangle-down"></div>
          </div>
        ) : null}
        {showTextInMobileView && (
          <PText
            className="md:hidden block"
            textColor="text-coral-500"
            fontSize="text-base"
            title={<div dangerouslySetInnerHTML={{ __html: info }}></div>}
          />
        )}
        <Link to={uid && `/blog/${uid}`} className="hover:no-underline" name="blog-links">
          <div
            onMouseOver={() => setHandleLink(true)}
            onMouseLeave={() => setHandleLink(false)}
            className={`${children ? 'ml-0' : 'ml-1'} ${
              alwaysShow ? 'block' : 'hidden'
            }  md:block rounded-full cursor-pointer`}
          >
            {children ? children : <InformtionCircle stroke={infoIconColor} width={iconWidth} height={iconheight} />}
          </div>
        </Link>
      </div>
    </>
  );
}

InfoPopUp.propTypes = {
  info: PropTypes.string,
  popUpClass: PropTypes.string,
  LinkTitle: PropTypes.string,
  uid: PropTypes.string,
  alwaysShow: PropTypes.string,
  infoIconColor: PropTypes.string,
  iconWidth: PropTypes.number,
  iconheight: PropTypes.number,
  children: PropTypes.any,
  iconContainerClass: PropTypes.any,
  showTextInMobileView: PropTypes.bool
};
InfoPopUp.defaultProps = {
  infoIconColor: MUI_COLORS.CORAL,
  showTextInMobileView: false
};
