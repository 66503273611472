import React from 'react';
import H2HeaderText from '../../components/ui/typography/H2HeaderText';
import BaseText from '../ui/typography/BaseText';
import '../../styles/pages/home.scss';
import StepApply from '../../images/graphics/step-apply.png';
import StepVerify from '../../images/graphics/step-verify.png';
import StepEnjoy from '../../images/graphics/step-enjoy.png';
import H3HeaderText from '../ui/typography/H3HeaderText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import '../../styles/pages/rewards.scss';

export default function ExclusiveBenefits({ title }) {
  return (
    <div>
      <H2HeaderText
        className="md:whitespace-pre-line"
        textAlign="text-center"
        fontWeight="text-regular"
        title={title}
      />
      <BaseText
        className="mt-8 md:w-2/5 mx-auto"
        textAlign="text-center"
        title={
          'Statrys partners with different solutions to provide customers with the tools they need to grow their business.'
        }
      />
      <div className="rewards-steps-container md:gap-0 gap-28 relative mx-auto flex flex-col md:flex-row">
        <div className="w-full md:h-auto h-96">
          <div className="h-24">
            <img src={StepApply} alt="step-1" className="w-24 mx-auto" />
          </div>
          <H3HeaderText className="mt-8" textAlign="text-center" title="Get Statrys" />
          <BaseText
            className="lg:whitespace-pre-line mt-4 w-11/12"
            textAlign="text-center"
            title={`It only takes 10 minutes of your time, and it's all done completely online. It's that easy.`}
          />
        </div>
        <div className="w-full md:h-auto h-96">
          <div className="h-24">
            <img src={StepVerify} alt="step-1" className="w-24 mx-auto" />
          </div>
          <H3HeaderText className="mt-8" textAlign="text-center" title="Redeem" />
          <BaseText
            className="lg:whitespace-pre-line mt-4 w-11/12"
            textAlign="text-center"
            title={`Choose your favorite tool or begin your journey with something new from our network.`}
          />
          <PrimaryButton urlLink={process.env.SIGN_UP} className="w-full mt-12" caption="Open a business account" />
        </div>
        <div className="w-full md:h-auto h-96">
          <div className="h-24">
            <img src={StepEnjoy} alt="step-1" className="w-24 mx-auto" />
          </div>
          <H3HeaderText className="mt-8" textAlign="text-center" title="Enjoy" />
          <BaseText
            className="lg:whitespace-pre-line mt-4 w-11/12"
            textAlign="text-center"
            title={`Combine the best of Statrys and your new perks for a truly growth-worthy experience`}
          />
        </div>
      </div>
    </div>
  );
}

ExclusiveBenefits.propTypes = {
  title: PropTypes.string
};
ExclusiveBenefits.defaultProps = {
  title: `Access to exclusive benefits \nwith our partners`
};
