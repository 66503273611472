import * as actionTypes from './actionTypes';
import axios from 'axios';
import API from '../../../store/invoiceInterceptor';
import { ENDPOINTS } from '../../../service/api-end-points';
export const fetchLatestAppVersionStart = () => ({ type: actionTypes.FETCH_LATEST_APP_VERSION_START });
export const fetchLatestAppVersionFail = (error) => ({
  type: actionTypes.FETCH_LATEST_APP_VERSION_FAIL,
  error: error
});
export const fetchLatestAppVersionSuccess = (fetchLatestAppVersion) => ({
  type: actionTypes.FETCH_LATEST_APP_VERSION_SUCCESS,
  fetchLatestAppVersion: fetchLatestAppVersion
});
export const fetchLatestAppVersion = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchLatestAppVersionStart());
      let mobileApkResponse = await axios
        .get(process.env.MOBILE_APK_AWS_URL)
        .then((data) => data)
        .catch((error) => error);
      await dispatch(fetchLatestAppVersionSuccess(mobileApkResponse));
      return mobileApkResponse;
    } catch (error) {
      dispatch(fetchLatestAppVersionFail(error));
    }
  };
};

export const sendContactsRequestStart = () => ({ type: actionTypes.SEND_CONTACTS_REQUEST_START });
export const sendContactsRequestFail = (error) => ({ type: actionTypes.SEND_CONTACTS_REQUEST_FAILURE, error: error });
export const sendContactsRequestSuccess = (contactsPayload) => ({
  type: actionTypes.SEND_CONTACTS_REQUEST_SUCCESS,
  contacts: contactsPayload
});
export const sendContacts = (contactsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(sendContactsRequestStart());
      let sendContactsResponse = await API.put(ENDPOINTS?.PUBLIC_SENDGRID_SENDEMAIL, contactsPayload);
      sendContactsRequestSuccess(sendContactsResponse);
      return sendContactsResponse;
    } catch (error) {
      dispatch(sendContactsRequestFail(error.response));
      return error?.response;
    }
  };
};
