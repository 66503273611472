import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as userActions from './reduxStore/action';
import { navigate } from 'gatsby';
import DynamicModal from '../ui/modal/DynamicModal';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import convert from 'xml-js';
import { API_CODE } from '../../constants/enum';
import MSText from '../ui/typography/MSText';
function ApkDownloadWrapper({ fetchLatestAppVersion, children }) {
  const [apiError, setApiError] = useState(false);
  const getLatestAppversion = async () => {
    const mobileApkResponse = await fetchLatestAppVersion();
    if (mobileApkResponse.status === API_CODE.STATUS_200) {
      const mobileApkJsonResponse = convert.xml2js(mobileApkResponse.data, { compact: true, spaces: 4 });
      let mobileApkContents = mobileApkJsonResponse?.ListBucketResult?.Contents;
      mobileApkContents.sort(function (obj1, obj2) {
        return new Date(obj2?.LastModified?._text) - new Date(obj1?.LastModified?._text);
      });
      let latestAppVersion = mobileApkContents[0]?.Key?._text
        ? mobileApkContents[0]?.Key?._text
        : mobileApkContents?.Key?._text;
      latestAppVersion && navigate(`${process.env.MOBILE_APK_AWS_URL}${latestAppVersion}`);
    } else {
      setApiError(mobileApkResponse);
    }
  };
  const closeDynamicModal = () => {
    setApiError(false);
  };
  return (
    <div>
      <div onClick={getLatestAppversion}>{children}</div>
      <DynamicModal openDynamicModal={apiError} closeDynamicModal={closeDynamicModal}>
        <MDSubtitleText fontWeight="text-bold" title="Oops! There has been an error" />
        <MSText className="my-6" title={`Error code: ${apiError?.response?.status}`} />
        <MSText title={apiError?.response?.statusText} />
      </DynamicModal>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchLatestAppVersion: () => dispatch(userActions.fetchLatestAppVersion())
  };
};

export default connect(null, mapDispatchToProps)(ApkDownloadWrapper);

ApkDownloadWrapper.propTypes = {
  fetchLatestAppVersion: PropTypes.string,
  children: PropTypes.any
};
