import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BaseText from '../../ui/typography/BaseText';
import upArrow from '../../../images/icons/faq-up-arrow.svg';
import downArrow from '../../../images/icons/faq-down-arrow.svg';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';

export default function FaqPageDropDown({ data, selected }) {
  const [handleDropDown, setHandleDropDown] = useState(false);

  useEffect(() => {
    setHandleDropDown(selected ? true : false);
  }, [selected]);
  return (
    <div
      className={
        handleDropDown
          ? 'mb-4 px-4 py-8 rounded-lg bg-customBg-mainBg transition-all duration-500'
          : 'px-4 py-8 transition-all duration-500'
      }
    >
      <div className="flex justify-between cursor-pointer" onClick={() => setHandleDropDown(!handleDropDown)}>
        <MDSubtitleText className=" w-10/12 whitespace-pre-line" fontWeight="text-bold" title={data.question} />
        <div className="w-16 h-13 -mt-3 cursor-pointer">
          <img
            className="w-7 mt-4 mx-auto"
            alt="arrow"
            width="28"
            height="28"
            loading="lazy"
            src={handleDropDown ? upArrow : downArrow}
          />
        </div>
      </div>
      <BaseText
        className={
          handleDropDown
            ? 'w-11/12 mt-9 whitespace-pre-line transition-all duration-500'
            : 'hidden mt-0 transition-all duration-500'
        }
        title={<div dangerouslySetInnerHTML={{ __html: data.answer }}></div>}
      />
    </div>
  );
}

FaqPageDropDown.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.bool
};
