import { Link } from 'gatsby';
import React, { useState } from 'react';
import '../../styles/header-navigation.scss';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
export default function NavigationDropDown({ menuItems, separtorPosition, separtorPosition2 }) {
  const [onHover, setOnHover] = useState();

  return (
    <div className={`dropdown-resources-container xl:-ml-32 lg:-ml-56`}>
      <div className="flex dropdown-content-container dropdown-content p-6">
        <div className="flex flex-col" onMouseLeave={() => setOnHover()}>
          {menuItems?.map((menu, index) => {
            return (
              index < separtorPosition && (
                <Link to={menu?.to} key={index} className="hover:no-underline">
                  <div
                    onMouseEnter={() => setOnHover(index)}
                    className={`flex flex-row items-center dropdown-item rounded-lg p-4 ${
                      index === onHover ? 'bg-coral-100 ' : ''
                    }`}
                    key={index}
                  >
                    <div className="w-8">
                      <img src={menu?.icon} height="32" width="32" />
                    </div>
                    <BaseText title={menu?.pageName} className="ml-4" fontWeight="text-bold" />
                    {menu?.isNewMenuItem && (
                      <div className="w-[39px] flex justify-between items-center px-[5px] py-0 rounded-lg bg-coral-500 text-white text-[15px] font-bold tracking-[-0.5px] leading-[20px] ml-[5px]">
                        New
                      </div>
                    )}
                  </div>
                </Link>
              )
            );
          })}
        </div>
        <div className="flex flex-col pl-4 border-l border-gray-200" onMouseLeave={() => setOnHover()}>
          {menuItems?.map((menu, index) => {
            return (
              index >= separtorPosition &&
              index < separtorPosition2 && (
                <Link to={menu?.to} className="hover:no-underline">
                  <div
                    onMouseEnter={() => setOnHover(index)}
                    className={`flex flex-row items-center dropdown-item rounded-lg p-4 ${
                      index === onHover ? 'bg-coral-100 ' : ''
                    }`}
                    key={index}
                  >
                    <div className="w-8">
                      <img src={menu?.icon} height="32" width="32" />
                    </div>
                    <BaseText title={menu?.pageName} className="ml-4" fontWeight="text-bold" />
                    {menu?.isNewMenuItem && (
                      <div className="w-[39px] flex justify-between items-center px-[5px] py-0 rounded-lg bg-coral-500 text-white text-[15px] font-bold tracking-[-0.5px] leading-[20px] ml-[5px]">
                        New
                      </div>
                    )}
                  </div>
                </Link>
              )
            );
          })}
        </div>
        <div className="flex flex-col pl-4 border-l border-gray-200" onMouseLeave={() => setOnHover()}>
          {menuItems?.map((menu, index) => {
            return (
              index >= separtorPosition2 && (
                <Link to={menu?.to} className="hover:no-underline">
                  <div
                    onMouseEnter={() => setOnHover(index)}
                    className={`flex flex-row items-center dropdown-item rounded-lg p-4 ${
                      index === onHover ? 'bg-coral-100 ' : ''
                    }`}
                    key={index}
                  >
                    <div className="w-8">
                      <img src={menu?.icon} height="32" width="32" />
                    </div>
                    <BaseText title={menu?.pageName} className="ml-4" fontWeight="text-bold" />
                    {menu?.isNewMenuItem && (
                      <div className="w-[39px] flex justify-between items-center px-[5px] py-0 rounded-lg bg-coral-500 text-white text-[15px] font-bold tracking-[-0.5px] leading-[20px] ml-[5px]">
                        New
                      </div>
                    )}
                  </div>
                </Link>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPosition: PropTypes.number,
  separtorPosition2: PropTypes.number
};
