import React from 'react';
import PropTypes from 'prop-types';

const H3Text = ({ title, className, fontWeight, fontSize, textColor, textAlign, letterSpacing, lineHeight, style }) => {
  return (
    <h3
      className={`${className} ${fontWeight}  ${textColor} ${fontSize} ${textAlign} ${letterSpacing} ${lineHeight}`}
      style={style}
    >
      {title}
    </h3>
  );
};

export default H3Text;

H3Text.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string,
  style: PropTypes.object
};

H3Text.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-medium',
  fontSize: 'text-[38px]',
  letterSpacing: 'tracking-[-0.5px]',
  lineHeight: 'leading-[150%]'
};
