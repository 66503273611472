import React from 'react';
import PropTypes from 'prop-types';

function XMSText({ title, className, textColor, textAlign, fontWeight }) {
  return (
    <div className={`${className}`}>
      <p className={`${fontWeight} text-xs ${textColor} ${textAlign} letter-spacing-p leading-4`}>{title}</p>
    </div>
  );
}

export default XMSText;

XMSText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string
};

XMSText.defaultProps = {
  textColor: 'text-gray-300',
  textAlign: 'text-left',
  fontWeight: 'text-regular'
};
