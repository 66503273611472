import { Link } from 'gatsby';
import React, { useState } from 'react';
import { guidesCustomUrl, stringTruncate } from '../../helper/helpers';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import PropTypes from 'prop-types';

function ArticleLeftSide({ articleData }) {
  const [handleLink, setHandleLink] = useState(false);
  const [authorLink, setAuthorLink] = useState(false);
  return (
    <div>
      <Link
        onMouseEnter={() => setHandleLink(true)}
        onMouseLeave={() => setHandleLink(false)}
        to={guidesCustomUrl(articleData[0]?.linked_article?.document)}
      >
        <div className="cursor-pointer">
          <img
            className={`rounded-lg new-top-blog-card-img`}
            src={
              articleData[0]?.linked_article?.document?.data?.meta_image?.url
                ? articleData[0]?.linked_article?.document?.data?.meta_image?.url
                : articleData[0]?.linked_article?.document?.data?.silos_category?.document?.data
                    ?.section_and_article_image?.url
            }
            alt={
              articleData[0]?.linked_article?.document?.data?.meta_image?.alt
                ? articleData[0]?.linked_article?.document?.data?.meta_image?.alt
                : 'card image'
            }
            loading="lazy"
            width="660"
            height="340"
          />
        </div>
      </Link>
      <div>
        <XXSText
          className="mb-4 mt-4"
          textColor="text-coral-500"
          fontWeight="text-bold"
          title={articleData[0]?.linked_article?.document?.data?.category?.document?.data?.title?.text}
        />
        <Link
          onMouseEnter={() => setHandleLink(true)}
          onMouseLeave={() => setHandleLink(false)}
          to={guidesCustomUrl(articleData[0]?.linked_article?.document)}
        >
          <BaseText
            title={stringTruncate(articleData[0]?.linked_article?.document?.data?.title?.text, 60)}
            fontSize="text-3xl"
            fontWeight="text-normal"
            className={`${handleLink && 'underline text-coral-500'} blog-right-side-title-container`}
            textColor={`${handleLink ? 'text-coral-500' : 'text-charcoal-dark'}`}
            lineHeight="leading-8"
            letterSpacing="new-blog-card-letter-spacing"
          />
        </Link>
        <XXSText
          textColor="text-gray-450"
          title={articleData[0]?.linked_article?.document?.data?.category?.document?.data?.subtitle?.text}
        />
        <div className="mt-4">
          {articleData[0]?.linked_article?.document?.data?.author?.document?.uid && (
            <div className="flex mt-12 items-center">
              <Link
                onMouseEnter={() => setAuthorLink(true)}
                onMouseLeave={() => setAuthorLink(false)}
                to={`/team/${articleData[0]?.linked_article?.document?.data?.author?.document?.uid}`}
                className="w-16 mr-4"
              >
                <img
                  src={articleData[0]?.linked_article?.document?.data?.author?.document?.data?.avatar?.url}
                  className="w-16 h-16 rounded-full"
                  alt="author img"
                  loading="lazy"
                />
              </Link>
              <div className="editors-author-detail">
                <Link
                  onMouseEnter={() => setAuthorLink(true)}
                  onMouseLeave={() => setAuthorLink(false)}
                  to={`/team/${articleData[0]?.linked_article?.document?.data?.author?.document?.uid}`}
                >
                  <div
                    className={`${authorLink && 'underline text-coral-500'} text-lg text-gray-500 text-bold leading-5`}
                  >
                    {articleData[0]?.linked_article?.document?.data?.author?.document?.data?.full_name?.text}
                  </div>
                  <div
                    className={`${authorLink ? 'underline text-coral-500' : 'text-gray-500'} text-lg  leading-5 my-1`}
                  >{`${articleData[0]?.linked_article?.document?.data?.author?.document?.data?.role?.text}`}</div>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ArticleLeftSide.propTypes = {
  articleData: PropTypes.object
};

export default ArticleLeftSide;
