import React from 'react';
import '../../styles/header-navigation.scss';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function MobileNavigationDropDown({ menuItems }) {
  return (
    <div className={`mobile-view-dropdown-container my-2`}>
      <div className="mobile-view-dropdown-content-container flex flex-col">
        {menuItems.map((menu, index) => {
          return (
            <Link to={menu?.to} className="hover:no-underline pl-2" key={index}>
              <div className={`flex flex-row items-center dropdown-item rounded-lg p-4`} key={index}>
                <div className="w-8">
                  <GatsbyImageWrapper
                    image={{
                      url: menu?.icon,
                      width: 32,
                      height: 32
                    }}
                    alt="menu-icon"
                    loading="eager"
                    objectFit="contain"
                  />
                </div>
                <BaseText title={menu?.pageName} className="ml-4" fontWeight="text-bold" />
                {menu?.isNewMenuItem && (
                  <div className="w-[39px] flex justify-between items-center px-[5px] py-0 rounded-lg bg-coral-500 text-white text-[15px] font-bold tracking-[-0.5px] leading-[20px] ml-[5px]">
                    New
                  </div>
                )}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
MobileNavigationDropDown.propTypes = {
  menuItems: PropTypes.array
};
