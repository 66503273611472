import IconLinkedIn from '../images/icons/socialLinks/linkedIn.svg';
import IconTwitter from '../images/icons/socialLinks/twitter.svg';
import IconMail from '../images/icons/socialLinks/mail.svg';
import IconFacebook from '../images/icons/socialLinks/facebook.svg';
import { social_media, email_support_link } from '../config/config.json';

export const socialLinks = [
  {
    icon: IconLinkedIn,
    link: social_media?.linkedIn
  },
  {
    icon: IconTwitter,
    link: social_media?.twitter
  },
  {
    icon: IconMail,
    link: email_support_link
  },
  {
    icon: IconFacebook,
    link: social_media?.fb
  }
];
