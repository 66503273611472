import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Link } from "gatsby";
import IconArrowUp from "../../../images/icons/black-arrow-up.svg"
import IconArrowUp2 from "../../../images/icons/coral-arrow-up.svg"
import IconArrowCornerBack from "../../../images/icons/arrow-corner-back.svg"
import BaseText from '../../ui/typography/BaseText'
import H3HeaderText from '../typography/H3HeaderText';
import XSText from '../typography/XSText';
export default function ReadMoreLink({ uid, title, textColor, className, blog, fontWeight, fontSize }) {
    const [handleLink, setHandleLink] = useState(false)
    return (
        <Link to={blog ? `/blog/${uid}` : uid} >
            <div onMouseEnter={() => setHandleLink(true)} onMouseLeave={() => setHandleLink(false)} className={`flex items-center ${fontSize ? "mt-0" : "mt-4"} ${className}`}>
                {
                    fontSize === "H3"
                        ? <H3HeaderText className="w-full" fontWeight={fontWeight} textColor={` ${handleLink ? "text-coral-500" : textColor} `} title={<div className="w-full"><span>{title}</span><button><img src={handleLink ? IconArrowUp2 : textColor == "text-coral-500" ? IconArrowUp2 : IconArrowUp} className={`${handleLink ? `transform rotate-45 ml-6 ${textColor == "text-coral-500" ? "ml-3 h-4 w-h" : "w-3 h-3"}` : "ml-3 h-4 w-4"}  `} alt="arrow icon" /></button></div>} />
                        : fontSize === "XS"
                            ? <XSText fontWeight={fontWeight} textColor={` ${handleLink ? "text-coral-500" : textColor} `} title={<div><span>{title}</span><button><img src={handleLink ? IconArrowUp2 : textColor == "text-coral-500" ? IconArrowUp2 : IconArrowUp} className={`${handleLink ? `transform rotate-45 ml-6 ${textColor == "text-coral-500" ? "ml-3 h-3 w-3" : "w-3 h-3"}` : "ml-3 h-3 w-3"}  `} alt="arrow icon" /></button></div>} />
                            : <BaseText fontWeight={fontWeight} textColor={` ${handleLink ? "text-coral-500" : textColor} `} title={<div><button><img src={handleLink ? IconArrowCornerBack : textColor == "text-coral-500" ? IconArrowCornerBack : IconArrowUp} className={`${handleLink ? `transform rotate-45 -mb-1 ${textColor == "text-coral-500" ? "w-5 w-5" : "w-4 h-4"}` : "h-5 w-5 -mb-1"}  `} alt="arrow icon" /></button><span className="ml-2">{title}</span></div>} />
                }

            </div>
        </Link>
    )
}

ReadMoreLink.propTypes = {
    uid: PropTypes.string,
    title: PropTypes.string,
    textColor: PropTypes.string,
    className: PropTypes.string,
    fontWeight: PropTypes.string,
    blog: PropTypes.bool,
    fontSize: PropTypes.string
};
ReadMoreLink.defaultProps = {
    title: "Read more",
    textColor: "text-gray-500",
    fontWeight: "text-bold"
};