import * as actionTypes from './actionTypes';

let initialState = {
  fetchLatestAppVersion: {},
  jobId: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LATEST_APP_VERSION_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_LATEST_APP_VERSION_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_LATEST_APP_VERSION_SUCCESS:
      return {
        ...state,
        fetchLatestAppVersion: { ...action.fetchLatestAppVersion },
        loading: false,
        error: false
      };
    case actionTypes.SEND_CONTACTS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SEND_CONTACTS_REQUEST_SUCCESS:
      return {
        ...state,
        jobId: action.contacts,
        loading: false,
        error: false
      };
    case actionTypes.SEND_CONTACTS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};
export default reducer;
