import React from 'react';
import { MUI_COLORS, SVG_TYPES } from '../constants/enum';
import IconSendEmail from '../images/icons/customSvg/iconSendEmail';
import ContactPhone from '../images/icons/customSvg/contactPhone';

export const getSvgIcon = (type, color = MUI_COLORS.CORAL) => {
  switch (type) {
    case SVG_TYPES.BOOK_OPEN_BOOKMARK:
      return '../../../book-open-bookmark.svg';
    case SVG_TYPES.STATRYS_LOGO:
      return '../../../statrys-icon.svg';
    case SVG_TYPES.CREDIT_CARD:
      return '../../../credit-card.svg';
    case SVG_TYPES.CURRENCY_EURO_DOLLAR_EXCHANGE:
      return '../../../currency-euro-dollar-exchange.svg';
    case SVG_TYPES.TAG_DOLLAR:
      return '../../../tag-dollar.svg';
    case SVG_TYPES.QUESTION_CIRCLE:
      return '../../../question-circle.svg';
    case SVG_TYPES.BOOK_LIBRARY:
      return '../../../book-library.svg';
    case SVG_TYPES.BOOK_OPEN:
      return '../../../book-open.svg';
    case SVG_TYPES.SINGLE_MAN_MAIL:
      return '../../../single-man-mail.svg';
    case SVG_TYPES.TEAM_IDEA:
      return '../../../team-idea.svg';
    case SVG_TYPES.NEWS_PAPER_READ:
      return '../../../newspaper-read.svg';
    case SVG_TYPES.BUSINESS_PARTNER:
      return '../../../business-deal-handshake.svg';
    case SVG_TYPES.CONTACT_US:
      return '../../../contact-us.svg';
    case SVG_TYPES.RATING_STARS:
      return '../../../rating-stars.svg';
    case SVG_TYPES.Comparison:
      return '../../../compare-icon.svg';
    case SVG_TYPES.PERFORMANCE_GRAPH:
      return '../../../performance-graph.svg';
    case SVG_TYPES.ACCOUNTING_CALCULATOR:
      return '../../../accounting-calculator.svg';
    case SVG_TYPES.INVOICE_GENERATOR:
      return '../../../invoice-generator.svg';
    case SVG_TYPES.SEND_EMAIL:
      return <IconSendEmail stroke={color} />;
    case SVG_TYPES.CONTACT_PHONE:
      return <ContactPhone stroke={color} />;
    case SVG_TYPES.PAYMENTS_BILLS:
      return '../../../cash-payment-bills.svg';
    case SVG_TYPES.GET_REWARDED:
      return '../../../treasure-chest-open.svg';
    case SVG_TYPES.WRITE_BLOG:
      return '../../../ContentInkPen.svg';
    case SVG_TYPES.VIDEO_GUIDE:
      return '../../../video-guides.svg';
    case SVG_TYPES.QUIZ_QUESTION:
      return '../../../quiz-question.svg';
    case SVG_TYPES.CURRENCY_EURO:
    default:
      return '../../../statrys-icon.svg';
  }
};
