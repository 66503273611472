import React, { useState, useEffect, useCallback } from 'react';
import H3Text from '../ui/typography/H3Text';
import PText from '../ui/typography/PText';
import '../../styles/pages/blogTemplate/pollingCard.scss';
import { API_CODE, MUI_COLORS } from '../../constants/enum';
import { StaticImage } from 'gatsby-plugin-image';
import StatrysLucky from '../ui/loaders/StatrysLucky';
import axios from 'axios';
import PropTypes from 'prop-types';
import { storeArrayInCookie, getArrayFromCookie } from '../../helper/helpers';
import { ENDPOINTS } from '../../service/invoice-service';
import useElementOnScreen from '../../customHooks/useElementOnScreen';
function PollingCard({ blogId, pollingData }) {
  const [loader, setLoader] = useState(false);
  const [getApiLoader, setGetApiLoader] = useState(false);
  const [alreadyVoted, setAlreadyVoted] = useState(false);
  const [hasCalled, setHasCalled] = useState(false);
  const [allPollingOptions, setAllPollingOptions] = useState(pollingData?.document?.data?.options);
  const [ref, isIntersecting] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  });

  const pollingDetails = {
    days: 365,
    cookieName: 'polling'
  };

  let createPollPayload = {
    blogUID: blogId,
    pollQuestionId: pollingData?.document?.id,
    pollQuestionUID: pollingData?.uid,
    question: pollingData?.document?.data?.question?.text,
    options: pollingData?.document?.data?.options
  };

  async function createPoll(answer) {
    try {
      createPollPayload['answer'] = answer;
      const createPollResponse = await axios.post(
        `${process.env.INVOICE_BASE_API_URL}${ENDPOINTS.CREATE_POLL}`,
        createPollPayload
      );
      if (createPollResponse?.status === API_CODE.STATUS_200) {
        setAlreadyVoted(true);
        setLoader(false);
        setAllPollingOptions(createPollResponse?.data?.data);
      }
    } catch (error) {
      alert(error.message);
      setLoader(false);
    }
    setHasCalled(true);
  }

  const getPollingOptions = useCallback(async () => {
    setGetApiLoader(true);
    let getPollPayload = {
      blogUID: blogId,
      pollQuestionId: pollingData?.document?.id
    };
    try {
      const PollResponse = await axios.post(
        `${process.env.INVOICE_BASE_API_URL}${ENDPOINTS.GET_POLL_VOTES}`,
        getPollPayload
      );
      setAllPollingOptions(PollResponse?.data?.data);
    } catch (error) {
      alert(error.message);
    }
    setHasCalled(true);
    setGetApiLoader(false);
  }, [blogId, pollingData]);

  const handleSelectedAnswer = async (option) => {
    setLoader(true);
    let pollingObject = {
      blogUID: blogId,
      pollQuestionId: pollingData?.document?.id,
      pollQuestionUID: pollingData?.uid
    };

    if (getArrayFromCookie(pollingDetails?.cookieName)) {
      let polling = getArrayFromCookie(pollingDetails?.cookieName) || [];
      let isPollingExisted = polling?.filter(
        (poll) => poll.blogUID === blogId && poll.pollQuestionId === pollingData?.document?.id
      );
      if (isPollingExisted.length === 0) {
        polling.push(pollingObject);
        storeArrayInCookie(pollingDetails?.cookieName, polling, pollingDetails?.days);
        createPoll(option);
      }
    } else {
      let pollingData = [pollingObject];
      storeArrayInCookie(pollingDetails?.cookieName, pollingData, pollingDetails?.days);
      createPoll(option);
    }
  };

  useEffect(() => {
    let getPolling = getArrayFromCookie(pollingDetails?.cookieName);
    if (
      getPolling &&
      getPolling.some((poll) => poll.blogUID === blogId && poll.pollQuestionId === pollingData?.document?.id)
    ) {
      setAlreadyVoted(true);
      if (isIntersecting && !hasCalled) getPollingOptions();
    }
  }, [isIntersecting, hasCalled]);

  return (
    <section className="bg-white p-6 rounded-lg" ref={ref}>
      <H3Text className="polling-question" fontSize="text-[26px]" title={pollingData?.document?.data?.question?.text} />
      {loader ? (
        <div className="bg-customBg-mainBg polling-thanks-section flex gap-2 flex-col justify-center items-center rounded-lg">
          <StaticImage
            src="../../images/graphics/congratulation-pop.png"
            className="polling-thanks-img"
            objectFit="contain"
            alt="congrats image"
          />
          <PText fontSize="text-[26px]" title="Thank you for your vote!" />
        </div>
      ) : alreadyVoted ? (
        <>
          {getApiLoader ? (
            <div className="flex justify-center polling-thanks-section">
              <StatrysLucky />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              {allPollingOptions?.map((poll, index) => (
                <div className="flex gap-2" key={index + 1}>
                  <div className="relative h-[68px] w-full rounded-lg flex justify-center items-center">
                    <PText
                      className="polling-choice w-full h-full flex ml-4 items-center z-10"
                      title={poll?.option?.text || poll?.text}
                    />
                    <div
                      style={{
                        width: `${poll?.percentage === 0 ? 0.5 : poll?.percentage}%`,
                        backgroundColor: MUI_COLORS.LIGHT_CREAM,
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        zIndex: 1,
                        borderRadius: '8px',
                        transition: 'width 0.5s ease'
                      }}
                    ></div>
                  </div>
                  <PText className="polling-choice z-10 flex items-center" title={`${poll?.percentage}%`} />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            {allPollingOptions?.map((poll, index) => (
              <div
                className="border h-[68px] w-full rounded-lg flex justify-center items-center cursor-pointer hover:bg-purple-500"
                onClick={() => handleSelectedAnswer(poll?.option?.text || poll?.text)}
                key={index + 1}
              >
                <PText
                  className="polling-choice w-full h-full flex justify-center items-center"
                  textColor="hover:text-white"
                  title={poll?.option?.text || poll?.text}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </section>
  );
}

PollingCard.propTypes = {
  blogId: PropTypes.string,
  pollingData: PropTypes.object
};

export default PollingCard;
