import React, { useState } from 'react';
import '../../styles/pages/blogs.scss';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import { Link } from 'gatsby';
import { stringTruncate, blogCustomUrl, screenWidth, blogImageAccordingToLayout } from '../../helper/helpers';
import XXSText from '../ui/typography/XXSText';
export default function LatestBlogCard({ data }) {
  const [handleLink, setHandleLink] = useState(false);
  return (
    <Link
      onMouseEnter={() => setHandleLink(true)}
      onMouseLeave={() => setHandleLink(false)}
      to={blogCustomUrl(data)}
      className="hover:no-underline rounded-lg new-blog-card-container cursor-pointer"
    >
      <div className="latest-blog-card-img-container">
        <img
          className={`rounded-lg latest-blog-card-img`}
          src={blogImageAccordingToLayout(screenWidth, data?.data)}
          alt={data.data.header_image?.alt ? data.data.header_image?.alt : 'card image'}
          loading="lazy"
          width="260"
          height="260"
        />
      </div>
      <div className="latest-blog-card-heading pt-6">
        <XXSText
          className="mb-2"
          textColor="text-coral-500"
          fontWeight="text-bold"
          title={data?.data?.category?.document?.data?.title?.text}
        />
        <BaseText
          title={stringTruncate(data.data.title.text, 60)}
          fontWeight="text-normal"
          className={`${handleLink && 'underline text-coral-500'}`}
          textColor={`${handleLink ? 'text-coral-500' : 'text-charcoal-dark'}`}
          lineHeight="leading-6"
          letterSpacing="new-blog-card-letter-spacing"
        />
      </div>
    </Link>
  );
}

LatestBlogCard.propTypes = {
  data: PropTypes.object
};
