import * as actionTypes from "./actionTypes";

let initialState = {
  generateResetPasswordOtp: {},
  loading: false,
  error: false,
}

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.GENERATE_RESET_PASSWORD_OTP_START:
      return { ...state, loading: true };
    case actionTypes.GENERATE_RESET_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GENERATE_RESET_PASSWORD_OTP_SUCCESS:
      return { ...state, generateResetPasswordOtp: { ...action.generateResetPasswordOtp }, loading: false, error: false };

    default: return state;

  }
}
export default reducer;