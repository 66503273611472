import React from 'react';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import '../../styles/pages/blogTemplate/ReportKeyPoints.scss';
import ArrowIcon from '../../images/icons/arrow-corner-left-coral.svg';
import { MUI_COLORS } from '../../constants/enum';
export default function ReportKeyPoints({ slice }) {
  return (
    <div
      className={`mx-auto pb-8 ${
        !slice?.primary?.disable_full_screen_height && 'min-h-screen'
      } flex flex-col justify-center`}
      style={{
        backgroundColor: slice?.primary?.background_color ? slice?.primary?.background_color : MUI_COLORS?.SUNGLOW
      }}
    >
      <div className="max-w-6xl mx-auto md:px-0 px-4">
        <h2 className="text-center">{slice?.primary?.report_key_points_title?.text}</h2>
        <BaseText
          textAlign="text-center"
          className=" mx-auto max-w-xl px-4"
          title={slice?.primary?.report_key_points_description?.text}
        />
        <div className="flex flex-wrap items-start justify-center gap-4 mt-10">
          {slice?.items?.map((cardData, index) => (
            <div key={index} className="bg-white p-6 rounded-lg report-key-points-card">
              {cardData?.card_image?.url && (
                <img
                  src={cardData?.card_image?.url}
                  alt={cardData?.card_image?.url ? cardData?.card_image?.url : 'key-points-img'}
                />
              )}

              <div dangerouslySetInnerHTML={{ __html: cardData?.card_content?.html }}></div>
              <a className="cursor-pointer" href={cardData?.card_custom_url?.text}>
                <div className="flex gap-2 items-center">
                  {cardData?.custom_url_label?.text}
                  <img src={ArrowIcon} className="h-4 transform rotate-45" alt="arrow" />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ReportKeyPoints.propTypes = {
  slice: PropTypes.object
};
