import React from 'react';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
import PropTypes from 'prop-types';

export default function LongArticleSectionHeader({ headerData }) {
  return (
    <>
      <GatsbyImageWrapper
        className="my-12 rounded-lg"
        image={{ url: headerData?.image?.url, width: 1152, height: 481 }}
        alt={headerData?.image?.alt}
      />
      <h2 className="text-bold max-w-[847px] md:text-[42px] text-[32px] leading-[100%] mb-12">
        {headerData?.section_header_title?.text}
      </h2>
      <div
        className="max-w-[847px] long-article-section-header long-article-richtext text-[26px] leading-[130%] mb-6"
        dangerouslySetInnerHTML={{ __html: headerData?.section_header_content?.html }}
      ></div>
    </>
  );
}

LongArticleSectionHeader.propTypes = {
  headerData: PropTypes?.object
};
