import React from 'react';
import H2HeaderText from '../ui/typography/H2HeaderText';
import PropTypes from 'prop-types';
import SolutionForBusinessSection from './SolutionForBusinessSection';

export default function VirtualAccounts({ useCase, className }) {
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <H2HeaderText
        className="solutions-heading md:whitespace-pre-line"
        textColor="text-blue-800"
        fontWeight="text-bold"
        textAlign="text-center"
        title={`Are You in Good Company?\nSee Who We Work With!`}
      />
      <div className="mt-14">
        <SolutionForBusinessSection useCaseInfo={useCase} />
      </div>
    </div>
  );
}

VirtualAccounts.propTypes = {
  useCase: PropTypes.array,
  className: PropTypes.string
};
