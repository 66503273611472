import React from "react";
import Faq from "../common/Faq"
import BaseText from "../ui/typography/BaseText";
import { Link } from "gatsby";

const question1 = 'Which companies can apply for a Business Account?'
const answer1 = 'Companies registered in Hong Kong, Singapore and the BVI can apply for a Business Account with Statrys.'
const question2 = 'What information and documents are required to apply for a Business Account?'
const answer2 = (<div>The following documents are required to submit your application:
  <BaseText className="pt-6" title={(<div><span className="text-coral-500">•</span> The business registration certificate of your Hong Kong Company (or equivalent document for a Singaporean or BVI company)</div>)} />
  <BaseText className="pt-6" title={<div><span className="text-coral-500">•</span> Passport copy of all directors</div>} />
  <BaseText className="py-6" title={<div><span className="text-coral-500">•</span> Passport copy of all shareholders</div>} />
  In addition to these documents, you will be requested to answer some questions that can help us to understand the business of your company.
  The process for submitting your application is 100% online and takes about 10min.</div>)
const question3 = 'What are my Business Account details?'
const answer3 = 'Your Business Account details are as follows: \n\nAccount name: Statrys - Your company name Account Number: Your unique account number \nSWIFT: SWIFT code of our custodian bank in Hong Kong'
const question4 = 'How long does it take for an incoming payment to be credited to my Business Account?'
const answer4 = (<div>All incoming payments are credited to your Business Account as soon as they are received by our custodian bank in Hong Kong. Payments are received during business hours: 8am to 5pm Hong Kong time, Monday to Friday (except public holidays).
  <BaseText className="pt-6" title={(<div>The day on which your Business Account is credited actually depends on the nature of the payment made by the remitter:</div>)} />
  <BaseText className="pt-6" title={(<div><span className="text-coral-500">•</span> Autopay payment from another Hong Kong bank account = your Business Account is credited the next working day</div>)} />
  <BaseText className="pt-6" title={<div><span className="text-coral-500">•</span> FPS/RGTS/CHATS payment from another Hong Kong bank account = your Business Account is credited the same working day</div>} />
  <BaseText className="pt-6" title={<div><span className="text-coral-500">•</span> SWIFT payment (ie.international transfer) from a bank account outside of Hong Kong = your Business Account is usually credited after 2 or 3 working days. The actual time may be shorter or longer depending on several factors: who is the issuing bank, whether this bank has a direct relationship with our custodian bank in Hong Kong, the currency in which payment is made, etc.</div>} />
</div>)
const question5 = 'How long does it take for an outgoing payment to be credited to the bank account of my beneficiary?'
const answer5 = (<div>All outgoing payments are processed on the same working day, provided that payment instructions are given during business hours. Business hours are from 8am to 5pm Hong Kong time, Monday to Friday (except public holidays).
  <BaseText className="pt-6" title={(<div>The day on which your payment is credited to your beneficiary&apos;s bank account actually depends on the nature of the payment:</div>)} />
  <BaseText className="pt-6" title={(<div><span className="text-coral-500">•</span> Autopay payment to another Hong Kong bank account = your beneficiary&apos;s bank account is credited the next working day. At your request payment can be credited on the same day (subject to a fee of 55HKD).</div>)} />
  <BaseText className="pt-6" title={<div><span className="text-coral-500">•</span> SWIFT payment (ie.international transfer) to a bank account outside of Hong Kong = your beneficiary&apos;s bank account is usually credited after 2 or 3 working days. The actual time may be shorter or longer depending on several factors: who is the receiving bank, whether this bank has a direct relationship with our custodian bank in Hong Kong, the currency in which payment is made, etc.</div>} />
</div>)
const question6 = 'What are the fees associated with your Business Account?'
const answer6 = (<div ><Link to="/pricing" className="text-bold text-coral-500 cursor-pointer" >Check the applicable fees</Link> for the use of your Business Account.</div>)
function BusinessFaq() {
  return (
    <div>
      <Faq
        faqData={[{ question: question1, answer: answer1 }, { question: question2, answer: answer2 }, { question: question3, answer: answer3 }, { question: question4, answer: answer4 }, { question: question5, answer: answer5 }, { question: question6, answer: answer6 }]}
      />
    </div>
  );
}
export default BusinessFaq;
