import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BaseText from './typography/BaseText';
import '../../styles/table-content.scss';
import { isBrowser, percentage } from '../../helper/helpers';
import { useHeadsObserver } from '../../customHooks/useHeadsObserver';
import Line from '../common/Line';
import ProgressBar from './ProgressBar';
import DownArrow from '../../images/icons/arrow-select-input.svg';
import UpArrow from '../../images/icons/up-arrow.svg';
import UploadBottomIcon from '../../images/icons/inline/upload-bottom.inline.svg';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon
} from 'react-share';

function TableofContents({ tocs, fullwidth, longToc, isTableInViewPort }) {
  const { activeId } = useHeadsObserver();
  const [percentageOfProgressBar, setPercentageOfProgressBar] = useState(2);
  const getClassName = (level) => {
    switch (level) {
      case 2:
        return 'head2';
      case 3:
        return 'head3';
      default:
        return null;
    }
  };
  const checkingActiveH3Tag = (h2Headings) => {
    const activeElementId = h2Headings?.find((item) => item?.id === activeId);
    const activeParentId = activeElementId?.parentId;
    return activeParentId;
  };

  useEffect(() => {
    const tocContainer = document.querySelectorAll('.toc-scroll-container');
    const heading2 = document.querySelectorAll('#toc-header, .toc-h3-ul');
    for (let i = 0; i < heading2?.length; i++) {
      const distanceOfHighLighted = heading2[i].querySelectorAll('p, li');
      if (distanceOfHighLighted) {
        for (let j = 0; j < distanceOfHighLighted.length; j++) {
          const highLightedH2 = distanceOfHighLighted?.[j]?.classList?.contains('text-bold');
          if (highLightedH2) {
            const calculatedPercentage = percentage(
              tocContainer?.[0]?.getBoundingClientRect().height,
              distanceOfHighLighted?.[j]?.offsetTop
            );
            setPercentageOfProgressBar(calculatedPercentage);
          }
        }
      }
    }
  }, [activeId]);
  let url, parts, modifiedUrl;
  url = isBrowser && window.location.href;
  parts = url && url?.split('#');
  modifiedUrl = parts.length > 1 ? parts[0] : url;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      className={`${longToc ? 'long-toc' : 'toc'} ${fullwidth ? 'w-full' : 'content-continer '} ${
        !isTableInViewPort && 'sticky top-24'
      }`}
    >
      <BaseText className="mb-4 toc-heading" title="In this article" fontWeight="text-bold" />
      <div className="flex flex-row relative mt-6 toc-scroll-container">
        <ProgressBar completed={percentageOfProgressBar + 2} />
        <div className="flex flex-col w-full">
          <ul className="list-none pl-0 toc-ul">
            {tocs?.map((toc) => {
              return (
                <li
                  key={toc?.id}
                  className={`${getClassName(
                    toc?.level
                  )} list-disc md:font-light  text-coral-500 font-semibold toc-list`}
                >
                  <div className="flex flex-row">
                    <BaseText
                      id="toc-header"
                      fontWeight={`${activeId === toc?.id ? 'text-bold' : 'text-normal'}`}
                      title={
                        <a
                          href={`#${toc?.id}`}
                          dangerouslySetInnerHTML={{ __html: toc?.title }}
                          className={`text-gray-150 hover:underline`}
                        ></a>
                      }
                    />
                    {toc?.items?.length > 0 && (
                      <img
                        src={activeId === toc?.id ? UpArrow : DownArrow}
                        className="toc-heading-arrow transition-all delay-75 ease-in-out"
                        alt="drop down arrow"
                      />
                    )}
                  </div>
                  <BaseText
                    className="toc-h3-heading"
                    title={
                      toc?.items?.length > 0 && (
                        <ul className="toc-h3-ul">
                          {toc?.items?.map((child) => {
                            return (
                              <li
                                key={child.id}
                                className={`${child?.id === activeId ? 'text-bold ' : 'text-normal'} ${getClassName(
                                  child?.level
                                )}  ${
                                  activeId === toc?.id || checkingActiveH3Tag(toc?.items) === child?.parentId
                                    ? 'block'
                                    : 'hidden'
                                }`}
                              >
                                <a href={`#${child?.id}`} className="hover:underline">
                                  {child?.title}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )
                    }
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="mr-4">
        <Line className="ml-4" />
      </div>
      <BaseText
        title={<a href="#related-articles" dangerouslySetInnerHTML={{ __html: 'Related articles' }}></a>}
        className="mt-4 ml-2"
      />
      <div className="flex flex-row social-links-container gap-3 mt-4 ml-4">
        <FacebookShareButton url={modifiedUrl} quote={'ferd'}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={modifiedUrl} quote={'ferd'}>
          <XIcon size={32} round />
        </TwitterShareButton>
        <TelegramShareButton url={modifiedUrl} quote={'ferd'}>
          <TelegramIcon size={32} round />
        </TelegramShareButton>
        <LinkedinShareButton url={modifiedUrl} quote={'ferd'}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <WhatsappShareButton url={modifiedUrl} quote={'ferd'}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
        <div onClick={() => scrollToTop()} className={`bg-coral-500 p-[6px] rounded-lg cursor-pointer`}>
          <UploadBottomIcon />
        </div>
      </div>
    </div>
  );
}

export default TableofContents;

TableofContents.propTypes = {
  tocs: PropTypes.any,
  fullwidth: PropTypes.bool,
  longToc: PropTypes.bool,
  isTableInViewPort: PropTypes.bool
};
