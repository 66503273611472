import React from 'react';
import PropTypes from 'prop-types';

const BlogImageWithLink = ({ imageData }) => {
  return (
    <>
      {imageData?.link?.url ? (
        <a
          href={imageData?.link?.url}
          rel={imageData?.nofollow_and_noreferrer ? 'noopener noreferrer nofollow' : 'opener'}
          target={imageData?.link?.target}
        >
          <img src={imageData?.image?.url} alt={imageData?.image?.alt ? imageData?.image?.alt : 'blog image'} />
        </a>
      ) : (
        <img src={imageData?.image?.url} alt={imageData?.image?.alt ? imageData?.image?.alt : 'blog image'} />
      )}
    </>
  );
};

export default BlogImageWithLink;

BlogImageWithLink.propTypes = {
  imageData: PropTypes.object,
  className: PropTypes.string
};
