import axios from 'axios';

const Api = axios.create({
  baseURL: process.env.INVOICE_BASE_API_URL
});

Api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default Api;
