import React from 'react';
import AlertTriangle from '../../images/icons/alert-triangle.svg';
import MSText from './typography/MSText';
import PropTypes from 'prop-types';

export default function ValidationMessage({ title, contentAlignment }) {
  return (
    <div className={`flex gap-2 mt-2 ${contentAlignment}`}>
      <img src={AlertTriangle} alt="alert triangle" />
      <MSText textColor="text-red-alert" title={title} className="md:whitespace-pre-line" />
    </div>
  );
}

ValidationMessage.propTypes = {
  title: PropTypes.string,
  contentAlignment: PropTypes.string
};

ValidationMessage.defaultProps = {
  contentAlignment: 'items-center'
};
