import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
const WhatsAppIcon = ({ fill, stroke }) => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_36_492)">
        <path
          d="M16.668 0.119966C12.4452 0.119848 8.39365 1.78907 5.39649 4.76375C2.39933 7.73843 0.699653 11.7773 0.667982 16C0.663747 19.2314 1.66054 22.3848 3.52132 25.0266L1.70798 30.36C1.67065 30.4772 1.66581 30.6023 1.69399 30.7221C1.72216 30.8418 1.7823 30.9517 1.86798 31.04C1.95544 31.1269 2.0653 31.1879 2.18535 31.2162C2.3054 31.2444 2.43093 31.2388 2.54798 31.2L8.13465 29.4266C10.2183 30.7677 12.5862 31.6039 15.0499 31.8686C17.5136 32.1334 20.005 31.8194 22.326 30.9516C24.647 30.0838 26.7334 28.6863 28.4191 26.8702C30.1048 25.054 31.3433 22.8695 32.036 20.4904C32.7287 18.1113 32.8566 15.6034 32.4093 13.1662C31.962 10.729 30.9521 8.42987 29.4597 6.45178C27.9674 4.47368 26.0339 2.87124 23.8132 1.772C21.5925 0.672766 19.1458 0.107079 16.668 0.119966ZM26.268 22.4133C25.8228 23.263 25.1513 23.9731 24.3277 24.4649C23.5041 24.9567 22.5605 25.2111 21.6013 25.2C19.9998 25.0166 18.4404 24.5659 16.988 23.8666C13.7396 22.3853 10.9891 19.9959 9.06798 16.9866C6.50798 13.6133 6.40132 10.4533 8.84132 7.83997C9.17927 7.52301 9.58722 7.29021 10.032 7.16047C10.4768 7.03074 10.946 7.00772 11.4013 7.0933C11.7458 7.13655 12.0736 7.26702 12.3536 7.47234C12.6336 7.67767 12.8565 7.95106 13.0013 8.26663L13.5213 9.4933L14.3613 11.5066C14.4667 11.7131 14.5216 11.9415 14.5216 12.1733C14.5216 12.4051 14.4667 12.6335 14.3613 12.84C14.02 13.5179 13.5696 14.135 13.028 14.6666C13.7216 15.7023 14.5264 16.6591 15.428 17.52C16.5017 18.4594 17.7343 19.1999 19.068 19.7066C19.4413 19.2133 20.1613 18.3733 20.4013 18C20.5813 17.7066 20.8678 17.4943 21.2008 17.4073C21.5338 17.3203 21.8875 17.3655 22.188 17.5333C22.6946 17.7066 25.5346 19.1066 25.5346 19.1066C25.8977 19.2243 26.2148 19.4528 26.4413 19.76C26.6471 20.1787 26.7394 20.6442 26.7089 21.1098C26.6785 21.5754 26.5265 22.0249 26.268 22.4133Z"
          fill={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_36_492">
          <rect width="32" height="32" fill={fill} transform="translate(0.667969)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default WhatsAppIcon;

WhatsAppIcon.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string
};

WhatsAppIcon.defaultProps = {
  stroke: MUI_COLORS.CORAL,
  fill: MUI_COLORS.CORAL
};
