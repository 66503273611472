import React from 'react';

function giftBox() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_4287_5920)">
        <path
          d="M19.4043 10.7866H2.3418V19.9741C2.3418 20.3222 2.48008 20.6561 2.72622 20.9022C2.97236 21.1483 3.3062 21.2866 3.6543 21.2866H18.0918C18.4399 21.2866 18.7737 21.1483 19.0199 20.9022C19.266 20.6561 19.4043 20.3222 19.4043 19.9741V10.7866Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.4043 6.84912H2.3418C1.9937 6.84912 1.65986 6.9874 1.41372 7.23354C1.16758 7.47968 1.0293 7.81352 1.0293 8.16162V10.1304C1.0293 10.3044 1.09844 10.4713 1.22151 10.5944C1.34458 10.7175 1.5115 10.7866 1.68555 10.7866H20.0605C20.2346 10.7866 20.4015 10.7175 20.5246 10.5944C20.6477 10.4713 20.7168 10.3044 20.7168 10.1304V8.16162C20.7168 7.81352 20.5785 7.47968 20.3324 7.23354C20.0862 6.9874 19.7524 6.84912 19.4043 6.84912Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2168 6.84912C7.31705 6.84912 4.31055 4.49887 4.31055 1.59912"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.31055 1.59912C7.2103 1.59912 10.2168 3.94937 10.2168 6.84912"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5293 6.84912C14.429 6.84912 17.4355 4.49887 17.4355 1.59912"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4355 1.59912C14.5358 1.59912 11.5293 3.94937 11.5293 6.84912"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8418 6.84912H8.9043V21.2866H12.8418V6.84912Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4287_5920">
          <rect width="21" height="21" fill="white" transform="translate(0.373047 0.942871)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default giftBox;
