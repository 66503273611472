import React from 'react';
import PropTypes from 'prop-types';

export default function richText({ content }) {
  return (
    <>
      {
        <div
          className="my-6 long-article-richtext"
          dangerouslySetInnerHTML={{ __html: content?.page_content?.html }}
        ></div>
      }
    </>
  );
}

richText.propTypes = {
  content: PropTypes?.object
};
