import React from 'react';
import BaseText from '../ui/typography/BaseText';
import H2HeaderText from '../ui/typography/H2HeaderText';
import H3HeaderText from '../ui/typography/H3HeaderText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

export default function WhitePaperCta({ fullwidth }) {
  return (
    <div className={`${fullwidth && 'max-w-6xl mx-auto'}`}>
      <div className={`bg-customBg-secondaryBg flex flex-col md:flex-row md:gap-10 gap-5 rounded-lg mt-56`}>
        <div className="w-full">
          <StaticImage src="../../images/graphics/white-paper-cta.png" className="ml-8 -mt-36" alt="white paper cta" />
        </div>
        <div className="md:w-10/12 md:ml-0 p-5 mb-10">
          <H3HeaderText className="mt-10 mb-2" title="White Paper" />
          <H2HeaderText
            fontWeight="text-regular"
            title="8 Things to know about Opening a Business Account in Hong Kong"
          />
          <BaseText
            className="mt-4 mb-8"
            title="Whether you're starting a new business in Hong Kong, or you have an established business that you're looking to expand into Asia, this whitepaper will help you get started. "
          />
          <div className="md:text-left text-center">
            <PrimaryButton
              urlLink="/resources/whitepapers/8-things-to-know-opening-a-business-account-hong-kong"
              className="w-72"
              caption="Download Free White Paper"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

WhitePaperCta.propTypes = {
  fullwidth: PropTypes.bool
};
