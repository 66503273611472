import React from 'react';
import Ratings from 'react-ratings-declarative';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
function StarRating({ value }) {
  let widgetDimensions = '20px';
  let widgetSpacings = '2px';
  const windowGlobal = typeof window !== 'undefined';
  if (windowGlobal) {
    if (window.innerWidth < 600) {
      widgetDimensions = '15px';
      widgetSpacings = '2px';
    }
  }
  return (
    <>
      <Ratings
        rating={value ? value : 0}
        widgetDimensions={widgetDimensions}
        widgetSpacings={widgetSpacings}
        svgIconPaths="M9.07601 13.509C8.77845 13.3475 8.41936 13.3475 8.1218 13.509L4.99029 15.209C4.25859 15.6063 3.39028 14.9876 3.5267 14.1663L4.14013 10.4731C4.19282 10.1559 4.08972 9.83262 3.86305 9.60447L1.24083 6.96507C0.663385 6.38385 0.991616 5.39292 1.80185 5.27135L5.37014 4.73594C5.69842 4.68668 5.98086 4.47773 6.124 4.17823L7.69666 0.887768C8.05912 0.129398 9.13869 0.129397 9.50115 0.887768L11.0738 4.17823C11.2169 4.47773 11.4994 4.68668 11.8277 4.73594L15.396 5.27135C16.2062 5.39292 16.5344 6.38385 15.957 6.96507L13.3348 9.60447C13.1081 9.83262 13.005 10.1559 13.0577 10.4731L13.6711 14.1663C13.8075 14.9876 12.9392 15.6063 12.2075 15.209L9.07601 13.509Z"
        svgIconViewBoxes="0 0 17 16"
        widgetRatedColors={MUI_COLORS.SUNGLOW}
      >
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
        <Ratings.Widget />
      </Ratings>
    </>
  );
}

StarRating.propTypes = {
  value: PropTypes.any
};

export default StarRating;
