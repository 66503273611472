import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import ReadMoreLink from '../ui/Link/ReadMoreLink';
import H3HeaderText from '../ui/typography/H3HeaderText';
import '../../styles/pages/home/solutions-for-business.scss';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function SolutionForBusinessSection({ useCaseInfo }) {
  const defalutOpenUseCase = 0;
  const [openContent, setOpenContent] = useState({
    title: useCaseInfo[defalutOpenUseCase]?.node?.data?.title?.text,
    image: useCaseInfo[defalutOpenUseCase]?.node?.data?.image?.url,
    description: useCaseInfo[defalutOpenUseCase]?.node?.data?.scroller_description?.text,
    uid: useCaseInfo[defalutOpenUseCase]?.node?.uid,
    tabBackgroundColor: useCaseInfo[defalutOpenUseCase]?.node?.data?.tab_background_color,
    figure: useCaseInfo[defalutOpenUseCase]?.node?.data?.figure?.text
  });

  const selectSolution = (useCaseData) => {
    setOpenContent({
      icon: useCaseData?.node?.data?.icon?.url,
      title: useCaseData?.node?.data?.title?.text,
      image: useCaseData?.node?.data?.image?.url,
      description: useCaseData?.node?.data?.scroller_description?.text,
      uid: useCaseData?.node?.uid,
      tabBackgroundColor: useCaseData?.node?.data?.tab_background_color,
      figure: useCaseData?.node?.data?.figure.text
    });
  };

  return (
    <div className="flex md:flex-row flex-col gap-2 w-full solution-for-business-container">
      <div
        className={`md:w-4/6 w-full flex md:flex-row flex-col justify-between rounded-2xl border border-gray-400`}
        style={{ backgroundColor: openContent?.tabBackgroundColor }}
      >
        <div className="md:my-8 my-8 md:mx-10 mx-auto solutions-content">
          <H3HeaderText title={openContent?.title} fontWeight="text-bold" textColor="text-green-600" />
          <div className="flex flex-col h-full justify-center">
            {openContent?.figure && (
              <BaseText
                fontSize="text-[109.417px]"
                fontWeight="text-bold"
                lineHeight="leading-[80%]"
                letterSpacing="letter-spacing-[-0.842]"
                title={openContent?.figure}
                textColor="text-green-600"
              />
            )}
            <BaseText
              title={openContent?.description}
              fontSize="text-[32px]"
              lineHeight="leading-[100%]"
              letterSpacing="letter-spacing-[-0.842]"
              textClassName="max-w-[14ch]"
              textColor="text-green-600"
            />
            <ReadMoreLink
              uid={`/use-case/${openContent?.uid}`}
              className="mt-4"
              title="See case study"
              textColor="text-green-500"
            />
          </div>
        </div>
        <div className="solutions-image-cotainer flex self-end">
          <GatsbyImageWrapper
            image={{
              url: openContent?.image,
              width: 349,
              height: 365
            }}
            alt={useCaseInfo[0]?.node?.data?.image?.alt}
            loading="lazy"
          />
        </div>
      </div>
      <div className="md:w-2/6 w-full flex flex-wrap gap-2 md:justify-start justify-center">
        {useCaseInfo?.map((useCase, index) => {
          return (
            <div
              className={`solutions-forbusiness-small-box cursor-pointer rounded-2xl border border-gray-400`}
              style={{ backgroundColor: useCase?.node?.data?.button_background_color }}
              key={index}
              onClick={() => selectSolution(useCase)}
            >
              <div className="flex flex-col items-center justify-center h-full">
                <GatsbyImageWrapper
                  image={{
                    url: useCase?.node?.data?.icon?.url,
                    width: 30,
                    height: 32
                  }}
                  className="use-case-icon"
                  alt={useCase?.node?.data?.icon?.url}
                  loading="lazy"
                />
                <MDSubtitleText
                  title={useCase?.node?.data?.title?.text}
                  style={{ color: useCase?.node?.data?.link_color }}
                  fontWeight="text-bold"
                  className="mt-1 w-11/12"
                  textAlign="text-center"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
SolutionForBusinessSection.propTypes = {
  useCaseInfo: PropTypes.array
};
