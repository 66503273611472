import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
export default function FaqArticleList({ faqList, articleUid, setHandleDropDown }) {
  useEffect(() => {
    if (faqList?.faqs_article_link?.document?.uid === articleUid) {
      setHandleDropDown(true);
    }
  }, []);
  return (
    <>
      {faqList?.faqs_article_link?.document?.uid && (
        <li
          key={faqList?.key}
          className={`pb-2 list-disc text-lg ${
            faqList?.faqs_article_link?.document?.uid === articleUid && 'text-coral-500 text-bold'
          } `}
        >
          <Link
            to={`/faq/${faqList?.faqs_article_link?.document?.data?.category?.uid}/${faqList?.faqs_article_link?.document?.uid}`}
          >
            {faqList?.faqs_article_link?.document?.data?.title?.text
              ? faqList?.faqs_article_link?.document?.data?.title?.text
              : null}
          </Link>
        </li>
      )}
    </>
  );
}

FaqArticleList.propTypes = {
  faqList: PropTypes.object,
  articleUid: PropTypes.string,
  setHandleDropDown: PropTypes.func
};
