import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  type,
  unFollow,
  metaImage,
  faqData,
  breadCrumbData,
  noIndex,
  companySecName,
  ratingCount,
  ratingValue,
  articleSchemaData,
  videoSchema,
  preLoadHeaderImage
}) {
  const query = graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
      ogImageDefault: file(absolutePath: { regex: "/images/statrys-pay-like-pro/" }) {
        childImageSharp {
          fixed(height: 630, width: 1200) {
            src
          }
        }
      }
      prismicScripts {
        data {
          script {
            script_data {
              text
            }
            id {
              text
            }
            pre_connect_href {
              text
            }
            src {
              text
            }
            loading_type
            type
          }
        }
      }
    }
  `;
  const DEFER = 'defer';
  const ASYNC = 'async';
  const SYNC = 'sync';
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const { site, ogImageDefault } = data;
        const metaDescription = description || site.siteMetadata.description;
        const ogImage = metaImage || site.siteMetadata.siteUrl.concat(ogImageDefault.childImageSharp.fixed.src);

        let faqSchema = {};
        if (faqData[0]?.question?.text) {
          const FAQ = faqData.map((faq) => {
            return {
              '@type': 'Question',
              name: faq.question.text,
              acceptedAnswer: {
                '@type': 'Answer',
                text: faq.answer.html
              }
            };
          });
          faqSchema = {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: FAQ
          };
        }

        let breadCrumbSchema = {};
        if (breadCrumbData[0]?.position) {
          const breadCrumb = breadCrumbData.map((data) => {
            return {
              '@type': 'ListItem',
              position: data?.position,
              name: data?.name,
              item: data?.item
            };
          });
          breadCrumbSchema = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadCrumb
          };
        }
        let companySecReviewSchema = {
          '@context': 'http://schema.org',
          '@type': 'Product',
          name: companySecName,
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingCount: ratingCount,
            ratingValue: ratingValue,
            author: [
              {
                '@type': 'Organization',
                name: 'Statrys',
                url: 'https://statrys.com/'
              }
            ]
          },
          review: [
            {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                bestRating: 5,
                worstRating: 1,
                ratingValue: ratingValue
              },
              author: [
                {
                  '@type': 'Organization',
                  name: 'Statrys',
                  url: 'https://statrys.com/'
                }
              ]
            }
          ]
        };
        let articleSchema = {
          '@context': 'https://schema.org',
          '@type': 'Article',
          headline: articleSchemaData?.title,
          image: [ogImage],
          datePublished: articleSchemaData?.datePublished,
          dateModified: articleSchemaData?.dateModified,
          author: articleSchemaData?.author
        };
        let commonSchema = {
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@context': 'https://schema.org',
              '@type': 'Organization',
              '@id': 'https://statrys.com/#organization',
              name: 'Statrys',
              url: 'https://statrys.com',
              image: 'https://statrys.com/static/515f05d8834ed459e47017f8a3790618/3b869/statrys-pay-like-pro.png',
              description:
                'Statrys helps entrepreneurs and small-business enterprises register in Hong Kong and access a multi-currency business accounts with a human touch',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '17/F, 23 Thomson Rd',
                addressRegion: 'HK',
                addressCountry: 'HK',
                addressLocality: 'Hong Kong',
                postalCode: '00000'
              },
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'Support',
                telephone: '+852 5803 2818',
                email: 'support@statrys.com'
              },
              foundingDate: 2018,
              legalName: 'Statrys Ltd.',
              logo: 'https://images.prismic.io/statrys/b649af93-27c2-431a-a9e6-573797d8b10c_statrys+logo+small%402x.png',
              founders: [
                {
                  '@type': 'Person',
                  name: 'Bertrand Theaud'
                }
              ],
              sameAs: [
                'https://www.youtube.com/c/statrys',
                'https://www.facebook.com/statrys',
                'https://www.twitter.com/statrys',
                'https://www.linkedin.com/company/statrys'
              ]
            },
            {
              '@type': 'WebPage',
              url: 'https://statrys.com',
              name: 'Statrys',
              description: 'Hong Kong Company Registration and Business Account with a human touch.'
            },
            {
              '@type': 'WebSite',
              name: 'Statrys',
              url: 'https://statrys.com',
              potentialAction: [
                {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: 'https://statrys.com/search?query={search_term_string}'
                  },
                  'query-input': 'required name=search_term_string'
                }
              ]
            }
          ]
        };
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            meta={[
              {
                name: `title`,
                content: `${title}`
              },
              {
                name: `description`,
                content: metaDescription
              },
              {
                name: `twitter:title`,
                content: `${title}`
              },
              {
                name: `twitter:description`,
                content: metaDescription
              },
              {
                property: `twitter:image`,
                content: ogImage
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:site`,
                content: `@Statrys`
              },
              {
                name: `twitter:creator`,
                content: `Statrys`
              },
              {
                property: `og:title`,
                content: `${title}`
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:image`,
                content: ogImage
              },
              {
                name: `image`,
                content: ogImage
              },
              {
                property: `og:type`,
                content: `${type === 'blog' ? 'article' : type === 'blogHome' ? 'blog' : 'website'}`
              },
              {
                property: `og:site_name`,
                content: `Statrys`
              },
              {
                property: `og:url`,
                content: typeof window !== `undefined` && window?.location?.href
              },
              {
                property: `og:locale`,
                content: `en_US`
              },
              {
                name: `theme-color`,
                content: `#E15741`
              },
              !noIndex
                ? {
                    name: 'robots',
                    content: 'max-image-preview:large, max-snippet:-1, max-video-preview:-1'
                  }
                : {},
              !noIndex
                ? {
                    name: 'msapplication-TileColor',
                    content: '#E15741'
                  }
                : {},
              articleSchemaData?.datePublished
                ? {
                    property: 'article:published_time',
                    content: articleSchemaData?.datePublished
                  }
                : {},
              articleSchemaData?.dateModified
                ? {
                    property: 'article:modified_time',
                    content: articleSchemaData?.dateModified
                  }
                : {},
              articleSchemaData?.author
                ? {
                    name: 'author',
                    content: articleSchemaData?.author[0]?.name
                  }
                : {},
              articleSchemaData?.author
                ? {
                    name: 'twitter:label1',
                    content: 'Written by'
                  }
                : {},
              articleSchemaData?.author
                ? {
                    name: 'twitter:data1',
                    content: articleSchemaData?.author[0]?.name
                  }
                : {},
              articleSchemaData?.readingTime
                ? {
                    name: 'twitter:label2',
                    content: `Reading time`
                  }
                : {},
              articleSchemaData?.readingTime
                ? {
                    name: 'twitter:data2',
                    content: articleSchemaData?.readingTime
                  }
                : {},
              unFollow || noIndex
                ? {
                    name: `robots`,
                    content: `noindex,nofollow`
                  }
                : {},
              unFollow || noIndex
                ? {
                    name: `googlebot`,
                    content: `noindex,nofollow`
                  }
                : {}
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
            title={`${title} | Statrys`}
          >
            {data?.prismicScripts?.data?.script?.map((scriptData) => {
              return (
                scriptData?.pre_connect_href?.text && (
                  <link rel="preconnect" href={scriptData?.pre_connect_href?.text} />
                )
              );
            })}
            {data?.prismicScripts?.data?.script?.map((scriptData) => {
              if (scriptData?.loading_type === DEFER) {
                return scriptData?.src?.text && <script defer src={scriptData?.src?.text} />;
              }
              if (scriptData?.loading_type === ASYNC) {
                return scriptData?.src?.text && <script async src={scriptData?.src?.text} />;
              }
              if (scriptData?.loading_type === SYNC) {
                return scriptData?.src?.text && <script src={scriptData?.src?.text} />;
              }
            })}
            {Object.keys(faqSchema).length && <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>}
            {Object.keys(breadCrumbSchema).length && (
              <script type="application/ld+json">{JSON.stringify(breadCrumbSchema)}</script>
            )}
            {preLoadHeaderImage && (
              <link rel="preload" as="image" href={preLoadHeaderImage} media="(max-width: 767px)"></link>
            )}
            {articleSchemaData && <script type="application/ld+json">{JSON.stringify(articleSchema)}</script>}
            {ratingValue && <script type="application/ld+json">{JSON.stringify(companySecReviewSchema)}</script>}
            <script type="application/ld+json">{JSON.stringify(commonSchema)}</script>
            {videoSchema && <script type="application/ld+json">{JSON.stringify(videoSchema)}</script>}
            {data?.prismicScripts?.data?.script?.map((scriptData, index) => {
              if (scriptData?.loading_type === DEFER) {
                return (
                  <script key={index} defer type={scriptData?.type} id={scriptData?.id?.text}>
                    {scriptData?.script_data?.text}
                  </script>
                );
              }
              if (scriptData?.loading_type === SYNC) {
                return (
                  <script key={index} type={scriptData?.type} id={scriptData?.id?.text}>
                    {scriptData?.script_data?.text}
                  </script>
                );
              }
              if (scriptData?.loading_type === ASYNC) {
                return (
                  <script key={index} async type={scriptData?.type} id={scriptData?.id?.text}>
                    {scriptData?.script_data?.text}
                  </script>
                );
              }
            })}
          </Helmet>
        );
      }}
    ></StaticQuery>
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
  pageType: 'staticPage',
  faqData: [],
  breadCrumbData: []
};

SEO.propTypes = {
  pageType: PropTypes.oneOf(['staticPage', 'blogPage', 'homePage', 'standAlonePage']),
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  unFollow: PropTypes.string,
  metaImage: PropTypes.any,
  faqData: PropTypes.any,
  breadCrumbData: PropTypes.any,
  noIndex: PropTypes.bool,
  companySecReview: PropTypes.any,
  companySecName: PropTypes.string,
  ratingCount: PropTypes.number,
  ratingValue: PropTypes.number,
  articleSchemaData: PropTypes.any,
  videoSchema: PropTypes.any,
  preLoadHeaderImage: PropTypes.string
};

export default SEO;
