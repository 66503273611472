import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import ArrowDown from '../../../images/icons/arrow-down-menu.svg';

function MenuText({ title, urlLink, withDropDown, linkClassName, imgClassName, isNew, id }) {
  const [navLine, setNavLine] = useState(false);

  return (
    <div className={`${navLine ? 'active' : null} `}>
      {urlLink ? (
        <Link
          to={urlLink}
          className={`menu-text text-lg ${navLine ? 'active' : null} ${linkClassName}`}
          onMouseEnter={() => setNavLine(true)}
          onMouseLeave={() => setNavLine(false)}
          id={id}
        >
          {title}
          {isNew && (
            <div className="w-[39px] flex justify-between items-center px-[5px] py-0 rounded-lg bg-coral-500 text-white text-[15px] font-bold tracking-[-0.5px] leading-[20px] ml-[5px]">
              New
            </div>
          )}
          {withDropDown ? <img src={ArrowDown} className={imgClassName} alt="arrow down" /> : ''}
        </Link>
      ) : (
        <div
          className={`menu-text cursor-pointer text-lg ${navLine ? 'active' : null} ${linkClassName}`}
          onMouseEnter={() => setNavLine(true)}
          onMouseLeave={() => setNavLine(false)}
        >
          {title}
          {isNew && (
            <div className="w-[39px] flex justify-between items-center px-[5px] py-0 rounded-lg bg-coral-500 text-white text-[15px] font-bold tracking-[-0.5px] leading-[20px] ml-[5px]">
              New
            </div>
          )}
          {withDropDown ? <img src={ArrowDown} className={imgClassName} alt="arrow down" /> : ''}
        </div>
      )}
    </div>
  );
}

export default MenuText;

MenuText.propTypes = {
  title: PropTypes.string,
  urlLink: PropTypes.string,
  withDropDown: PropTypes.bool,
  imgClassName: PropTypes.string,
  linkClassName: PropTypes.bool,
  isNew: PropTypes.bool,
  id: PropTypes.string
};

MenuText.defaultProps = {
  withDropDown: false
};
