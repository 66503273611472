import React from 'react';

function Hammer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <g clipPath="url(#clip0_4287_5967)">
        <path
          d="M12.2207 7.94263L20.3845 15.6339C20.5988 15.8487 20.7191 16.1398 20.7191 16.4433C20.7191 16.7467 20.5988 17.0378 20.3845 17.2526L19.5707 18.0664C19.3559 18.2806 19.0648 18.401 18.7614 18.401C18.4579 18.401 18.1669 18.2806 17.952 18.0664L10.2607 9.90263"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.74181 7.90769C6.46641 6.3947 8.71257 4.14854 10.2256 1.42394C10.4404 1.20968 10.7315 1.08936 11.0349 1.08936C11.3384 1.08936 11.6294 1.20968 11.8443 1.42394L14.9156 4.49519C15.1304 4.71155 15.2509 5.00406 15.2509 5.30894C15.2509 5.61382 15.1304 5.90633 14.9156 6.12269C12.1632 7.59456 9.9074 9.84731 8.43181 12.5977C8.21694 12.812 7.92588 12.9323 7.62244 12.9323C7.31899 12.9323 7.02793 12.812 6.81306 12.5977L3.74181 9.52644C3.52755 9.31157 3.40723 9.02051 3.40723 8.71707C3.40723 8.41362 3.52755 8.12256 3.74181 7.90769Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5293 18.5914C11.5293 18.2433 11.391 17.9095 11.1449 17.6634C10.8987 17.4172 10.5649 17.2789 10.2168 17.2789H3.6543C3.3062 17.2789 2.97236 17.4172 2.72622 17.6634C2.48008 17.9095 2.3418 18.2433 2.3418 18.5914V20.7789H11.5293V18.5914Z"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.0293 20.7789H12.8418"
          stroke="#097D75"
          strokeWidth="1.3125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4287_5967">
          <rect width="21" height="21" fill="white" transform="translate(0.373047 0.435181)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Hammer;
