import * as actionTypes from "./actionTypes";

let initialState = {
    jurisdictionCountry: {},
    sentOtp: {},
    verifyOtp:{},
    customerUid:{},
    validateToken: {},
    setPassword: {},
    userLogin: {},
    userOnboarding: {},
    nationalitiesList: {},
    otherJurisdictionCountriesList: {},
    loading: false,
    error: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.JURISDICTION_COUNTRY_REQUEST_SUCCESS:
            return { ...state, jurisdictionCountry: { ...action.jurisdictionCountry }, loading: false, error: false };

        case actionTypes.SENT_OTP_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.SENT_OTP_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.SENT_OTP_REQUEST_SUCCESS:
            return { ...state, sentOtp: { ...action.sentOtp }, loading: false, error: false };

        case actionTypes.VERIFY_OTP_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.VERIFY_OTP_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VERIFY_OTP_REQUEST_SUCCESS:
            return { ...state, verifyOtp: { ...action.verifyOtp }, loading: false, error: false };

        case actionTypes.CUSTOMER_UID_REQUEST_SUCCESS:
            return { ...state, customerUid: { ...action.customerUid }, loading: false, error: false };

        case actionTypes.VALIDATE_TOKEN_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.VALIDATE_TOKEN_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.VALIDATE_TOKEN_REQUEST_SUCCESS:
            return { ...state, validateToken: { ...action.validateToken }, loading: false, error: false };

        case actionTypes.SET_PASSWORD_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.SET_PASSWORD_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.SET_PASSWORD_REQUEST_SUCCESS:
            return { ...state, setPassword: { ...action.setPassword }, loading: false, error: false };

        case actionTypes.USER_LOGIN_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.USER_LOGIN_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.USER_LOGIN_REQUEST_SUCCESS:
            return { ...state, userLogin: { ...action.userLogin }, loading: false, error: false };

        case actionTypes.GET_TOKEN_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_TOKEN_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_TOKEN_REQUEST_SUCCESS:
            return { ...state, getToken: { ...action.getToken }, loading: false, error: false };

        case actionTypes.USER_ONBOARDING_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.USER_ONBOARDING_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.USER_ONBOARDING_REQUEST_SUCCESS:
            return { ...state, userOnboarding: { ...action.userOnboarding }, loading: false, error: false };

        case actionTypes.NATIONALITIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.NATIONALITIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.NATIONALITIES_LIST_REQUEST_SUCCESS:
            return { ...state, nationalitiesList: { ...action.nationalitiesList }, loading: false, error: false };

        case actionTypes.OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.OTHER_JURISDICTION_COUNTRIES_LIST_REQUEST_SUCCESS:
            return { ...state, otherJurisdictionCountriesList: { ...action.otherJurisdictionCountriesList }, loading: false, error: false };

        default: return state;

    }
}
export default reducer;