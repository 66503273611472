import React from 'react';
import '../../styles/components/floating-btn.scss';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';

export default function FloatingButton({ onClick }) {
  return (
    <div className="w-full bg-white rounded-t-2xl fixed bottom-0 md:hidden block floating-btn-container">
      <div className="px-4 pt-8 pb-6">
        <PrimaryButton
          caption="Table of contents"
          bgColor="bg-white"
          color="text-coral-500"
          className="w-full"
          onClick={onClick}
          id="mobile-toc"
        />
      </div>
    </div>
  );
}
FloatingButton.propTypes = {
  onClick: PropTypes.func
};
