import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
import { ModalContent } from './Modal';
import ZoomArrow from '../../images/customImages/zoom-arrow.png';
import { screenWidth } from '../../helper/helpers';

const ImageSlice = ({ post, slice }) => {
  const [imageModal, setImageModal] = useState({ open: false, id: '', showArrow: false });
  const [showArrow, setShowArrow] = useState(false);

  const showModal = (imageId) => {
    setImageModal({ ...imageModal, id: imageId, open: true });
    setShowArrow(false);
  };
  const mobileScreenWidth = 767;
  return (
    <>
      <div
        className={`my-8 magnify-image relative ${
          post?.is_report_article_blog && 'padding-for-report-article mx-auto'
        }`}
        onClick={() => screenWidth > mobileScreenWidth && showModal(slice?.id)}
        onMouseEnter={() => screenWidth > mobileScreenWidth && setShowArrow(true)}
        onMouseLeave={() => setShowArrow(false)}
      >
        {showArrow && (
          <img
            src={ZoomArrow}
            alt="magnify the image icon"
            width="32"
            height="32"
            className="zoom-arrow-container absolute"
          />
        )}
        <GatsbyImageWrapper
          image={{
            url: slice?.primary?.blog_image?.url,
            width: slice?.primary?.blog_image?.dimensions?.width,
            height: slice?.primary?.blog_image?.dimensions?.height
          }}
          className="w-full rounded-lg"
          alt={slice?.primary?.blog_image?.alt}
          loading="lazy"
        />
      </div>
      {imageModal?.open && imageModal.id === slice?.id && (
        <ModalContent onClose={() => setImageModal(false)}>
          <img
            src={slice?.primary?.blog_image?.url}
            alt={slice?.primary?.blog_image?.alt}
            width="1302"
            height="737"
            loading="lazy"
            className="modal-slice-image"
          />
        </ModalContent>
      )}
    </>
  );
};

ImageSlice.propTypes = {
  post: PropTypes.object,
  slice: PropTypes.object
};
ImageSlice.defaultProps = {
  post: {}
};

export default ImageSlice;
