import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

function Header({ title, publishedData, timeToRead, headerImage }) {
  return (
    <section>
      <h1 className="text-bold md:text-[48px] text-[32px] leading-[100%] tracking-[-1.9px] md:tracking-[-2.8px] mt-6 md:w-11/12">
        {title}
      </h1>
      <div className="flex gap-3 text-lg mt-6">
        <div className="text-bold">{publishedData}</div>
        <div>{timeToRead}</div>
      </div>
      <GatsbyImageWrapper
        className="my-12 rounded-lg"
        loading="eager"
        objectFit="contain"
        image={{ url: headerImage?.url, width: 1152, height: 481 }}
        alt={headerImage?.alt}
      />
    </section>
  );
}

Header.propTypes = {
  title: PropTypes?.string,
  publishedData: PropTypes?.string,
  timeToRead: PropTypes?.string,
  headerImage: PropTypes?.object
};

export default Header;
