import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import PrimaryButton from '../buttons/PrimaryButton';
import XSText from '../typography/XSText';
import MDSubtitleText from '../typography/MDSubtitleText';
import PropTypes from 'prop-types';
import { MatchingInvoiceApiErrorStatus } from '../../../helper/helpers';
const styles = (theme) => ({
  root: {
    margin: 0,
    paddingHorizontal: '32px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    paddingHorizontal: '32px',
    minWidth: '300px',
    maxWidth: '400px'
  }
}))(MuiDialogContent);

function InvoiceApiErrorModal({ getApiError }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (MatchingInvoiceApiErrorStatus(getApiError)) {
      setOpen(getApiError);
    }
  }, [getApiError]);

  return (
    <Dialog onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={() => handleClose()}></DialogTitle>
      <DialogContent>
        <MDSubtitleText
          className="md:whitespace-pre-line my-3"
          fontWeight="text-bold"
          title={getApiError?.data?.error}
        />
        <XSText classNamemb="mb-4" className="my-3" title={getApiError?.data?.message} />
        <XSText classNamemb="mb-4" className="my-3" title={getApiError?.data?.statusCode} />

        {!getApiError?.data && !getApiError?.data?.message && (
          <XSText
            className="my-6"
            title="An unexpected error occurred for this application, please contact Statrys support team."
          />
        )}
        <PrimaryButton
          className="capitalize my-4 onboarding-button w-full"
          fontSize="text-base"
          caption="Close"
          onClick={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    getApiError: state?.newAccountSetup?.error
  };
};

export default connect(mapStateToProps, null)(InvoiceApiErrorModal);

InvoiceApiErrorModal.propTypes = {
  getApiError: PropTypes.bool
};
