import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/layout';
import PropTypes from 'prop-types';
import BaseText from '../components/ui/typography/BaseText';
import H1HeaderText from '../components/ui/typography/H1HeaderText';
import './post.scss';
import { RichText } from 'prismic-reactjs';
import SEO from '../components/seo';
import FaqDropDown from '../components/ui/dropdowns/FaqDropDown';
import { MUI_COLORS, RICH_TEXT_TYPE, SLICE_TYPE, STATUS } from '../constants/enum';
import MDSubtitleText from '../components/ui/typography/MDSubtitleText';
import { Link, navigate } from 'gatsby';
import IconLinkedIn from '../images/icons/computer-logo-linkedin.svg';
import IconEmailAction from '../images/icons/email-action-at.svg';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import addCircle from '../images/icons/addCircle.svg';
import SubtractCircle from '../images/icons/subtractCircle.svg';
import FaqSupport from '../components/faq/FaqSupport';
import FaqSurvey from '../components/faq/FaqSurvey';
import Bullet from '../components/common/Bullet';
import XSText from '../components/ui/typography/XSText';
import { replaceSpaceAndSpecialCharacter, replaceWatchWithEmbed } from '../helper/helpers';
import '../styles/pages/faq-article.scss';
import SearchInput from '../components/ui/form/SearchInput';
import FaqArticleDropDown from '../components/ui/dropdowns/FaqArticleDropDown';

const navigateToSearchResults = (searchTerm) => {
  navigate('/faq-search', { state: { searchTerm } });
};
function FaqsArticleTemplate({ data }) {
  const tableRef = useRef([]);
  const [headings, setHeadings] = useState([]);
  const [handleDropDown, setHandleDropDown] = useState(false);
  if (!data || !data.prismicFaqArticles) {
    return null;
  }
  function addAttributesToBlogImages() {
    const blockImgClass = document.querySelectorAll('.block-img');
    for (let i = 0; i < blockImgClass.length; i++) {
      const blockImg = blockImgClass[i].querySelectorAll('img');
      if (!blockImg?.[0].alt) {
        blockImg?.[0].setAttribute('alt', data?.prismicFaqArticles?.data?.title.text);
      }
      blockImg?.[0].setAttribute('loading', 'lazy');
    }
  }
  useEffect(() => {
    var parentElement = document.querySelectorAll('.blog-link');
    for (let index = 0; index < parentElement.length; index++) {
      const element = parentElement[index];
      const spanElement = element.querySelector('.nofollow');
      if (spanElement) {
        element.setAttribute('rel', 'nofollow');
      }
    }
    let matches = document.querySelectorAll('span.nofollow > a');
    for (let index = 0; index < matches.length; index++) {
      matches[index].setAttribute('rel', 'nofollow');
    }
    addAttributesToBlogImages();
  }, []);

  const getNestedHeadings = (headingElements) => {
    const nestedHeadings = [];
    headingElements?.forEach((heading) => {
      const { id, innerText: title } = heading;
      if (heading.nodeName === 'H2') {
        nestedHeadings.push({ id, title, items: [], level: Number(heading.nodeName?.charAt(1)) });
      } else if (heading.nodeName === 'H3' && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          parentId: nestedHeadings[nestedHeadings.length - 1].id,
          id,
          title,
          level: Number(heading.nodeName?.charAt(1))
        });
      }
    });
    return nestedHeadings;
  };

  useEffect(() => {
    const divArticle = document.querySelector('.article-content');
    const allHTags = divArticle.querySelectorAll('h2, h3');
    if (allHTags) {
      for (let i = 0; i < allHTags.length; i++) {
        allHTags[i].setAttribute('id', replaceSpaceAndSpecialCharacter(allHTags[i]?.innerText));
      }
    }
    const newheadingElements = Array.from(divArticle.querySelectorAll('h2, h3'));
    const newNestedHeadings = getNestedHeadings(newheadingElements);
    setHeadings(newNestedHeadings);
  }, []);

  useEffect(() => {
    const tableTags = document.querySelectorAll('table');
    if (tableTags) {
      [...tableTags].forEach((table, index) => {
        tableRef.current[index] = table;
      });
    }
  }, []);

  useEffect(() => {
    const tocH3Heading = document.querySelectorAll('.toc-h3-heading');
    for (let i = 0; i < tocH3Heading?.length; i++) {
      const liOfToc = tocH3Heading[i].querySelectorAll('p');
      if (liOfToc[0].innerHTML === '') {
        liOfToc[0].classList.add('hidden');
      }
    }
  }, [headings]);

  function articles(label, sideIcon, backgroundColor) {
    const mainElement = document.getElementsByClassName('article-content')[0];
    const target = mainElement.innerHTML;
    const checkingNoFollow = document.getElementsByClassName('nofollow');
    if (!checkingNoFollow) {
      mainElement.innerHTML = target.replace(/(<span)/gim, '<div').replace(/<\/span>/gim, '</div>');
    }
    const containerClass = document.getElementsByClassName(label);
    for (let i = 0; i < containerClass.length; i++) {
      const icon = sideIcon && document.createElement('img');
      if (sideIcon) {
        icon.src = sideIcon;
        icon.className = 'richTextIcon';
        icon.style.alignSelf = 'flex-start';
        icon.style.width = '32px';
        icon.style.margin = '0px';
      }

      const anchorTag = containerClass[0].getElementsByTagName('a')[0];
      if (anchorTag) {
        anchorTag.className = 'text-bold';
        anchorTag.style.textDecoration = 'none';
      }
      const containerForTextAndLink = document.createElement('div');
      icon && containerForTextAndLink.appendChild(icon.cloneNode(true));
      containerForTextAndLink.appendChild(containerClass[i].cloneNode(true));
      containerClass[i].parentNode.replaceChild(containerForTextAndLink, containerClass[i]);
      containerClass[i].style.paddingLeft = sideIcon && '24px';
      containerClass[i].style.marginBottom = '0px';
      containerClass[i].style.color = '#464646';
      containerForTextAndLink.className = 'containerOfRichText';

      containerForTextAndLink.style.backgroundColor = backgroundColor;
      containerForTextAndLink.style.padding = backgroundColor === MUI_COLORS.PINK ? '24px 40px' : '24px';
      containerForTextAndLink.style.borderRadius = '8px';
      containerForTextAndLink.style.fontFamily =
        backgroundColor === MUI_COLORS.PINK ? 'HKGrotesk-bold' : 'HKGrotesk-Regular';
      containerForTextAndLink.style.fontSize = '18px';
      containerForTextAndLink.style.textAlign = backgroundColor === MUI_COLORS.PINK ? 'center' : 'left';
      containerForTextAndLink.style.lineHeight = '20px';
      containerForTextAndLink.style.display = 'flex';
      containerForTextAndLink.style.marginTop = '1rem';
    }
  }
  useEffect(() => {
    articles(RICH_TEXT_TYPE.PURPLE, null, MUI_COLORS.LIGHT_CREAM);
    articles(RICH_TEXT_TYPE.GREEN, null, MUI_COLORS.LIGHT_GREEN);
    articles(RICH_TEXT_TYPE.YELLOW, null, MUI_COLORS.LIGHT_YELLOW);
    articles(RICH_TEXT_TYPE.GRAY, null, MUI_COLORS.LIGHT_BLUE);
    articles(RICH_TEXT_TYPE.PINK, null, MUI_COLORS.PINK);
    scrollToSource();
  }, []);
  const post = data?.prismicFaqArticles?.data;

  let breadCrumbData = [
    {
      position: '1',
      name: 'faq',
      item: process.env.SITEURL + '/faq'
    },
    {
      position: '2',
      name: post?.linked_blog?.document?.data?.title?.text
        ? post?.linked_blog?.document?.data?.title?.text
        : post?.category?.document?.data?.section_title?.text,
      item:
        process.env.SITEURL +
        '/faq' +
        `/${post?.linked_blog?.document?.uid ? post?.linked_blog?.document?.uid : post?.category?.document?.uid}`
    }
  ];
  const articleSchemaData = {
    datePublished: post?.published_date,
    dateModified: data?.prismicFaqArticles?.last_publication_date,
    title: post?.title?.text,
    readingTime: post?.time_to_read?.text,
    author: [
      {
        '@type': 'Person',
        name: post?.author?.document?.data?.full_name?.text,
        url: `${process.env.SITEURL}/team/${post?.author?.document?.uid}`
      }
    ]
  };

  function scrollToSource() {
    const sourcesElement = document.getElementsByClassName('source');
    for (let i = 0; i < sourcesElement.length; i++) {
      const anchorTagForSources = document.createElement('a');
      anchorTagForSources.appendChild(sourcesElement[i].cloneNode(true));
      sourcesElement[i].parentNode.replaceChild(anchorTagForSources, sourcesElement[i]);
      sourcesElement[i].innerHTML = `[${i + 1}]`;
      anchorTagForSources.href = '#sources';
      anchorTagForSources.style.cursor = 'pointer';
    }
  }
  return (
    <Layout
      customNavigationBg={MUI_COLORS.EXTRA_LIGHT_GRAY}
      fullwidth={true}
      showCustomerReviewsFooter={false}
      bannerData={post?.link_banner?.document?.data}
    >
      <SEO
        description={post?.seo_description?.text}
        keywords={post?.seo_focus_keywords.text ? post?.seo_focus_keywords?.text.split(',') : []}
        title={post?.seo_title?.text}
        metaImage={post?.meta_image?.url}
        type="blog"
        faqData={post?.seo_faq}
        breadCrumbData={breadCrumbData}
        articleSchemaData={articleSchemaData}
      />
      <div className="pillar-page md:px-0 first-section">
        <div className="bg-customBg-mainBg py-12 ">
          <div className="flex items-center gap-3 max-w-6xl md:px-0 px-4 mx-auto">
            <div className="w-full">
              <div className="flex items-center gap-3">
                <Link to="/faq">
                  <div className={`text-2xl text-light letter-spacing-1`}>FAQs</div>
                </Link>
                <div className={`bg-coral-500 h-2 w-2 rounded-full`}></div>
                <Link to="/faq" className={`text-2xl text-bold letter-spacing-1 `}>
                  {post?.category?.document?.data?.faq_product_category?.document?.data?.category_title?.text}
                </Link>
              </div>
              <Link to={`/faq/${post?.category?.document?.uid}`}>
                <p className={`text-5xl text-bold letter-spacing-1 mt-4`}>
                  {post?.category?.document?.data?.section_title?.text}
                </p>
              </Link>
            </div>
            <div className="w-2/5 md:block hidden">
              <SearchInput onSearch={(value) => navigateToSearchResults(value)} isFaq />
            </div>
          </div>
        </div>
        <div>
          <div
            className={`
            justify-between items-start
             flex md:flex-row flex-col-reverse max-w-6xl md:px-0 px-4 faq-blog-container mx-auto gap gap-12`}
          >
            <div className="flex gap-3 flex-col">
              {data?.prismicFaqArticles?.data?.category?.document?.data?.body.map((faqData) => {
                return (
                  <FaqArticleDropDown
                    key={faqData?.uid}
                    categoryUid={faqData?.uid}
                    categoryTitle={faqData?.primary?.link_faqs_sub_category?.document?.data?.sub_category_title?.text}
                    faqsList={faqData?.items}
                    articleUid={data?.prismicFaqArticles?.uid}
                    articleCategory={post?.category?.document?.uid}
                  />
                );
              })}
            </div>
            <div className="w-full">
              <article className="article-content faq-article-content blog" style={{ marginTop: '0px' }}>
                <H1HeaderText title={post?.title?.text} className="mb-4" fontSize="text-4xl" />
                <div className="article-content-parent-container">
                  {post?.content?.richText[0]?.text && (
                    <RichText
                      render={post?.content?.richText}
                      elements={{
                        hyperlink: function a({ children, data }) {
                          return (
                            <a className="blog-link" target={data?.target} href={data?.url}>
                              {children}
                            </a>
                          );
                        }
                      }}
                    />
                  )}
                  {post.body.map((slice, index) => {
                    if (slice?.slice_type === SLICE_TYPE.RICH_TEXT) {
                      return (
                        <div className="mb-8 break-words" key={index}>
                          <div dangerouslySetInnerHTML={{ __html: slice?.primary?.page_content?.html }} />
                        </div>
                      );
                    }

                    if (slice?.slice_type === SLICE_TYPE.IMAGE) {
                      return (
                        <div className="my-8" key={index}>
                          <img
                            src={slice?.primary?.blog_image?.url}
                            className="w-full rounded-lg"
                            alt={slice?.primary?.blog_image?.alt}
                            loading="lazy"
                          />
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.BLOG_CTA) {
                      const stringWithN = slice?.primary?.description1.text.split('/n');
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundColor: slice?.primary?.background_color
                              ? slice?.primary?.background_color
                              : MUI_COLORS.PINK
                          }}
                          className="my-8 rounded-lg"
                        >
                          <div
                            className={`flex md:flex-row flex-col md:${
                              slice?.primary?.cta_image?.url ? 'pl-8' : 'px-8'
                            } ${slice?.primary?.cta_image?.url ? 'px-auto' : ''}  items-center justify-between`}
                          >
                            <div className={`w-10/12  ${!slice?.primary?.cta_image?.url ? 'mb-6' : 'mb-0'}`}>
                              <BaseText
                                title={slice?.primary?.title1?.text}
                                fontWeight="text-bold"
                                className="blog-cta-container mt-6"
                                style={{
                                  color: slice?.primary?.text_color ? slice?.primary?.text_color : MUI_COLORS.CHARCOAL,
                                  fontSize: slice?.primary?.title_font_size ? slice?.primary?.title_font_size : '32px'
                                }}
                              />
                              <div className="mb-4">
                                {stringWithN?.map((description, index) => {
                                  return (
                                    <BaseText
                                      key={index}
                                      title={description.replace(/\n/g, '')}
                                      className="whitespace-pre-line blog-cta-description"
                                      style={{
                                        color: slice?.primary?.text_color
                                          ? slice?.primary?.text_color
                                          : MUI_COLORS.CHARCOAL
                                      }}
                                    />
                                  );
                                })}
                              </div>

                              <PrimaryButton
                                urlLink={slice?.primary?.button_url?.url}
                                caption={slice?.primary?.cta_button?.text}
                                className={`${slice?.primary?.cta_image?.url ? 'block' : 'hidden'} mb-6 `}
                                type="medium-button"
                                target="_blank"
                                isBorderRequired={true}
                                style={{
                                  backgroundColor: slice?.primary?.button_color
                                    ? slice?.primary?.button_color
                                    : MUI_COLORS.CORAL,
                                  color: slice?.primary?.button_text_color
                                    ? slice?.primary?.button_text_color
                                    : MUI_COLORS.WHITE
                                }}
                              />
                            </div>

                            {slice?.primary?.cta_image?.url && (
                              <img
                                src={slice?.primary?.cta_image?.url}
                                alt={slice?.primary?.cta_image?.alt}
                                className="blog-cta-image self-end"
                                loading="lazy"
                                width="340"
                                height="340"
                              />
                            )}

                            {!slice?.primary?.cta_image?.url && (
                              <PrimaryButton
                                urlLink={slice?.primary?.button_url?.url}
                                caption={slice?.primary?.cta_button?.text}
                                className="md:w-28 w-full md:mb-0 mb-4 "
                                type="medium-button"
                                target="_blank"
                                isBorderRequired={true}
                                style={{
                                  backgroundColor: slice?.primary?.button_color
                                    ? slice?.primary?.button_color
                                    : MUI_COLORS.CORAL,
                                  color: slice?.primary?.button_text_color
                                    ? slice?.primary?.button_text_color
                                    : MUI_COLORS.WHITE
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.TABLE) {
                      return (
                        <div className="my-8" key={index}>
                          <div
                            id={slice?.primary?.is_table_full_width && 'blog-table-full-width'}
                            className={`${' blog-table '}${
                              post?.show_competitors_vs_header && 'competitor-blog-table'
                            } `}
                            dangerouslySetInnerHTML={{ __html: slice?.primary?.table_data?.text }}
                          />
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.EDITORS_NOTE) {
                      return (
                        <div className="my-8 bg-blue-200 p-6 rounded-lg" key={index}>
                          <MDSubtitleText
                            fontWeight="text-bold"
                            title={
                              slice?.primary?.editors_note_title?.text
                                ? slice?.primary?.editors_note_title?.text
                                : 'Editor’s Note'
                            }
                          />
                          <div
                            className="editors-note-description"
                            dangerouslySetInnerHTML={{ __html: slice?.primary?.editors_note_description?.html }}
                          />
                          {slice?.primary?.editors_note_author?.document?.uid && (
                            <div className="flex mt-12">
                              <Link
                                to={`/team/${slice?.primary?.editors_note_author?.document?.uid}`}
                                className="w-16 mr-4"
                              >
                                <img
                                  src={slice?.primary?.editors_note_author?.document?.data?.avatar?.url}
                                  className="w-16 h-16 rounded-full"
                                  alt="author img"
                                  loading="lazy"
                                />
                              </Link>
                              <div className="editors-author-detail">
                                <Link to={`/team/${slice?.primary?.editors_note_author?.document?.uid}`}>
                                  <div className="text-lg text-gray-500 hover:no-underline text-bold leading-5">
                                    {slice?.primary?.editors_note_author?.document?.data?.full_name?.text}
                                  </div>
                                  <div className="text-lg text-gray-500 hover:no-underline leading-5 my-1">{`${slice?.primary?.editors_note_author?.document?.data?.role?.text}`}</div>
                                </Link>
                                <div className="flex gap-2">
                                  <a href={slice?.primary?.editors_note_author?.document?.data?.email?.url}>
                                    <img src={IconEmailAction} alt="IconEmailAction" loading="lazy" />
                                  </a>
                                  <a
                                    href={slice?.primary?.editors_note_author?.document?.data?.linkedin?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img src={IconLinkedIn} alt="IconLinkedIn" loading="lazy" />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.PODCAST) {
                      return <div id={slice?.primary?.podcast_id?.text} key={index} className="my-4" />;
                    }
                    if (slice?.slice_type === 'button') {
                      return (
                        <div className="text-center my-8" key={index}>
                          <button
                            type="button"
                            className="bg-coral-500 text-white text-base p-4 rounded-md font-bold text-center slice-button"
                          >
                            <a
                              href={slice?.primary?.button_link?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-white"
                            >
                              {slice?.primary?.button_title?.text}
                            </a>
                          </button>
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.YOUTUBE_VIDEO) {
                      return (
                        <div className="my-8 flex justify-center youtube-slice-container" key={index}>
                          <iframe
                            loading="lazy"
                            src={replaceWatchWithEmbed(slice.primary.youtube_link.url)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.KEY_TAKEAWAYS) {
                      return (
                        <div className="bg-white rounded-lg mb-8" key={index}>
                          <div className="py-8 px-8">
                            <BaseText
                              title={slice?.primary?.key_takaways_title?.text}
                              fontWeight="text-bold"
                              textColor="text-gray-500"
                            />
                            {slice?.items?.map((takeAwayPoints, index) => {
                              return (
                                <div key={index} className="flex flex-row gap-2">
                                  <Bullet />
                                  <XSText
                                    title={takeAwayPoints?.takeaways_points?.text}
                                    className="takeaways-points"
                                    textColor="text-gray-150"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    }
                    if (slice?.slice_type === SLICE_TYPE.DOUBLE_COLUMN) {
                      return (
                        <div
                          className="flex md:flex-row flex-col md:gap-8 gap-4 mt-8 double-column-container"
                          key={index}
                        >
                          <div
                            className="w-full"
                            dangerouslySetInnerHTML={{ __html: slice?.primary?.first_column?.html }}
                          />
                          <div
                            className="w-full"
                            dangerouslySetInnerHTML={{ __html: slice?.primary?.second_column?.html }}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>

                <div className={`w-full mb-12`}>
                  {post?.seo_faq[0]?.question?.text ? (
                    <div>
                      <h2>FAQs</h2>
                      {post?.seo_faq.map((data, index) => {
                        return (
                          <div key={index}>
                            <FaqDropDown blog={true} open={index === 0 ? true : false} data={data} />
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                {post?.sources[0]?.sources_list?.text && (
                  <div id="sources" className="sources">
                    <h2>Sources</h2>
                    <div
                      className={`
                      ${
                        handleDropDown
                          ? 'p-6 transition-all duration-500'
                          : 'px-6 pt-6 pb-6 transition-all duration-500'
                      } rounded-lg bg-white mb-4
                    `}
                    >
                      <div
                        className="flex justify-between cursor-pointer"
                        onClick={() => setHandleDropDown(!handleDropDown)}
                      >
                        <ul className="md:w-11/12 w-10/12 source-ul">
                          {post.sources.map((list, index, row) => {
                            if (handleDropDown ? row.length : index < 3) {
                              return (
                                <li key={index} className="flex gap-1 items-start">
                                  <p>{`${index + 1}.`}</p>
                                  {list?.is_source_link ? (
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer nofollow"
                                      className="break-all"
                                      href={list.sources_list.text}
                                    >
                                      {list.sources_list.text}
                                    </a>
                                  ) : (
                                    <p>{list?.sources_list?.text}</p>
                                  )}
                                </li>
                              );
                            }
                          })}
                        </ul>
                        <div className="cursor-pointer">
                          <img
                            className="w-7 mt-4 mx-auto"
                            alt="arrow"
                            src={handleDropDown ? SubtractCircle : addCircle}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {post?.disclaimer_description?.html && (
                  <div className="disclaimer-container">
                    <div className="disclaimer px-6 pt-6 pb-2">
                      <p className="disclaimer-heading">Disclaimer</p>
                      <div dangerouslySetInnerHTML={{ __html: post?.disclaimer_description?.html }}></div>
                    </div>
                  </div>
                )}
              </article>
              {process.env.NODE_JS_MAINTENANCE_FLOW_ENABLED === STATUS?.FALSE && <FaqSurvey />}
            </div>
          </div>
        </div>
        <FaqSupport />
      </div>
    </Layout>
  );
}

FaqsArticleTemplate.propTypes = {
  data: PropTypes.any,
  date: PropTypes.any,
  children: PropTypes.any
};

export default FaqsArticleTemplate;
