import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import '../../styles/pages/blogTemplate/header.scss';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import IconLinkedIn from '../../images/icons/computer-logo-linkedin.svg';
import IconEmailAction from '../../images/icons/email-action-at.svg';
import { stringTruncate } from '../../helper/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
export default function BlogAuthorSection({ post }) {
  const [showAuthorDetails, setShowAuthorDetails] = useState(false);
  const [showReviewedByDetails, setShowReviewedByDetails] = useState(false);
  const [showFactCheckByDetails, setShowFactCheckByDetails] = useState(false);
  const authorImage = getImage(post?.author?.document?.data?.avatar?.gatsbyImageData);
  const reviewerImage = getImage(post?.reviewed_by?.document?.data?.avatar?.gatsbyImageData);
  const factCheckedByImage = getImage(post?.fact_checked_by?.document?.data?.avatar?.gatsbyImageData);

  return (
    <>
      <div
        onMouseEnter={() => setShowAuthorDetails(true)}
        onMouseLeave={() => setShowAuthorDetails(false)}
        className={`relative ${showAuthorDetails ? 'bg-white rounded-lg p-4' : 'px-4 pt-4'}`}
      >
        <Link
          className={`flex flex-col md:flex-row items-start md:items-center mb-4 gap-3 md:w-[384px]`}
          to={`/team/${post?.author?.document?.uid}`}
        >
          <GatsbyImage className="rounded-full w-16 h-16" image={authorImage} alt="author-image" loading="eager" />

          <div>
            <div className="text-xl text-bold leading-5">{post?.author?.document?.data?.full_name?.text}</div>
            <div className="text-xl leading-5 my-1 ">{`${post?.author?.document?.data?.role?.text}`}</div>
          </div>
        </Link>
        <div className={`${showAuthorDetails ? 'block' : 'hidden'}`}>
          <XXSText title={stringTruncate(post?.author?.document?.data?.bio?.text, 250)} />
          <div className="flex items-center justify-between">
            <Link to={`/team/${post?.author?.document?.uid}`} className="mt-2">
              <BaseText textColor="text-coral-500" fontWeight="text-bold underline" title="Read full Bio" />
            </Link>
            <div className="flex">
              <a href={post?.author?.document?.data?.email?.url} className="hover:bg-coral-100 rounded-lg p-2">
                <img src={IconEmailAction} loading="lazy" className="h-6 w-6" alt="emil icon" />
              </a>
              <a
                href={post?.author?.document?.data?.linkedin?.url}
                className="hover:bg-coral-100 rounded-lg p-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconLinkedIn} loading="lazy" className="h-6 w-6" alt="linkedin icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {post?.reviewed_by?.document?.data?.full_name?.text && (
        <div
          onMouseEnter={() => setShowReviewedByDetails(true)}
          onMouseLeave={() => setShowReviewedByDetails(false)}
          className={`relative mt-6 ${showReviewedByDetails ? 'bg-white rounded-lg p-4' : 'px-4 pt-4'}`}
        >
          <div className={`flex flex-col md:flex-row gap-1 ${showReviewedByDetails && 'mb-4'}`}>
            <div className="text-xl leading-5">Reviewed by</div>
            {!showReviewedByDetails && (
              <div className="flex items-center">
                <div className="text-xl text-bold leading-5">{post?.reviewed_by?.document?.data?.full_name?.text}</div>
              </div>
            )}
          </div>
          {showReviewedByDetails && (
            <Link
              className={`flex flex-col md:flex-row items-start md:items-center mb-4 gap-3 ${
                showReviewedByDetails ? 'block' : 'hidden'
              }`}
              to={`/team/${post?.reviewed_by?.document?.uid}`}
            >
              <GatsbyImage
                className="rounded-full w-16 h-16"
                image={reviewerImage}
                alt={post?.reviewed_by?.document?.data?.avatar?.alt}
                width="64"
                height="64"
              />

              <div>
                <div className="text-xl text-bold leading-5">{post?.reviewed_by?.document?.data?.full_name?.text}</div>
                <div className="text-xl leading-5 my-1 ">{`${post?.reviewed_by?.document?.data?.role?.text}`}</div>
              </div>
            </Link>
          )}

          <div className={`${showAuthorDetails ? 'block' : 'hidden'}`}>
            <XXSText title={stringTruncate(post?.reviewed_by?.document?.data?.bio?.text, 250)} />
            <div className="flex flex-col md:flex-row items-center justify-between">
              <Link to={`/team/${post?.reviewed_by?.document?.uid}`} className="mt-2">
                <BaseText textColor="text-coral-500" fontWeight="text-bold underline" title="Read full Bio" />
              </Link>
              <div className="flex">
                <a href={post?.reviewed_by?.document?.data?.email.url} className="hover:bg-coral-100 rounded-lg p-2">
                  <img src={IconEmailAction} loading="lazy" className="h-6 w-6" alt="emil icon" />
                </a>
                <a
                  href={post?.reviewed_by?.document?.data?.linkedin.url}
                  className="hover:bg-coral-100 rounded-lg p-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconLinkedIn} loading="lazy" className="h-6 w-6" alt="linkedin icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      {post?.fact_checked_by?.document?.data?.full_name?.text && (
        <div
          onMouseEnter={() => setShowFactCheckByDetails(true)}
          onMouseLeave={() => setShowFactCheckByDetails(false)}
          className={`relative mt-3 ${showFactCheckByDetails ? 'bg-white rounded-lg p-4' : 'px-4 pt-4'}`}
        >
          <div className="flex flex-col md:flex-row gap-1 mb-4">
            <div className="text-xl leading-5">Fact checked by</div>
            {!showFactCheckByDetails && (
              <div className="flex items-center">
                <div className="text-xl text-bold leading-5">
                  {post?.fact_checked_by?.document?.data?.full_name?.text}
                </div>
              </div>
            )}
          </div>
          {showFactCheckByDetails && (
            <Link
              className={`flex flex-col md:flex-row items-start md:items-center mb-4 gap-3 ${
                showFactCheckByDetails ? 'block' : 'hidden'
              }`}
              to={`/team/${post?.fact_checked_by?.document?.uid}`}
            >
              <GatsbyImage
                className="rounded-full w-16 h-16"
                image={factCheckedByImage}
                alt={post?.fact_checked_by?.document?.data?.avatar?.alt}
                width="64"
                height="64"
              />

              <div>
                <div className="text-xl text-bold leading-5">
                  {post?.fact_checked_by?.document?.data?.full_name?.text}
                </div>
                <div className="text-xl leading-5 my-1 ">{`${post?.fact_checked_by?.document?.data?.role?.text}`}</div>
              </div>
            </Link>
          )}

          <div className={`${showFactCheckByDetails ? 'block' : 'hidden'}`}>
            <XXSText title={stringTruncate(post?.fact_checked_by?.document?.data?.bio?.text, 250)} />
            <div className="flex items-center justify-between">
              <Link to={`/team/${post?.fact_checked_by?.document?.uid}`} className="mt-2">
                <BaseText textColor="text-coral-500" fontWeight="text-bold underline" title="Read full Bio" />
              </Link>
              <div className="flex">
                <a
                  href={post?.fact_checked_by?.document?.data?.email.url}
                  className="hover:bg-coral-100 rounded-lg p-2"
                >
                  <img src={IconEmailAction} loading="lazy" className="h-6 w-6" alt="emil icon" />
                </a>
                <a
                  href={post?.fact_checked_by?.document?.data?.linkedin.url}
                  className="hover:bg-coral-100 rounded-lg p-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconLinkedIn} loading="lazy" className="h-6 w-6" alt="linkedin icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

BlogAuthorSection.propTypes = {
  post: PropTypes.object,
  allPostsList: PropTypes.array,
  blogUid: PropTypes.string,
  colors: PropTypes.object,
  publishDate: PropTypes.string,
  type: PropTypes.string
};
