import React from 'react';
import XLSubtitleText from '../ui/typography/XLSubtitleText';
import H3HeaderText from '../ui/typography/H3HeaderText';
import BaseText from '../ui/typography/BaseText';
import { FormControl } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import AlertTriangle from '../../images/icons/alert-triangle.svg';
import TextInput from '../ui/inputs/TextInput';
import { handleEnter, sendHubspotRequestResources } from '../../helper/helpers';
import { REGEX, RESOURCE_TYPE } from '../../constants/enum';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { Link } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function header({ resourcesData }) {
  const { register, handleSubmit, errors } = useForm();
  const recaptchaRef = React.useRef();
  const emailRegex = REGEX.EMAIL_REGEX;
  function downloadURI() {
    var link = document.createElement('a');
    link.setAttribute('download', resourcesData?.title?.text);
    link.href = resourcesData?.resource_url?.url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const onSubmit = async (data) => {
    await recaptchaRef.current.executeAsync();
    var payload = {
      fields: [
        {
          name: 'firstname',
          value: data?.name
        },
        {
          name: 'lastname',
          value: data?.lastName
        },
        {
          name: 'email',
          value: data?.email
        },
        {
          name: 'website_activity',
          value: resourcesData?.website_activity
        },
        {
          name: 'resource_title',
          value: resourcesData?.title?.text
        }
      ],
      context: {
        pageUri: `https://statrys.com/resources/${resourcesData?.website_activity.toLowerCase()}`,
        pageName: resourcesData?.website_activity
      }
    };
    var final_data = JSON.stringify(payload);
    if (typeof window !== `undefined`) {
      await window?.heap?.identify(data?.email);
      await window?.heap?.addUserProperties({
        firstName: data?.name,
        lastName: data?.lastName,
        email: data?.email,
        websiteActivity: resourcesData?.website_activity,
        resourcesData: resourcesData?.title?.text
      });
    }
    await sendHubspotRequestResources(final_data, downloadURI);
  };
  const getResourceCategory = () => {
    if (resourcesData?.website_activity === RESOURCE_TYPE.WHITEPAPER) {
      return 'White Papers & E-Books';
    }
    if (resourcesData?.website_activity === RESOURCE_TYPE.NEWSLETTER) {
      return 'Newsletters';
    }
  };
  const getResourceTitle = () => {
    if (resourcesData?.website_activity === RESOURCE_TYPE.WHITEPAPER) {
      return 'White Paper';
    }
    if (resourcesData?.website_activity === RESOURCE_TYPE.NEWSLETTER) {
      return 'Newsletter';
    }
  };
  return (
    <div className="whitepaper-container secondary-bg">
      <div className="max-w-6xl mx-auto px-4 md:px-0">
        <div className="hidden md:flex whitepaper-navigation items-center gap-3">
          <div className={`text-xl text-light letter-spacing-1`}>Resources</div>
          <div className={`bg-coral-500 h-2 w-2 rounded-full `}></div>
          <Link
            to={
              resourcesData?.website_activity === RESOURCE_TYPE.WHITEPAPER
                ? '/resources/whitepapers'
                : '/resources/newsletters'
            }
          >
            <div className={`text-xl letter-spacing-1`}>{getResourceCategory()}</div>
          </Link>
          <div className={`bg-coral-500 h-2 w-2 rounded-full `}></div>
          <div className={`text-xl text-bold letter-spacing-1`}>{resourcesData?.title?.text}</div>
        </div>
        <div className="flex flex-col lg:flex-row gap-12">
          <div className="md:w-11/12 whitepaper-left-section">
            <H3HeaderText title={getResourceTitle()} fontWeight="text-regular" />
            <div className="flex items-center gap-4 mt-4 mb-8">
              <XLSubtitleText title={resourcesData?.title?.text} />
            </div>
            <BaseText title={resourcesData?.long_description?.text} />
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)} className="resources-form">
              <FormControl className="w-full">
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.CAPTCHA_SITE_KEY} />
                <div className="flex flex-col md:flex-row gap-4 mt-10">
                  <TextInput
                    onKeyDown={handleEnter}
                    name="name"
                    label="First Name"
                    className="w-full md:w-56"
                    inputRef={register({
                      required: true
                    })}
                    error={errors.name ? true : false}
                    helperText={
                      errors.name ? (
                        <div className="flex gap-2 items-center">
                          <img src={AlertTriangle} alt="alert triangle" />
                          Please fill in a valid First Name
                        </div>
                      ) : null
                    }
                  />
                  <TextInput
                    onKeyDown={handleEnter}
                    name="lastName"
                    label="Last Name"
                    className="w-full md:w-56"
                    inputRef={register({
                      required: true
                    })}
                    error={errors.lastName ? true : false}
                    helperText={
                      errors.lastName ? (
                        <div className="flex gap-2 items-center">
                          <img src={AlertTriangle} alt="alert triangle" />
                          Please fill in a valid Last Name
                        </div>
                      ) : null
                    }
                  />
                </div>
                <TextInput
                  onKeyDown={handleEnter}
                  margin="mt-4"
                  name="email"
                  label="Email Address"
                  variant="filled"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: 'Please fill in a valid Email Address'
                    }
                  })}
                  error={errors.email ? true : false}
                  helperText={
                    errors.email ? (
                      <div className="flex gap-2 items-center">
                        <img src={AlertTriangle} alt="alert triangle" />
                        Please fill in a valid Email Address
                      </div>
                    ) : null
                  }
                />
                <PrimaryButton
                  className="capitalize w-full mt-8"
                  fontSize="text-base"
                  caption={resourcesData?.button_caption?.text}
                  onClick={handleSubmit(onSubmit)}
                />
              </FormControl>
            </form>
          </div>
          <div className="w-full resource-right-section">
            <GatsbyImageWrapper
              image={{ url: resourcesData?.main_image?.url, width: 559, height: 709 }}
              className="w-full -mb-12 whitepaper-main-img"
              alt={resourcesData?.main_image?.alt}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
