import React from 'react';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import DownArrow from '../../images/icons/arrow-select-input.svg';
import CloseSvg from '../../images/icons/close.svg';
import Line from '../common/Line';
export default function MobileToc({ tocs, onClick }) {
  return (
    <>
      <div className="mx-6">
        <div className="flex flex-row justify-between">
          <BaseText title="In this article" fontWeight="text-bold" />
          <img src={CloseSvg} width="15" height="15" alt="close" onClick={onClick} />
        </div>
        <Line className="my-4" />
      </div>
      <ul className="list-none pl-0 toc-ul mx-6 pb-8">
        {tocs?.map((toc) => {
          return (
            <li key={toc?.id} className={`md:font-light  text-coral-500 font-semibold toc-list`}>
              <div className="flex flex-row justify-between" onClick={onClick}>
                <BaseText
                  id="mobile-toc-header"
                  fontWeight={'text-normal'}
                  title={
                    <a
                      href={`#${toc?.id}`}
                      dangerouslySetInnerHTML={{ __html: toc?.title }}
                      className={`text-gray-150 hover:underline`}
                    ></a>
                  }
                />
                {toc?.items?.length > 0 && (
                  <img
                    src={DownArrow}
                    className="toc-heading-arrow transition-all delay-75 ease-in-out"
                    alt="drop down arrow"
                  />
                )}
              </div>

              <BaseText
                className="toc-h3-heading pl-2"
                title={
                  toc?.items?.length > 0 && (
                    <ul className="toc-h3-ul">
                      {toc?.items?.map((child) => {
                        return (
                          <li key={child.id} onClick={onClick}>
                            <a href={`#${child?.id}`} className="hover:underline font-normal text-base">
                              {child?.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )
                }
              />
            </li>
          );
        })}
      </ul>
    </>
  );
}

MobileToc.propTypes = {
  tocs: PropTypes.any,
  onClick: PropTypes.func
};
