import React, { useState } from 'react';
import { stringTruncate, guidesCustomUrl } from '../../helper/helpers';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function ArticleBottomRightSide({ blog }) {
  const [handleLink, setHandleLink] = useState(false);
  return (
    <div>
      <Link
        onMouseEnter={() => setHandleLink(true)}
        onMouseLeave={() => setHandleLink(false)}
        className="hover:no-underline"
        to={guidesCustomUrl(blog?.linked_article?.document)}
      >
        <div className="flex justify-between">
          <div>
            <XXSText
              className="mb-2"
              textColor="text-coral-500"
              fontWeight="text-bold"
              title={blog?.linked_article?.document?.data?.category?.document?.data?.title?.text}
            />

            <BaseText
              title={stringTruncate(blog?.linked_article?.document?.data?.title?.text, 60)}
              fontSize="text-xl"
              fontWeight="text-normal"
              className={`${handleLink && 'underline'} blog-bottom-right-title-container`}
              textColor={`${handleLink ? 'text-coral-500' : 'text-charcoal-dark'}`}
              lineHeight="leading-6"
              letterSpacing="new-blog-card-letter-spacing"
            />
          </div>

          <div className="cursor-pointer">
            <img
              className={`rounded-lg blog-bottom-right-card-img`}
              src={
                blog?.linked_article?.document?.data?.header_image?.url
                  ? blog?.linked_article?.document?.data?.header_image?.url
                  : blog?.linked_article?.document?.data?.silos_category?.document?.data?.section_and_article_image?.url
              }
              alt={
                blog?.linked_article?.document?.data?.header_image?.alt
                  ? blog?.linked_article?.document?.data?.header_image?.alt
                  : 'card image'
              }
              loading="lazy"
              width="84"
              height="84"
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

ArticleBottomRightSide.propTypes = {
  blog: PropTypes.object
};

export default ArticleBottomRightSide;
