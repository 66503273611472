import React, { useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import PropTypes from 'prop-types';
export default function Disclaimer({ disclaimerDescription, disclaimerMoreInfoContent, disclaimerMoreInfoLabel }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="disclaimer-container">
        <div className="disclaimer px-6 pt-6 pb-2">
          <div className="flex justify-between">
            <p className="disclaimer-heading">Disclaimer</p>
            <div onClick={() => setOpenModal(true)}>
              {disclaimerMoreInfoLabel && (
                <div className="text-coral-500 text-bold cursor-pointer">{disclaimerMoreInfoLabel}</div>
              )}
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: disclaimerDescription }}></div>
        </div>
      </div>
      {disclaimerMoreInfoLabel && (
        <DynamicModal
          title={disclaimerMoreInfoLabel}
          openDynamicModal={openModal}
          closeDynamicModal={() => setOpenModal(false)}
          minWidth="576px"
        >
          <div dangerouslySetInnerHTML={{ __html: disclaimerMoreInfoContent }}></div>
        </DynamicModal>
      )}
    </>
  );
}
Disclaimer.propTypes = {
  disclaimerDescription: PropTypes.any,
  disclaimerMoreInfoLabel: PropTypes.string,
  disclaimerMoreInfoContent: PropTypes.any
};
