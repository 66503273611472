import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
const IconSendEmail = ({ stroke }) => {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16_2896)">
        <path
          d="M2.20142 4.99072C1.99325 4.61824 1.90966 4.18894 1.96288 3.76557C2.0161 3.3422 2.20334 2.94694 2.49722 2.63757C2.7911 2.3282 3.17622 2.12093 3.5963 2.04605C4.01639 1.97117 4.44941 2.03261 4.83208 2.22139L30.1841 15.1001C30.3486 15.1837 30.4867 15.3113 30.5832 15.4686C30.6797 15.6259 30.7307 15.8068 30.7307 15.9914C30.7307 16.1759 30.6797 16.3569 30.5832 16.5142C30.4867 16.6715 30.3486 16.7991 30.1841 16.8827L4.83208 29.7787C4.44941 29.9675 4.01639 30.0289 3.5963 29.9541C3.17622 29.8792 2.7911 29.6719 2.49722 29.3625C2.20334 29.0532 2.0161 28.6579 1.96288 28.2345C1.90966 27.8112 1.99325 27.3819 2.20142 27.0094L8.94542 15.9907L2.20142 4.99072Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.7315 15.9907H8.93945"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_2896">
          <rect width="32" height="32" fill="white" transform="translate(0.333984)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconSendEmail;

IconSendEmail.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string
};

IconSendEmail.defaultProps = {
  stroke: MUI_COLORS.CORAL
};
