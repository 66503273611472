import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/report-slice.scss';
const ReportSlice = ({ reportData }) => {
  return (
    <div
      className={`w-full flex justify-center pt-12 pb-8 ${
        !reportData?.disable_full_screen_height && 'min-h-screen'
      } report-slice-container `}
      style={{
        background: reportData?.background_color
          ? reportData?.background_color
          : `url(${reportData?.background_image?.url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}
    >
      <div
        className={`flex md:flex-row flex-col gap gap-10 report-slice-columns ${
          reportData?.make_image_sticky && 'relative'
        }`}
      >
        <div
          className={`md:w-1/2 w-full flex flex-col ${
            reportData?.right_column_alignment || reportData?.make_image_sticky ? 'self-start' : 'self-center'
          } ${reportData?.make_image_sticky && 'md:sticky static top-24'}  rounded-lg`}
          style={{ color: reportData?.text_color && reportData?.text_color }}
          dangerouslySetInnerHTML={{ __html: reportData?.left_column?.html }}
        ></div>
        <div
          className={`md:w-1/2 w-full flex flex-col ${
            reportData?.right_column_alignment || reportData?.make_image_sticky ? 'self-start' : 'self-center'
          } ${reportData?.make_image_sticky && 'md:sticky static top-24'} rounded-lg`}
          style={{
            color: reportData?.text_color && reportData?.text_color,
            backgroundColor: reportData?.image_background_color && reportData?.image_background_color
          }}
          dangerouslySetInnerHTML={{ __html: reportData?.right_column?.html }}
        ></div>
      </div>
    </div>
  );
};
export default ReportSlice;
ReportSlice.propTypes = {
  reportData: PropTypes.object
};
