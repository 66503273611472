import React, { useState } from 'react';
import PropTypes from 'prop-types';
import addCircle from '../../../images/icons/addCircle.svg';
import SubtractCircle from '../../../images/icons/subtractCircle.svg';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import { Link } from 'gatsby';

export default function FaqCategoryDropDown({ categoryTitle, faqsList, open }) {
  const [handleDropDown, setHandleDropDown] = useState(open ? true : false);

  return (
    <div
      className={`
        ${
          handleDropDown ? 'p-6 transition-all duration-500' : 'px-6 pt-6 pb-6 transition-all duration-500'
        } rounded-lg bg-white mb-4 
      `}
    >
      <div
        className="flex justify-between items-start cursor-pointer"
        onClick={() => setHandleDropDown(!handleDropDown)}
      >
        <MDSubtitleText className=" w-10/12 whitespace-pre-line " fontWeight="text-bold" title={categoryTitle} />
        <div className="cursor-pointer">
          <img
            className="w-7 mx-auto"
            width="28"
            height="28"
            alt="arrow"
            loading="lazy"
            src={handleDropDown ? SubtractCircle : addCircle}
          />
        </div>
      </div>
      <div className={!handleDropDown ? 'hidden' : 'block mt-6'}>
        {faqsList?.map((faqList) => {
          return (
            <>
              {faqList?.faqs_article_link?.uid && (
                <div key={faqList?.key} className="pb-6 text-lg flex items-center gap-2">
                  <Link
                    to={`/faq/${faqList?.faqs_article_link?.document?.data?.category?.document?.uid}/${faqList?.faqs_article_link?.uid}`}
                    className="underline"
                  >
                    {faqList?.faqs_article_link?.document?.data?.title?.text
                      ? faqList?.faqs_article_link?.document?.data?.title?.text
                      : null}
                  </Link>
                  <div
                    className="rounded-lg px-2 py-1  flex items-center justify-center"
                    style={{ background: faqList?.faqs_article_link?.document?.data?.tag_background_color }}
                  >
                    {' '}
                    <p className="text-xs no-underline ">{faqList?.faqs_article_link?.document?.data?.tag_caption}</p>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}

FaqCategoryDropDown.propTypes = {
  categoryTitle: PropTypes.string,
  categoryUid: PropTypes.string,
  faqsList: PropTypes.array,
  open: PropTypes.bool
};
