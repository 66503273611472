import React from 'react';
import PropTypes from 'prop-types';

function LGText({
  title,
  className,
  textColor,
  textAlign,
  fontWeight,
  style,
  lineHeight,
  letterSpacing,
  contentClassName
}) {
  return (
    <div className={`${className}`}>
      <p
        style={style}
        className={`${fontWeight} text-lg ${textColor} ${textAlign} ${lineHeight} ${letterSpacing} ${contentClassName}`}
      >
        {title}
      </p>
    </div>
  );
}

export default LGText;

LGText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  style: PropTypes.any,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
  contentClassName: PropTypes.string
};

LGText.defaultProps = {
  textColor: 'text-gray-300',
  textAlign: 'text-left',
  fontWeight: 'text-regular'
};
