import React from 'react';
import PropTypes from 'prop-types';
import BlogAuthorSection from '../../components/blog-template/BlogAuthorSection';

function AuthorSection({ authorDetails, leftSideData }) {
  return (
    <div className="flex md:flex-row flex-col items-start md:gap-[112px]">
      <div className="w-full long-article-richtext" dangerouslySetInnerHTML={{ __html: leftSideData }}></div>
      <div className="mx-0 px-6 md:px-0 rounded-lg md:mb-8 md:max-w-[340px] md:min-w-[340px]">
        <BlogAuthorSection post={authorDetails} />
      </div>
    </div>
  );
}

AuthorSection.propTypes = {
  authorDetails: PropTypes?.object,
  leftSideData: PropTypes?.string
};

export default AuthorSection;
