import React from 'react';
import PropTypes from 'prop-types';

function CustomHeading({
  title,
  className,
  fontWeight,
  textColor,
  textAlign,
  fontSize,
  customFontSizeClass,
  letterSpacing,
  lineHeight
}) {
  return (
    <div className={`${className}`}>
      <h1
        className={`${fontWeight}  ${textColor} ${fontSize} ${customFontSizeClass}  ${textAlign} ${letterSpacing} ${lineHeight}`}
      >
        {title}
      </h1>
    </div>
  );
}

export default CustomHeading;

CustomHeading.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
  customFontSizeClass: PropTypes.string,
  letterSpacing: PropTypes.string,
  lineHeight: PropTypes.string
};

CustomHeading.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-bold',
  fontSize: 'text-2xl'
};
