import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderNavigation from './header-navigation/HeaderNavigation';
import Footer from './footer/Footer';
import TrustPilotReview from './common/TrustPilotReview';
import AboutStatrysCta from '../components/common/AboutStatrysCta';
import WhitePaperCta from '../components/common/WhitePaperCta';
import Banner from './common/Banner';
import HomePageCta from './common/HomePageCta';
import { MUI_COLORS } from '../constants/enum';
import DetailedCTA2 from '../components/common/DetailedCTA2';
import TakeALook from '../images/graphics/take-a-look.png';
import HorizontalProgressBar from '../components/common/HorizontalProgressBar';
import ReportArticleNavigation from './blog-template/ReportArticleNavigation';

function Layout({
  children,
  pageClassName,
  Header,
  paymentCard,
  blogPost,
  fullwidth,
  showAboutStatrysFooter,
  showCustomerReviewsFooter,
  showWhitePaperFooter,
  hidePreFooter,
  title,
  points,
  message,
  secondaryBtn,
  secondaryBtnText,
  backGroundColor,
  subPoint,
  isPeaceOfMindGuy,
  hideCta,
  isMainContainerRequired,
  bgColor,
  isHomePage,
  customNavigationBg,
  isMarginRequired,
  removeTopNavigation,
  reportBlogHeaderInfo,
  CTAImageClassName,
  footerBgColor,
  bannerData
}) {
  const [header, setHeader] = useState('header');
  const [bannerVisible, setBannerVisible] = useState(null);
  const listenScrollEvent = () => {
    if (window.scrollY < 73) {
      return setHeader('header');
    } else if (window.scrollY > 350) {
      return setHeader('header2');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    const bannerState = sessionStorage.getItem('isBannerHidden');
    setBannerVisible(bannerState);
    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  const closeButtonClicked = async () => {
    sessionStorage.setItem('isBannerHidden', true);
    setBannerVisible(sessionStorage.getItem('isBannerHidden'));
  };

  return (
    <>
      {bannerVisible === null ? <Banner bannerData={bannerData} onCloseClick={closeButtonClicked} /> : null}
      <div className={`relative layout-main-container layout ${bgColor}`}>
        <header
          className={`${header == 'header' ? 'header' : 'header2'}`}
          id="top-navigation"
          style={{ backgroundColor: Header ? '#F9DBD1' : customNavigationBg ? customNavigationBg : MUI_COLORS.WHITE }}
        >
          <div id="nav-header-bg">
            <div className={`w-full px-4 header-horizontal-padding md:pt-2 pt-4 mx-auto layout`}>
              {removeTopNavigation && header !== 'header' ? (
                <ReportArticleNavigation post={reportBlogHeaderInfo} />
              ) : (
                <HeaderNavigation paymentCard={paymentCard ? true : false} />
              )}
            </div>
            {header !== 'header' && (
              <HorizontalProgressBar
                className={`horizontal-progress-bar-container ${removeTopNavigation ? 'block' : 'md:hidden block'}`}
              />
            )}
          </div>
        </header>
        {Header && (
          <main id="main-container" className={`${pageClassName} ${!isMainContainerRequired && 'main-container'}`}>
            <Header />
          </main>
        )}

        <main
          id="main-container"
          className={`${pageClassName} ${isMainContainerRequired && 'main-container'} ${
            !fullwidth && !isHomePage && 'max-w-6xl px-4 xl:px-0 pt-4 mx-auto layout '
          } ${isHomePage && 'max-w-6xl tab-container-padding mx-auto px-4 md:px-0'} ${
            !isMarginRequired && 'px-4 md:px-0'
          }`}
        >
          {children}
        </main>
        {showWhitePaperFooter && <WhitePaperCta fullwidth={fullwidth} />}
        {showCustomerReviewsFooter && (
          <div className={`margin-for-reviews max-w-6xl md:px-0 px-4 mx-auto`}>
            <TrustPilotReview />
          </div>
        )}
        {showAboutStatrysFooter && <AboutStatrysCta fullwidth={fullwidth} />}
        <div className={`mt-0 h-96 w-full ${blogPost ? 'bg-customBg-whitesmoke' : footerBgColor}`}>
          <div className={`absolute left-0 right-0 ${blogPost ? 'bg-customBg-whitesmoke' : footerBgColor}`}>
            <div className={'max-w-6xl tab-container-padding mx-auto px-4 md:px-0'}>
              {!showAboutStatrysFooter && !hidePreFooter ? (
                <DetailedCTA2
                  title="Looking for a business account?"
                  buttonCaption="Take a look at Statrys"
                  islinkedToHomepage={true}
                  url="/"
                  CTAImage={TakeALook}
                  justifyImage="justify-end"
                  CTAImageClassName={CTAImageClassName}
                />
              ) : (
                <>
                  {!hideCta ? (
                    <HomePageCta
                      title={title}
                      points={points}
                      message={message}
                      secondaryBtn={secondaryBtn}
                      secondaryBtnText={secondaryBtnText}
                      backGroundColor={backGroundColor}
                      subPoint={subPoint}
                      isPeaceOfMindGuy={isPeaceOfMindGuy}
                    />
                  ) : null}
                </>
              )}
            </div>
            <Footer isHomePage={isHomePage} removeTopNavigation={removeTopNavigation} />
          </div>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageClassName: PropTypes.string,
  Header: PropTypes.any,
  paymentCard: PropTypes.bool,
  blog: PropTypes.bool,
  fullwidth: PropTypes.bool,
  changePreFooterImg: PropTypes.bool,
  preFooterText: PropTypes.string,
  showAboutStatrysFooter: PropTypes.bool,
  showCustomerReviewsFooter: PropTypes.bool,
  showWhitePaperFooter: PropTypes.bool,
  hidePreFooter: PropTypes.bool,
  points: PropTypes.array,
  message: PropTypes.string,
  secondaryBtn: PropTypes.bool,
  secondaryBtnText: PropTypes.string,
  title: PropTypes.string,
  backGroundColor: PropTypes.string,
  subPoint: PropTypes.string,
  isPeaceOfMindGuy: PropTypes.bool,
  hideCta: PropTypes.bool,
  isMainContainerRequired: PropTypes.bool,
  isRequiredFooterMargin: PropTypes.bool,
  blogPost: PropTypes.bool,
  bgColor: PropTypes.string,
  isHomePage: PropTypes.bool,
  customNavigationBg: PropTypes.string,
  isMarginRequired: PropTypes.bool,
  removeTopNavigation: PropTypes.bool,
  reportBlogHeaderInfo: PropTypes.object,
  CTAImageClassName: PropTypes.string,
  footerBgColor: PropTypes.string,
  bannerData: PropTypes.string
};

Layout.defaultProps = {
  showCustomerReviewsFooter: true,
  isRequiredFooterMargin: false,
  blogPost: false,
  bgColor: 'bg-white',
  isHomePage: false,
  isMarginRequired: true,
  removeTopNavigation: false,
  footerBgColor: MUI_COLORS?.WHITE
};

export default Layout;
