import React from 'react';
import '../../styles/bullet.scss';

export default function Bullet() {
  return (
    <div className="pb-2 p-1">
      <div className="bg-coral-500 rounded-full bullet"></div>
    </div>
  );
}
