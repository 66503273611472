export const CLIENT_APPLICATION_STATUS = {
  ACTIVE: "ACTIVE",
  PENDING_REVIEW: "PENDING_REVIEW",
  APPROVING: "APPROVING",
};

export const PROSPECT_APPLICATION_PROCESS_STATUS = {
  COMPLETED: "COMPLETED",
  INCOMPLETE: "INCOMPLETE",
};

export const PROSPECT_APPLICATION_COMPLIANCE_STATUS = {
  PENDING_REVIEW: "PENDING_REVIEW",
  APPROVED: "APPROVED",
  APPROVING: "APPROVING",
};
