import { Link } from 'gatsby';
import React from 'react';
import XXSText from '../ui/typography/XXSText';
import '../../styles/components/report-article-navigation.scss';
import PropTypes from 'prop-types';

const ReportArticleNavigation = ({ post }) => {
  return (
    <div className="flex flex-row justify-between items-center pb-3">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Link className="hover:no-underline" to="/blog">
            <XXSText fontWeight="text-bold" title="Blog" />
          </Link>
          <div className="bg-coral-500 h-2 w-2 rounded-full mx-2" />
          <Link
            to={`/blog/${
              post?.linked_blog?.document?.uid ? post?.linked_blog?.document?.uid : post?.category?.document?.uid
            }`}
            className="hover:no-underline"
          >
            <div className="text-lg text-light blog-type text-gray-300">
              {post?.linked_blog?.document?.data?.title?.text
                ? post?.linked_blog?.document?.data?.title?.text
                : post?.category?.document?.data?.title?.text}
            </div>
          </Link>
        </div>
        <h2 className="mt-2 report-title text-charcoal-sealBrown text-bold">{post?.title?.text}</h2>
      </div>
      <Link to="/" className="md:block hidden">
        <img src="/statrys-logo.svg" loading="lazy" alt="Statrys logo" />
      </Link>
    </div>
  );
};

export default ReportArticleNavigation;
ReportArticleNavigation.propTypes = {
  post: PropTypes.object
};
