import React, { useState } from 'react';
import XXSText from '../ui/typography/XXSText';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { guidesCustomUrl } from '../../helper/helpers';
import LatestArticleCard from './LatestArticleCard';
import { SHOW_NUMBER_OF_BLOGS } from '../../constants/enum';
import { slice } from 'lodash';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import H3HeaderText from '../ui/typography/H3HeaderText';
export default function SilosArticleSection({ silosSection, articles }) {
  const [handleLink, setHandleLink] = useState(false);
  const [authorLink, setAuthorLink] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(SHOW_NUMBER_OF_BLOGS.FOUR);
  const LEAST_NUMBER_OF_ARTICLES = -1;

  const loadMore = () => {
    setIndex(index + SHOW_NUMBER_OF_BLOGS.FOUR);
    if (index >= articles.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };
  const initialPosts = slice(articles, 0, index);
  return (
    <div className="md:mt-28 mt-14" id={silosSection?.node?.data?.title.text}>
      <H3HeaderText
        title={
          silosSection?.node?.data?.subtitle.text
            ? silosSection?.node?.data?.subtitle.text
            : silosSection?.node?.data?.title.text
        }
        fontWeight="text-bold"
      />
      {articles.length > LEAST_NUMBER_OF_ARTICLES ? (
        <div className="flex md:flex-row flex-col items-center mt-8">
          <Link
            onMouseEnter={() => setHandleLink(true)}
            onMouseLeave={() => setHandleLink(false)}
            to={guidesCustomUrl(silosSection?.node)}
            className="hover:no-underline"
          >
            <img
              src={
                silosSection?.node?.data?.meta_image?.url
                  ? silosSection?.node?.data?.meta_image?.url
                  : silosSection?.node?.data?.silos_category?.document?.data?.section_and_article_image?.url
              }
              alt={silosSection?.node?.data?.meta_image?.alt ? silosSection?.node?.data?.meta_image?.alt : 'card image'}
              className="rounded-lg new-top-blog-card-img"
              loading="lazy"
              width="660"
              height="340"
            />
          </Link>

          <div className="md:ml-16 md:mt-0 mt-8">
            <Link
              onMouseEnter={() => setHandleLink(true)}
              onMouseLeave={() => setHandleLink(false)}
              to={guidesCustomUrl(silosSection?.node)}
              className="hover:no-underline"
            >
              <H3HeaderText
                className={`${handleLink && 'underline text-coral-500'} mb-4`}
                fontWeight="text-noraml"
                title={silosSection?.node?.data?.title.text}
                textColor={`${handleLink && 'text-coral-500'}`}
              />
              <XXSText
                title={silosSection?.node?.data?.description.text}
                fontSize="text-3xl"
                fontWeight="text-normal"
                className={`${handleLink && 'underline text-coral-500'} section-desscription`}
                textColor={`${handleLink ? 'text-coral-500' : 'text-gray-450'}`}
              />
            </Link>
            <div className="mt-4">
              {silosSection?.node?.data?.author?.uid && (
                <div className="flex mt-12 items-center">
                  <Link
                    onMouseEnter={() => setAuthorLink(true)}
                    onMouseLeave={() => setAuthorLink(false)}
                    to={`/team/${silosSection?.node?.data?.author?.uid}`}
                    className="w-16 mr-4"
                  >
                    <img
                      src={silosSection?.node?.data?.author?.document?.data?.avatar?.url}
                      className="w-16 h-16 rounded-full"
                      alt="author img"
                      loading="lazy"
                    />
                  </Link>
                  <div className="editors-author-detail">
                    <Link
                      onMouseEnter={() => setAuthorLink(true)}
                      onMouseLeave={() => setAuthorLink(false)}
                      to={`/team/${silosSection?.node?.data?.author?.uid}`}
                    >
                      <div
                        className={`${
                          authorLink && 'underline text-coral-500'
                        } text-lg text-gray-500 text-bold leading-5`}
                      >
                        {silosSection?.node?.data?.author?.document?.data?.full_name?.text}
                      </div>
                      <div
                        className={`${authorLink && 'underline text-coral-500'} text-lg text-gray-500 leading-5 my-1`}
                      >{`${silosSection?.node?.data?.author?.document?.data?.role?.text}`}</div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div className="mt-6">
        <div className="flex flex-wrap gap-6">
          {initialPosts?.map((article, index) => {
            return (
              <>
                <div key={index}>
                  <LatestArticleCard data={article} />
                </div>
              </>
            );
          })}
        </div>
      </div>
      {!isCompleted && articles.length > index && (
        <div className="flex justify-center mt-10">
          <PrimaryButton
            bgColor="text-white"
            color="text-coral-500"
            disabled={isCompleted}
            caption="Load more articles"
            onClick={loadMore}
          />
        </div>
      )}
    </div>
  );
}
SilosArticleSection.propTypes = {
  silosSection: PropTypes.obj,
  articles: PropTypes.array
};
