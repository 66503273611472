import React, { useState } from 'react';
import '../../styles/pages/blogs.scss';
import PropTypes from 'prop-types';
import BaseText from '../ui/typography/BaseText';
import { Link } from 'gatsby';
import { stringTruncate, blogCustomUrl, relatedArticleCustomUrl, guidesCustomUrl } from '../../helper/helpers';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
export default function ArticleCard({ data, isCustomRelatedArticles, isFromGuides }) {
  const [handleLink, setHandleLink] = useState(false);
  return (
    <div className="rounded-lg article-blog-card-img-container">
      <Link
        onMouseEnter={() => setHandleLink(true)}
        onMouseLeave={() => setHandleLink(false)}
        to={
          isCustomRelatedArticles
            ? relatedArticleCustomUrl(data)
            : isFromGuides
            ? guidesCustomUrl(data)
            : blogCustomUrl(data)
        }
        className="hover:no-underline flex md:flex-row flex-col gap-6"
      >
        <div className="article-blog-img-container ">
          {isFromGuides ? (
            <GatsbyImageWrapper
              className={`rounded-lg article-blog-img`}
              image={{
                url: data?.data?.header_image.url
                  ? data?.data?.header_image.url
                  : data?.data?.silos_category?.document?.data?.section_and_article_image?.url,
                height: 124,
                width: 124
              }}
              alt={
                data?.data?.header_image?.alt
                  ? data?.data?.header_image?.alt
                  : data?.data?.silos_category?.document?.data?.section_and_article_image?.url
              }
              loading="lazy"
              width="124"
              height="124"
            />
          ) : (
            <GatsbyImageWrapper
              className={`rounded-lg article-blog-img`}
              image={{
                url: data?.data?.header_image.url
                  ? data?.data?.header_image.url
                  : data?.document?.data?.header_image?.url,
                height: 124,
                width: 124
              }}
              alt={
                data?.data?.header_image?.alt ? data?.data?.header_image?.alt : data?.document?.data?.header_image?.url
              }
              loading="lazy"
              width="124"
              height="124"
            />
          )}
        </div>
        <div>
          <BaseText
            title={stringTruncate(
              isCustomRelatedArticles ? data?.document?.data?.title?.text : data.data?.title?.text,
              90
            )}
            fontWeight="text-bold"
            className={`${handleLink && 'underline'} article-blog-card-heading `}
            textColor={`${handleLink ? 'text-corl-500' : 'text-gray-500'}`}
            lineHeight="leading-6"
          />
          <BaseText
            title={
              isCustomRelatedArticles
                ? data?.document?.data?.category?.document?.data?.title?.text
                : data?.data?.category?.document?.data?.title?.text
            }
            className="mt-4 article-blog-text article-blog-card-subheading"
          />
        </div>
      </Link>
    </div>
  );
}

ArticleCard.propTypes = {
  data: PropTypes.object,
  isCustomRelatedArticles: PropTypes.bool,
  isFromGuides: PropTypes.bool
};

ArticleCard.defaultProps = {
  isCustomRelatedArticles: false
};
