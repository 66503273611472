import React, { useState, useEffect } from 'react';
import Input from '../form/SearchField';
import IconSearch from '../../../images/icons/search.svg';
import PropTypes from 'prop-types';

function SearchInput(props) {
  const { name, id, type, onChange, onSearch, defaultValue, isFaq } = props;
  const [searchTerm, updateSearchTerm] = useState('');

  const onSearchChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    updateSearchTerm(value);
  };

  useEffect(() => {
    if (defaultValue) updateSearchTerm(defaultValue);
  }, [defaultValue]);

  return (
    <div className={`flex text-xs h-10 ${isFaq && 'border border-gray-200 h-[64px] rounded-full'}`}>
      <Input
        id={id}
        className={!isFaq && '-mt-4'}
        defaultValue={searchTerm}
        type={type}
        name={name}
        onKeyPress={() => onSearch && onSearch(searchTerm)}
        onChange={(value) => onSearchChange(value)}
        placeholder={isFaq ? 'Search Statrys FAQ' : 'Search'}
        isFaq={isFaq}
      ></Input>
      <img
        className={`w-10 ${isFaq ? 'bg-white rounded-r-full' : 'main-bg rounded-r-3xl'} pr-4 cursor-pointer`}
        onClick={() => onSearch && onSearch(searchTerm)}
        src={IconSearch}
        alt="search icon"
      />
    </div>
  );
}

export default SearchInput;

SearchInput.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  buttonclassName: PropTypes.string,
  btnSpanClassName: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  isFaq: PropTypes.bool
};
