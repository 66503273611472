import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import XSText from '../typography/XSText';
import { MUI_COLORS } from '../../../constants/enum';
export default function TextInput({
  margin,
  className,
  name,
  label,
  placeholder,
  inputRef,
  error,
  showValidation,
  helperText,
  onChange,
  value,
  type,
  onKeyDown,
  max,
  defaultValue,
  height,
  backgroundColor
}) {
  const useStyles = makeStyles({
    textAreaStyle: {
      marginTop: '16px',
      '& .MuiFilledInput-root': {
        border: '1px solid #D6D6D6',
        overflow: 'hidden',
        borderRadius: 8,
        color: 'black',
        height: height + 'px',
        backgroundColor: backgroundColor,
        '&.Mui-focused': {
          color: '#2F2F2F',
          borderColor: '#2D0D45',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)'
        }
      },
      '& .MuiFilledInput-multiline': {
        padding: '16px'
      },
      '& .MuiFilledInput-input': {
        fontSize: '16px',
        fontFamily: 'HKGrotesk-Regular',
        letterSpacing: '-0.5px',
        height: height - 62 + 'px'
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'HKGrotesk-Regular',
        fontWeight: 400,
        fontSize: '14px',
        color: '#7A7A7A',
        letterSpacing: '-0.5px'
      },
      '& .MuiInputLabel-filled': {
        margin: 6,
        marginLeft: 5
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, 5px) scale(1)',
        marginTop: 6
      },
      '& .PrivateNotchedOutline-legendLabelled-7 > span': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: 'black'
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: '#979797'
      },
      '& .MuiFilledInput-root.Mui-error': {
        backgroundColor: '#F9DBD1',
        border: '1px solid #FF4F42',
        color: '#FF4F42',
        fontSize: '16px'
      },
      '& .MuiInputLabel-root.Mui-error': {
        color: '#FF4F42'
      },
      '& .MuiFormHelperText-root': {
        fontFamily: 'HKGrotesk-Regular',
        color: '#FF4F42',
        fontSize: '16px',
        letterSpacing: '-0.5px'
      }
    }
  });
  const classes = useStyles();
  return (
    <div className={margin}>
      <TextField
        className={`${classes.textAreaStyle} ${className}`}
        InputProps={{ disableUnderline: true }}
        inputProps={{ maxLength: max }}
        name={name}
        label={label}
        placeholder={placeholder}
        variant="filled"
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        error={error}
        helperText={helperText}
        type={type}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        rows={4}
        multiline
      />
      <div className="flex justify-between mt-2">
        {showValidation && (
          <XSText
            textColor={error ? 'error-text-description' : 'text-light-gray'}
            title="Minimum 50 characters"
            className="ml-1"
          />
        )}
        {showValidation && (
          <XSText
            textColor={error ? 'error-text-description' : 'text-light-gray'}
            title={`${value ? value?.length : 0}/50`}
          />
        )}
      </div>
    </div>
  );
}

TextInput.propTypes = {
  margin: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  onKeyDown: PropTypes.any,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  defaultValue: PropTypes.any,
  height: PropTypes.any,
  showValidation: PropTypes.any,
  backgroundColor: PropTypes.string
};

TextInput.defaultProps = {
  className: 'w-full',
  type: 'string',
  height: '136',
  backgroundColor: MUI_COLORS.WHITE
};
