import React from 'react';
import CheckCircle from '../../images/icons/check-circle.svg';
import BaseText from '../ui/typography/BaseText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import H3HeaderText from '../ui/typography/H3HeaderText';
import { StaticImage } from 'gatsby-plugin-image';

export const CtaPoints = [
  {
    id: 0,
    text: '100% online application form'
  },
  {
    id: 1,
    text: 'No account opening fee, no initial deposit'
  },
  {
    id: 2,
    text: 'Premium support'
  },
  {
    id: 3,
    text: 'Physical and virtual cards'
  }
];

export default function PaypalCta() {
  return (
    <div className="flex md:flex-row flex-col mt-14 w-full">
      <div className={`flex md:flex-row flex-col h-full w-full bg-coral-500 rounded-lg overflow-auto`}>
        <div className="p-8 flex flex-col md:w-1/2 w-full">
          <H3HeaderText
            title={`Get your Hong Kong business \naccount open in a few days`}
            className="md:whitespace-pre-line"
            fontWeight="text-bold"
            textColor="text-white"
          />
          <div className="flex flex-col">
            {CtaPoints?.map((point, index) => {
              return (
                <div className="flex flex-row mt-4" key={index}>
                  <div className="home-page-cta-icon">
                    <img src={CheckCircle} width="23" height="23" loading="lazy" alt="CheckCircle" />
                  </div>
                  <div className="flex flex-col">
                    <BaseText title={point.text} className="ml-2 whitespace-pre-line" textColor="text-white" />
                  </div>
                </div>
              );
            })}
          </div>

          <div className={`flex flex-col md:items-start items-center`}>
            <PrimaryButton
              urlLink={process.env.SIGN_UP_SELECT_MANAGER}
              className="mt-11 open-account-btn"
              caption="Open account"
              bgColor="bg-white"
              color={'text-coral-500'}
              isBorderRequired={true}
            />
          </div>
        </div>
        <div className={`md:w-1/2 w-full flex self-end`}>
          <StaticImage src="../../images/graphics/paypal/paypal-cta.png" alt="cta-image" width="576" height="414" />
        </div>
      </div>
    </div>
  );
}
