import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

export default function StepsWithBigImage({ content }) {
  return (
    <div>
      {content?.map((stepContent, index) => {
        return (
          <div key={index}>
            <div className="flex flex-col md:flex-row gap-8 mt-[72px]">
              <div className="md:w-[328px]">
                <div className="text-[28px] min-w-10 max-w-[40px] h-10 bg-salmon-light text-coral-500 rounded-full border border-coral-500 flex items-center justify-center text-bold mb-4">
                  {stepContent?.step_number?.text}
                </div>
                <div
                  className="long-article-richtext"
                  dangerouslySetInnerHTML={{ __html: stepContent?.step_content?.html }}
                />
              </div>
              <GatsbyImage
                image={getImage(stepContent?.step_image?.gatsbyImageData)}
                alt={stepContent?.step_image?.alt}
                loading="lazy"
                className="max-w-[790px] border border-gray-400 rounded-2xl"
                objectFit="contain"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

StepsWithBigImage.propTypes = {
  content: PropTypes?.object
};
