import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../constants/enum';

export default function ProgressBar({ completed }) {
  const containerStyles = {
    position: 'absolute',
    top: '0px',
    borderRadius: '8px',
    height: '98%',
    width: '4px',
    transition: 'height 1s ease-in-out',
    backgroundColor: MUI_COLORS.WHITE
  };

  const fillerStyles = {
    height: `${completed > 90 ? '100' : completed}%`,
    width: '4px',
    backgroundColor: MUI_COLORS.CORAL,
    transition: 'height 1s ease-in-out',
    borderRadius: 'inherit'
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
}
ProgressBar.propTypes = {
  completed: PropTypes.number
};
ProgressBar.defaultProps = {
  completed: 10
};
