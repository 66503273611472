import React from "react";
import PropTypes from "prop-types";

function ClaimHeaderText({title, className, textColor, textAlign, fontWeight }) {
  return ( 
    <div className={`${className}`}>
      <p className={`${fontWeight} text-4xl ${textColor} ${textAlign} letter-spacing-1`}>{title}</p>
    </div>
  );
}

export default ClaimHeaderText;

ClaimHeaderText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
};

ClaimHeaderText.defaultProps = {
  textColor: "text-gray-500",
  textAlign: "text-left",
  fontWeight: "text-bold"
};


