import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';
const Arrow = ({ stroke, width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_23_285)">
        <rect width="15" height="15" rx="7.5" fill={stroke} />
        <path
          d="M9 10.5H8.5C8.23478 10.5 7.98043 10.3946 7.79289 10.2071C7.60536 10.0196 7.5 9.76522 7.5 9.5V7C7.5 6.86739 7.44732 6.74022 7.35355 6.64645C7.25978 6.55268 7.13261 6.5 7 6.5H6.5"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 4C7.20055 4 7.15222 4.01466 7.11111 4.04213C7.07 4.0696 7.03795 4.10865 7.01903 4.15433C7.00011 4.20001 6.99516 4.25028 7.0048 4.29877C7.01445 4.34727 7.03826 4.39181 7.07322 4.42678C7.10819 4.46174 7.15273 4.48555 7.20123 4.4952C7.24972 4.50484 7.29999 4.49989 7.34567 4.48097C7.39135 4.46205 7.4304 4.43 7.45787 4.38889C7.48534 4.34778 7.5 4.29945 7.5 4.25C7.5 4.1837 7.47366 4.12011 7.42678 4.07322C7.37989 4.02634 7.3163 4 7.25 4Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C3.60322 14.2098 5.51088 15 7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48912 0 7.5 0C5.51088 0 3.60322 0.790176 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5V7.5Z"
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_23_285">
          <rect width="15" height="15" rx="7.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Arrow;

Arrow.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  outerFill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

Arrow.defaultProps = {
  stroke: MUI_COLORS.DARK_CHARCOAL,
  width: 15,
  height: 15
};
