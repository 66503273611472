import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AudioPlayer2 from '../ui/audio-player/Audio';
import { Link } from 'gatsby';
import { MUI_COLORS } from '../../constants/enum';
import XXSText from '../ui/typography/XXSText';
import '../../styles/pages/blogTemplate/header.scss';
import AuthorDetails from './AuthorDetails';
import statrysSquareImg from '../../images/statrys-square.png';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

function Header({ post, colors, publishDate, type }) {
  const [showAuthorDetails, setShowAuthorDetails] = useState(false);
  const [showReviewedByDetails, setShowReviewedByDetails] = useState(false);
  const [showFactCheckByDetails, setShowFactCheckByDetails] = useState(false);

  return (
    <div
      id="guides-header"
      className="md:pt-14 pt-2 pb-20"
      style={{ backgroundColor: colors?.backgroundColor ? colors?.backgroundColor : MUI_COLORS.WHITE }}
    >
      <div className="max-w-6xl flex xl:flex-row flex-col items-center justify-between mx-auto">
        <div className="flex flex-col items-start md:px-0 px-4 md:w-1/2">
          {type === 'guides' ? (
            <div className="flex flex-row flex-wrap items-center">
              <Link className="hover:no-underline" to="/guides">
                <XXSText fontWeight="text-light" title="Guides" />
              </Link>
              <div className={`bg-coral-500 h-2 w-2  rounded-full mx-2`} />
              <Link
                to={`/guides/${
                  post?.linked_blog?.document?.uid
                    ? post?.linked_blog?.document?.uid
                    : post?.silos_category?.document?.uid
                }`}
                className="hover:no-underline"
              >
                <div
                  className={`text-lg text-light blog-type`}
                  style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
                >
                  {post?.linked_blog?.document?.data?.title?.text
                    ? post?.linked_blog?.document?.data?.title?.text
                    : post?.silos_category?.document?.data?.title?.text}
                </div>
              </Link>
              {post?.section_category?.document?.uid && (
                <div className="flex flex-row items-center">
                  <div className={`bg-coral-500 h-2 w-2  rounded-full mx-2`} />
                  <Link
                    to={`/guides/${post?.silos_category?.document?.uid}/${
                      post?.section_category?.document?.data?.custom_url?.text
                        ? post?.section_category?.document?.data?.custom_url?.text
                        : post?.section_category?.document?.uid
                    }`}
                    className="hover:no-underline"
                  >
                    <div
                      className={`text-lg text-light blog-type`}
                      style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
                    >
                      {post?.section_category?.document?.data?.subtitle?.text
                        ? post?.section_category?.document?.data?.subtitle?.text
                        : post?.section_category?.document?.data?.title?.text}
                    </div>
                  </Link>
                </div>
              )}
              {post?.subtitle?.text && (
                <div className="flex flex-row items-center">
                  <div className={`bg-coral-500 h-2 w-2  rounded-full mx-2`} />
                  <div
                    className={`text-lg text-light blog-type`}
                    style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
                  >
                    {post?.subtitle?.text}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-row items-center">
              <Link className="hover:no-underline" to={'/blog'}>
                <XXSText fontWeight="text-bold" title={'Blog'} />
              </Link>
              <div className={`bg-coral-500 h-2 w-2  rounded-full mx-2`} />
              <Link
                to={`/blog/${
                  post?.linked_blog?.document?.uid ? post?.linked_blog?.document?.uid : post?.category?.document?.uid
                }`}
                className="hover:no-underline"
              >
                <div
                  className={`text-lg text-light blog-type`}
                  style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
                >
                  {post?.linked_blog?.document?.data?.title?.text
                    ? post?.linked_blog?.document?.data?.title?.text
                    : post?.category?.document?.data?.title?.text}
                </div>
              </Link>
            </div>
          )}

          <h1
            className="mt-6 blog-main-title"
            style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
          >
            {post?.title.text}
          </h1>
          <div className="flex flex-row mt-6">
            <XXSText
              title={publishDate}
              fontWeight="text-bold"
              style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
            />
            <div className="flex flex-row items-center">
              <div className={`bg-coral-500 h-2 w-2  rounded-full ml-2`}></div>
              <XXSText
                title={post?.time_to_read?.text ? post?.time_to_read?.text : '6 minute read'}
                className="ml-2"
                style={{ color: colors?.textColor ? colors?.textColor : MUI_COLORS.DARK_GRAY_MAIN }}
              />
            </div>
          </div>
          {post.podcast_link.text ? (
            <div className="mt-4">
              <AudioPlayer2 url={post?.podcast_link?.text} />
            </div>
          ) : null}
          <div
            onMouseEnter={() => setShowAuthorDetails(true)}
            onMouseLeave={() => setShowAuthorDetails(false)}
            className="relative"
          >
            <div className="flex mt-6">
              <Link to={`/team/${post?.author?.document?.uid}`} className="md:mr-4 mr-2 ">
                <GatsbyImageWrapper
                  className="rounded-full blog-author-img"
                  image={{
                    url: post?.author?.document?.data?.avatar?.url
                  }}
                  alt={post?.author?.document?.data?.avatar?.alt}
                  loading="eager"
                  width="42"
                  height="42"
                />
              </Link>
              <div className="flex items-center">
                <Link to={`/team/${post?.author?.document?.uid}`}>
                  <div className="text-xl text-bold leading-5">{post?.author?.document?.data?.full_name?.text}</div>
                  <div className="text-xl leading-5 my-1 ">{`${post?.author?.document?.data?.role?.text}`}</div>
                </Link>
              </div>
            </div>
            {showAuthorDetails && <AuthorDetails user={post?.author} className="author-details md:block hidden" />}
          </div>
          {post?.reviewed_by?.document?.data?.full_name?.text && (
            <div
              onMouseEnter={() => setShowReviewedByDetails(true)}
              onMouseLeave={() => setShowReviewedByDetails(false)}
              className="relative"
            >
              <div className="flex mt-2">
                <div className="text-xl leading-5 mr-1">Reviewed by </div>
                <div className="flex items-center">
                  <Link to={`/team/${post?.reviewed_by?.document?.uid}`}>
                    <div className="text-xl text-bold leading-5">
                      {post?.reviewed_by?.document?.data?.full_name?.text}
                    </div>
                  </Link>
                </div>
              </div>
              {showReviewedByDetails && (
                <AuthorDetails user={post?.reviewed_by} className="author-details md:block hidden" />
              )}
            </div>
          )}
          {post?.fact_checked_by?.document?.data?.full_name?.text && (
            <div
              onMouseEnter={() => setShowFactCheckByDetails(true)}
              onMouseLeave={() => setShowFactCheckByDetails(false)}
              className="relative"
            >
              <div className="flex mt-2">
                <div className="text-xl leading-5 mr-1">Fact checked by</div>
                <div className="flex items-center">
                  <Link to={`/team/${post?.fact_checked_by?.document?.uid}`}>
                    <div className="text-xl text-bold leading-5">
                      {post?.fact_checked_by?.document?.data?.full_name?.text}
                    </div>
                  </Link>
                </div>
              </div>
              {showFactCheckByDetails && (
                <AuthorDetails user={post?.fact_checked_by} className="author-details md:block hidden" />
              )}
            </div>
          )}
        </div>
        {type === 'guides' ? (
          <div className="mx-2 mt-6">
            <GatsbyImageWrapper
              image={{
                url: post?.header_image.url ? post?.header_image.url : statrysSquareImg,
                width: 340,
                height: 340
              }}
              className="blog-header-img rounded-lg"
              objectFit="contain"
              alt={
                post?.header_image.alt
                  ? post?.header_image.alt
                  : post?.silos_category?.document?.data?.section_and_article_image?.alt
              }
              loading="eager"
            />
          </div>
        ) : (
          <div className="mx-2 mt-6">
            <GatsbyImageWrapper
              image={{
                url: post?.header_image.url ? post?.header_image.url : statrysSquareImg,
                width: 340,
                height: 340
              }}
              className="blog-header-img rounded-lg"
              objectFit="contain"
              alt="content image"
              loading="eager"
            />
          </div>
        )}
      </div>
    </div>
  );
}

Header.propTypes = {
  post: PropTypes.object,
  allPostsList: PropTypes.array,
  blogUid: PropTypes.string,
  colors: PropTypes.object,
  publishDate: PropTypes.string,
  type: PropTypes.string
};

export default Header;
