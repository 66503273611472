import React from 'react';
import '../../styles/pages/home/home-page-cta.scss';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { Link } from 'gatsby';
import H3HeaderText from '../ui/typography/H3HeaderText';
import BaseText from '../ui/typography/BaseText';
import CheckCircle from '../../images/icons/check-circle-1.svg';
import PropTypes from 'prop-types';
import XSText from '../ui/typography/XSText';
import { StaticImage } from 'gatsby-plugin-image';

export default function HomePageCta({
  title,
  points,
  message,
  secondaryBtn,
  secondaryBtnText,
  backGroundColor,
  subPoint,
  isPeaceOfMindGuy
}) {
  return (
    <>
      <Link to={process.env.SIGN_UP} className="hover:no-underline">
        <div
          className={`flex flex-col-reverse mb-8 md:flex-row w-full home-page-cta-container ${backGroundColor} gap lg:gap-12 md:items-start items-center rounded-lg cursor-pointer`}
        >
          <div className="flex md:self-end self-center md:w-2/4 w-full">
            {!isPeaceOfMindGuy ? (
              <StaticImage
                src={'../../images/graphics/business-account-cta.png'}
                className="home-page-cta-img"
                alt="peace-of-mind"
              />
            ) : (
              <StaticImage
                src={'../../images/graphics/peace-of-mind.png'}
                className="home-page-cta-img"
                alt="peace-of-mind"
              />
            )}
          </div>
          <div className="flex self-center home-page-cta-content-container md:px-0 px-4 my-10 md:my-0 md:w-2/4 w-full">
            <div className="flex flex-col">
              <H3HeaderText title={title} className="md:whitespace-pre-line" fontWeight="text-bold" />
              <div className="flex flex-col home-page-cta-content">
                {points?.map((point, index) => {
                  return (
                    <div className="flex flex-row mt-4" key={index}>
                      <div className="home-page-cta-icon">
                        <img src={CheckCircle} />
                      </div>
                      <div className="flex flex-col">
                        <BaseText title={point.text} className="ml-2 whitespace-pre-line" />
                        {subPoint && index === 1 ? (
                          <XSText title={subPoint} textColor="text-gray-450" className="ml-2 mt-1" />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              {message && (
                <H3HeaderText title={message} className="mt-7" fontWeight="text-bold" textColor="text-coral-500" />
              )}
              {secondaryBtn ? (
                <div className="flex md:flex-row flex-col justify-between">
                  <PrimaryButton
                    urlLink={process.env.SIGN_UP}
                    className="mt-8 medium-button"
                    caption="Open account"
                    fontSize="text-lg"
                  />
                  <PrimaryButton
                    caption={secondaryBtnText}
                    className="medium-button mt-8 md:ml-2"
                    bgColor="transparent"
                    color="text-coral-500"
                    urlLink="/contact"
                    fontSize="text-lg"
                  />
                </div>
              ) : (
                <PrimaryButton urlLink={process.env.SIGN_UP} className="mt-8 open-account-btn" caption="Open account" />
              )}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

HomePageCta.propTypes = {
  title: PropTypes.string,
  points: PropTypes.array,
  message: PropTypes.string,
  secondaryBtn: PropTypes.bool,
  secondaryBtnText: PropTypes.string,
  backGroundColor: PropTypes.string,
  subPoint: PropTypes.string,
  isPeaceOfMindGuy: PropTypes.bool
};
HomePageCta.defaultProps = {
  points: [{}],
  isPeaceOfMindGuy: false
};
