import React from 'react';
import BaseText from '../ui/typography/BaseText';
import H2HeaderText from '../ui/typography/H2HeaderText';
import H3HeaderText from '../ui/typography/H3HeaderText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

export default function AboutStatrysCta({ fullwidth }) {
  return (
    <div className={`${fullwidth && 'max-w-6xl mx-auto'}`}>
      <div
        className={`bg-salmon-500 flex flex-col flex-col-reverse md:flex-row py-12 md:px-10 px-4 rounded-lg mt-52 mb-28`}
      >
        <div className="w-full">
          <H3HeaderText className="mb-2" title="About Statrys" />
          <H2HeaderText
            className="md:w-10/12 md:whitespace-pre-line"
            fontWeight="text-regular"
            title={`Business accounts \nthat fit your needs`}
          />
          <BaseText
            className="mt-4 mb-8"
            title={
              <div>
                You’re looking to register a new company. You’re provably looking for a{' '}
                <span className="text-bold">
                  multi-currency account, easy payments, transparent pricing, Euro IBAN accounts
                </span>
                , and <span className="text-bold">competitive FX</span> rates. Statrys is a banking alternative.
              </div>
            }
          />
          <div className="flex flex-col md:flex-row md:gap-10 gap-5 justify-between">
            <div className="mt-8 w-full">
              <img src="../../../cursor-hand-coral.svg" alt="IconCursorHand" className="h-8" />
              <BaseText className="mt-6 mb-4" title="Easy to get started" fontWeight="text-bold" />
              <BaseText
                className="whitespace-pre-line"
                title={`Apply 100% online, get your account up in just a few days.`}
              />
              <Link to="/business-account">
                <BaseText
                  fontWeight="text-bold"
                  textColor="text-coral-500"
                  className="md:whitespace-pre-line mt-2"
                  title={`Read about our \nBusiness Account`}
                />
              </Link>
            </div>
            <div className="mt-8 w-full">
              <img src="../../../cash-search.svg" alt="IconCashSearch" className="h-8" />
              <BaseText className="mt-6 mb-4" title="Transparent pricing" fontWeight="text-bold" />
              <BaseText
                className="whitespace-pre-line"
                title={`Never be surprised when making and receiving payments.`}
              />
              <Link to="/pricing">
                <BaseText
                  fontWeight="text-bold"
                  className="mt-2"
                  textColor="text-coral-500"
                  title={`See our pricing`}
                />
              </Link>
            </div>
            <div className="mt-8 w-full">
              <img
                src="../../../headphones-customer-support-human.svg"
                className="h-8"
                alt="IconHeadPhonesCustomerSupport"
              />
              <BaseText className="mt-6 mb-4" title={`Real support from \nreal people`} fontWeight="text-bold" />
              <BaseText
                className="whitespace-pre-line"
                title={`No bots, talk to a real person whenever you need support.`}
              />
              <Link to="/reviews">
                <BaseText
                  fontWeight="text-bold"
                  className="mt-2"
                  textColor="text-coral-500"
                  title={`See our reviews`}
                />
              </Link>
            </div>
          </div>
          <div className="md:text-left text-center">
            <PrimaryButton urlLink="/signup/getstarted" className="mt-12 w-72" caption="Open account" />
          </div>
        </div>
        <div className="relative w-8/12 flex md:justify-end justify-center mx-auto md:mx-0">
          <div className="w-full md:absolute -mt-28 md:-mr-6">
            <StaticImage src="../../images/graphics/about-statrys-cta.png" className="" alt="Open account" />
          </div>
        </div>
      </div>
    </div>
  );
}

AboutStatrysCta.propTypes = {
  fullwidth: PropTypes.bool
};
