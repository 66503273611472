exports.linkResolver = (doc) => {
  const blogRootURL = '/blog';
  const blogCustomUrl = (post) => {
    if (post?.data?.custom_url[0]?.text) return `${blogRootURL}/${post?.data?.custom_url[0]?.text}/${post.uid}`;
    if (post?.data?.custom_url_without_blog[0]?.text)
      return `/${post?.data?.custom_url_without_blog[0]?.text}/${post.uid}`;
    else return `${blogRootURL}/${post.uid}`;
  };
  const faqRootURL = '/faq';
  const faqCustomUrl = (post) => {
    return `${faqRootURL}/${post?.data?.category?.uid}/${post.uid}`;
  };
  let resourceURL = (doc) => {
    return `/resources/${doc.data.website_activity.toLowerCase()}`;
  };
  const reviewRootURL = 'reviews/company-secretary-hong-kong';
  switch (doc.type) {
    case 'blog':
      return blogCustomUrl(doc);
    case 'faq_articles':
      return faqCustomUrl(doc);
    case 'faq_articles_category':
      return `${faqRootURL}/${doc.uid}`;
    case 'user':
      return `/team/${doc.uid}`;
    case 'silos':
      return `/guides/${doc.uid}`;
    case 'silos-section':
      return `/guides/${doc.data?.silos_category?.uid}/${
        doc?.data?.custom_url[0]?.text ? doc?.data?.custom_url[0]?.text : doc?.uid
      }`;
    case 'article':
      return `/guides/${doc.data?.silos_category.uid}/${
        doc?.data?.section_category?.slug
          ? doc?.data?.section_category?.slug
          : doc?.data?.section_category?.document?.uid
      }/${doc.uid}`;
    case 'use_case':
      return `/use-case/${doc.uid}`;
    case 'resources':
      return `${resourceURL(doc)}/${doc.uid}`;
    case 'reviews':
      return `/${reviewRootURL}/${doc.uid}`;
    case 'long_article':
      return `${doc?.data?.page_url[0]?.text}`;
    default:
      if (!doc.uid) return '/';
      return doc.uid;
  }
};
