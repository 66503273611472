import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';

export default function CashPaymentBag({ stroke, width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_308_16228)">
        <path d="M1.5 19V31" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M1.5 29H23.5C23.5 27.9391 23.0786 26.9217 22.3284 26.1716C21.5783 25.4214 20.5609 25 19.5 25H14.5C14.5 23.9391 14.0786 22.9217 13.3284 22.1716C12.5783 21.4214 11.5609 21 10.5 21H1.5"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9.5 25H14.5" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M27.4867 24.0107C28.9399 22.9282 30.0704 21.47 30.7568 19.793C31.4432 18.116 31.6593 16.2835 31.3821 14.4928C31.1048 12.7021 30.3446 11.0209 29.1832 9.62994C28.0218 8.23901 26.5032 7.19102 24.7907 6.5987L27.2613 2.37203C27.3428 2.23314 27.3857 2.07504 27.3857 1.91403C27.3857 1.75302 27.3428 1.59492 27.2613 1.45603C27.1768 1.31583 27.0573 1.20005 26.9144 1.12009C26.7716 1.04014 26.6104 0.998757 26.4467 1.00003H16.5533C16.3901 0.99922 16.2294 1.04082 16.0871 1.12075C15.9448 1.20069 15.8256 1.31622 15.7413 1.45603C15.6599 1.59492 15.6169 1.75302 15.6169 1.91403C15.6169 2.07504 15.6599 2.23314 15.7413 2.37203L18.212 6.5987C16.2557 7.27501 14.5584 8.54352 13.3556 10.2282C12.1529 11.9128 11.5043 13.9301 11.5 16"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.7667 18.4587C19.0554 18.8379 19.4314 19.142 19.8626 19.3451C20.2939 19.5482 20.7677 19.6443 21.244 19.6254C22.7627 19.6254 23.9947 18.7013 23.9947 17.5627C23.9947 16.424 22.7627 15.5014 21.244 15.5014C19.7253 15.5014 18.5 14.5773 18.5 13.4373C18.5 12.2973 19.732 11.3747 21.2493 11.3747C21.7257 11.3554 22.1996 11.4514 22.6309 11.6545C23.0623 11.8576 23.4381 12.1618 23.7267 12.5413"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M21.2495 19.6253V21" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21.2495 10V11.3747" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_308_16228">
          <rect width="33" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

CashPaymentBag.propTypes = {
  stroke: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

CashPaymentBag.defaultProps = {
  stroke: MUI_COLORS.GREEN,
  width: 33,
  height: 32
};
