import React from 'react';
import FixedClaimHeaderText from '../ui/typography/FixedClaimHeaderText';
import MSText from '../ui/typography/MSText';
import '../../styles/pages/trust-pilot-review.scss';
import XSText from '../ui/typography/XSText';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';

const customerReviews = [
  {
    date: 'Jul 17, 2023',
    message: `Easy to use - fast and efficient`,
    name: 'Victor',
    id: 1
  },
  {
    date: 'Jun 20, 2023',
    message: `Statrys delivers all I have been hoping for`,
    name: 'Steffens',
    id: 2
  },
  {
    date: 'Jun 26, 2023',
    message: 'They value my time!',
    name: 'Diego',
    id: 3
  }
];

export default function TrustPilotReview({ title, hideTitle }) {
  return (
    <>
      <div className="responsive-grid">
        <div className="bg-green-600 rounded-md trust-pilot-reviews-container">
          <div className="pilot-content-container flex flex-col items-center">
            <FixedClaimHeaderText
              fontSize="text-3xl"
              textAlign="text-center"
              fontWeight="text-regular"
              textColor="text-white"
              title="Excellent"
            />
            <StaticImage
              src="../../images/graphics/trustPilot/trust-pilot-four-start.png"
              alt="trustPilot rating"
              className="my-4 trust-pilot-img"
            />
            <div className="flex justify-center gap-1">
              <MSText textColor="text-white" textAlign="text-center" title="Based on" />
              <a href="https://www.trustpilot.com/review/statrys.com" target="_blank" rel="noreferrer">
                <MSText
                  textColor="text-white hover:text-coral-500 underline"
                  textAlign="text-center"
                  title="202 reviews"
                />
              </a>
            </div>
            <StaticImage
              src="../../images/graphics/trustPilot/trust-pilot-white-text.png"
              alt="trustpilot"
              className="mt-4 trust-pilot-white flex self-center"
            />
          </div>
        </div>
        {customerReviews?.map((customerReview, index) => {
          return (
            <>
              <div className="bg-green-200 rounded-md trust-pilot-reviews-container users-review" key={index}>
                <div className="trustpilot-content-container flex flex-col items-center">
                  <XSText
                    textAlign="text-center"
                    fontWeight="text-regular"
                    textColor="text-gray-450"
                    title={customerReview?.date}
                  />
                  <StaticImage
                    src="../../images/graphics/reviews-rating.png"
                    alt="trustPilot rating"
                    className="my-4 trust-pilot-img"
                  />

                  <XSText
                    textColor="text-gray-300"
                    textAlign="text-center"
                    title={customerReview?.message}
                    fontWeight="text-bold"
                    className="whitespace-pre-line"
                  />

                  <XSText
                    textAlign="text-center"
                    className="mt-4"
                    fontWeight="text-regular"
                    textColor="text-gray-450"
                    title={customerReview?.name}
                  />
                </div>
              </div>
            </>
          );
        })}
      </div>
      {!hideTitle && (
        <BaseText
          title={title}
          className="md:whitespace-pre-line content-main-heading mt-10"
          textColor="text-green-600"
          fontWeight="text-bold"
        />
      )}
    </>
  );
}

TrustPilotReview.propTypes = {
  title: PropTypes.string,
  hideTitle: PropTypes.bool
};

TrustPilotReview.defaultProps = {
  title: `85% of our customers open their \naccount in less than 3 days`,
  hideTitle: false
};
