import React from 'react';
import { MUI_COLORS } from '../../../constants/enum';

const creditCard = ({ stroke }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 18.9319C4.5 18.9319 3 19.6819 12.75 19.6819C15.75 19.6819 18.75 18.9319 19.5 15.9319"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 11.3249H3.132C3.27497 11.325 3.41617 11.2933 3.54537 11.2321C3.67457 11.1708 3.78853 11.0816 3.879 10.9709L7.929 6.98193L11.758 7.74793C12.304 7.85693 12.663 8.29293 12.425 9.24793C12.3787 9.43414 12.2777 9.60226 12.1352 9.73072C11.9927 9.85918 11.815 9.94213 11.625 9.96893L9.75 10.2419C9.63716 10.2532 9.52776 10.2872 9.42837 10.3418C9.32898 10.3964 9.24165 10.4705 9.17161 10.5597C9.10156 10.6489 9.05026 10.7513 9.02077 10.8608C8.99128 10.9703 8.98422 11.0846 9 11.1969V13.7539C8.93725 14.0454 8.7831 14.3092 8.56 14.5069L6.482 16.1459"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15.9319H21.75C22.1478 15.9319 22.5294 15.7738 22.8107 15.4925C23.092 15.2112 23.25 14.8297 23.25 14.4319V6.18188C23.25 5.78406 23.092 5.40253 22.8107 5.12122C22.5294 4.83992 22.1478 4.68188 21.75 4.68188H10.5C10.1022 4.68188 9.72064 4.83992 9.43934 5.12122C9.15804 5.40253 9 5.78406 9 6.18188V7.19988"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4919 8.43188H23.2499"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.25 11.4319H18.75" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default creditCard;

creditCard.defaultProps = {
  stroke: MUI_COLORS.CORAL
};
