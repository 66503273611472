import React from 'react';
import XSText from '../ui/typography/XSText';
import XMSText from '../ui/typography/XMSText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import TrustPilotLogo from '../../images/icons/trustpilot-logo.svg';
import TrustPilotRating from '../../images/graphics/trustpilot-rating.png';
import IconPin from '../../images/icons/pin.svg';
import IconCircleCheck from '../../images/icons/check-circle-1.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import SearchInput from '../ui/form/SearchInput';
import XLSubtitleText from '../ui/typography/XLSubtitleText';
import AudioPlayer2 from '../ui/audio-player/Audio';
import IconLinkedIn from '../../images/icons/computer-logo-linkedin.svg';
import IconEmailAction from '../../images/icons/email-action-at.svg';
import BaseText from '../ui/typography/BaseText';
import { Link, navigate } from 'gatsby';
import config from '../../config/config.json';
const currenciesList = ['HKD', 'USD', 'EUR', 'CNY', 'GBP', 'SGD', 'AUD', 'JPY', 'NZD', 'CHF', 'CAD'];
const navigateToSearchResults = (searchTerm) => {
  navigate('/search', { state: { searchTerm } });
};
function PromoHeader({ post }) {
  return (
    <div className="bg-customBg-secondaryBg mb-32">
      <div className="max-w-6xl pt-7 mx-auto">
        <div
          className={`md:flex items-center gap-6 -mt-14 ${
            post?.custom_url_without_blog?.text ? 'justify-end' : 'justify-between'
          }`}
        >
          <div
            className={`flex items-center ${post?.custom_url_without_blog?.text ? 'hidden' : null}
          ${
            !post?.linked_blog?.document?.data?.title?.text && !post?.category?.document?.data?.title?.text
              ? 'hidden'
              : null
          }
          gap-3`}
          >
            <Link to="/blog">
              <div className={`text-xl text-light letter-spacing-1`}>Statrys blog</div>
            </Link>
            <div className={`bg-coral-500 h-2 w-2 rounded-full`}></div>
            <Link
              to={`/blog/${
                post?.linked_blog?.document?.uid ? post?.linked_blog?.document?.uid : post?.category?.document?.uid
              }`}
            >
              <div className={`text-xl text-light letter-spacing-1`}>
                {post?.linked_blog?.document?.data?.title?.text
                  ? post?.linked_blog?.document?.data?.title?.text
                  : post?.category?.document?.data?.title?.text}
              </div>
            </Link>
          </div>
          <SearchInput onSearch={(value) => navigateToSearchResults(value)} />
        </div>
        <div className="md:my-16 flex xl:flex-row flex-col xl:items-end items-center justify-between gap-20">
          <div className="w-full">
            <div>
              <XLSubtitleText className="md:mt-0 mx:-mt-12" title={post?.title?.text} />
              {post?.podcast_link?.text ? (
                <div className="mt-4">
                  <AudioPlayer2 url={post?.podcast_link?.text} />
                </div>
              ) : null}
            </div>
            <div className="flex mt-6 mb-10">
              <Link to={`/team/${post?.author?.document?.uid}`} className="w-16 mr-4">
                <img
                  src={post?.author?.document?.data?.avatar?.url}
                  className="w-16 h-16 rounded-full"
                  alt="author img"
                />
              </Link>
              <div>
                <Link to={`/team/${post?.author?.document?.uid}`}>
                  <div className="text-xl text-bold leading-5">{post?.author?.document?.data?.full_name?.text}</div>
                  <div className="text-xl leading-5 my-1">{`${post?.author?.document?.data?.role?.text}`}</div>
                </Link>
                <div className="flex gap-2">
                  <a href={post?.author?.document?.data?.email?.url}>
                    <img src={IconEmailAction} alt="emil icon" />
                  </a>
                  <a href={post?.author?.document?.data?.linkedin?.url} target="_blank" rel="noreferrer">
                    <img src={IconLinkedIn} alt="linkedin icon" />
                  </a>
                </div>
              </div>
            </div>
            <img src={post?.review_star_rating?.url} alt={post?.review_star_rating?.alt} className="h-4" />
            <BaseText fontWeight="text-bold my-2" title={post?.review_title?.text} />
            <BaseText title={post?.review_description?.text} />
            <div className="mt-2 flex gap-2 items-center mb-8">
              <img src={IconPin} alt="pin" />
              <XMSText className="capitalize" textColor="text-gray-450" title={post?.reviewer_location?.text} />
            </div>
          </div>
          <div className="md:w-10/12 -mb-16 bg-white rounded-2xl p-8 promo-header-card">
            <MDSubtitleText fontWeight="text-bold" title={post?.card_title?.text} />
            <div className="my-6">
              <div>
                {post?.card_points?.length > 0 &&
                  post?.card_points?.map((cardData, index) => {
                    return (
                      <div key={index}>
                        <div className="flex gap-4 mt-8 mb-4 items-center" key={index}>
                          <img className="w-6" src={IconCircleCheck} alt="IconCircleCheck" />
                          <BaseText title={cardData?.card_list?.text} />
                        </div>
                        {cardData?.show_currencies && (
                          <div className="flex gap-2 flex-wrap ml-10 md:mr-20">
                            {currenciesList.map((currency, index) => {
                              return (
                                <div
                                  key={index}
                                  className="border flex items-center justify-center border-gray-200 rounded-full w-10 h-10"
                                >
                                  <XSText fontWeight="text-bold" title={currency} />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            <PrimaryButton
              urlLink={post?.card_button_link?.url}
              className="w-full promo-header-button text-bold"
              fontSize="text-base"
              font
              caption={post?.card_button_caption?.text}
            />
            <a href={config.trust_pilot} target="_blank" rel="noopener noreferrer">
              <div className="flex flex-wrap md:gap-4 gap-5 mt-6 md:justify-around justify-center items-center text-gray-300 hover:text-coral-500">
                <div className="-mt-1">
                  <img src={TrustPilotLogo} className="trustpilot-logo w-28" alt="trustpilot logo" />
                </div>
                <div className="hidden md:block vl"></div>
                <div className="flex flex-col items-center md:flex-row md:gap-4">
                  <div className="mt-1 text-bold text-xl">Rated Excellent</div>
                  <img src={TrustPilotRating} className="h-5 promo-header-trustpilot-rating" alt="trustPilot rating" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

PromoHeader.propTypes = {
  post: PropTypes.object
};

export default PromoHeader;
