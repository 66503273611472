import React, { useState } from 'react';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { Link } from 'gatsby';
import IconLinkedIn from '../../images/icons/computer-logo-linkedin.svg';
import IconEmailAction from '../../images/icons/email-action-at.svg';
import BaseText from '../ui/typography/BaseText';
import DynamicModal from '../ui/modal/DynamicModal';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function editorNotes({ post, slice }) {
  const [openModal, setOpenModal] = useState(false);
  const authorImage = getImage(slice?.primary?.editors_note_author?.document?.data?.avatar?.gatsbyImageData);

  return (
    <div
      className={`my-8 bg-blue-200 p-6 rounded-lg ${
        post?.is_report_article_blog && 'padding-for-report-article mx-auto'
      }`}
    >
      <div className="flex justify-between">
        <MDSubtitleText
          fontWeight="text-bold"
          title={slice?.primary?.editors_note_title?.text ? slice?.primary?.editors_note_title?.text : 'Editor’s Note'}
        />
        {slice?.primary?.more_info_label?.text && (
          <div onClick={() => setOpenModal(true)}>
            <BaseText
              fontWeight="text-bold"
              textColor="text-coral-500 cursor-pointer"
              title={slice?.primary?.more_info_label?.text}
            />
          </div>
        )}
      </div>
      <div
        className="editors-note-description"
        dangerouslySetInnerHTML={{ __html: slice?.primary?.editors_note_description?.html }}
      />
      {slice?.primary?.editors_note_author?.document?.uid && (
        <div className="flex mt-12">
          <Link to={`/team/${slice?.primary?.editors_note_author?.document?.uid}`} className="w-16 mr-4">
            <GatsbyImage image={authorImage} className="w-16 h-16 rounded-full" alt="author img" loading="lazy" />
          </Link>
          <div className="editors-author-detail">
            <Link to={`/team/${slice?.primary?.editors_note_author?.document?.uid}`}>
              <div className="text-lg text-gray-500 hover:no-underline text-bold leading-5">
                {slice?.primary?.editors_note_author?.document?.data?.full_name?.text}
              </div>
              <div className="text-lg text-gray-500 hover:no-underline leading-5 my-1">{`${slice?.primary?.editors_note_author?.document?.data?.role?.text}`}</div>
            </Link>
            <div className="flex gap-2">
              <a href={slice?.primary?.editors_note_author?.document?.data?.email?.url}>
                <img src={IconEmailAction} alt="IconEmailAction" loading="lazy" />
              </a>
              <a
                href={slice?.primary?.editors_note_author?.document?.data?.linkedin?.url}
                target="_blank"
                rel="noreferrer"
              >
                <img src={IconLinkedIn} alt="IconLinkedIn" loading="lazy" />
              </a>
            </div>
          </div>
        </div>
      )}
      {slice?.primary?.more_info_label?.text && (
        <DynamicModal
          title={slice?.primary?.more_info_label?.text}
          openDynamicModal={openModal}
          closeDynamicModal={() => setOpenModal(false)}
          minWidth="576px"
        >
          <div dangerouslySetInnerHTML={{ __html: slice?.primary?.more_info_content?.html }}></div>
        </DynamicModal>
      )}
    </div>
  );
}
