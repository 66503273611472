import * as actionTypes from "./actionTypes";

let initialState = {
    companyData: {},
    companyInformation: {},
    mainStepsStatus: {},
    industriesList: {},
    countriesList: {},
    nationalitiesList: {},
    businessData: {},
    businessInformation: {},
    userInformation:{},
    createIndividualStakeHolder: {},
    getAllIndividualStakeHolder: {},
    getAllCompanyStakeHolder: {},
    ownersIndividualData: {},
    ownersCompanyData: {},
    createCompanyStakeHolder: {},
    createThirdPartyInfo: {},
    getAllThirdPartyInfo: {},
    deleteStakeHolderIndividual: {},
    deleteStakeHolderCompany: {},
    deleteThirdPartyInfo: {},
    getIndividualStakeHolder: {},
    getCompanyStakeHolder: {},
    updateIndividualStakeHolder: {},
    updateCompanyStakeHolder: {},
    updateThirdPartyStakeHolder: {},
    getCompanyInformation: {},
    uploadDocuments: {},
    createCompanyDocuments: {},
    createStakeholderDocuments: {},
    getBusinessInformation: {},
    getAllIndividualStakeHolderDocuments: {},
    getAllCompanyStakeHolderDocuments: {},
    getCompanyDocuments: {},
    applicationSubmission: null,
    completeStage: {},
    paymentCountriesList: {},
    countryDialCodeList:{},
    phoneOtpInformation: {},
    updatePhoneVerification: {},
    selectedIndustry: {},
    loading: false,
    error: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.COMPANY_DATA_REQUEST_SUCCESS:
            return { ...state, companyData: { ...state.companyData, ...action.companyData }, loading: false, error: false }

        case actionTypes.BUSINESS_DATA_SELECTED_INDUSTRY_SUCCESS:
            return { ...state, selectedIndustry: { ...state.selectedIndustry, ...action.selectedIndustry }, loading: false, error: false }

        case actionTypes.BUSINESS_DATA_RESET_SELECTED_INDUSTRY_SUCCESS:
            return { ...state, selectedIndustry: {}, loading: false, error: false }

        case actionTypes.COMPANY_INFORMATION_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.COMPANY_INFORMATION_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.COMPANY_INFORMATION_REQUEST_SUCCESS:
            return { ...state, companyInformation: { ...action.companyInformation }, loading: false, error: false };

        case actionTypes.STEPS_STATUS_REQUEST_SUCCESS:
            return { ...state, mainStepsStatus: { ...state.mainStepsStatus,...action.mainStepsStatus }, loading: false, error: false }

        case actionTypes.INDUSTRIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.INDUSTRIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.INDUSTRIES_LIST_REQUEST_SUCCESS:
            return { ...state, industriesList: { ...action.industriesList }, loading: false, error: false };

        case actionTypes.BUSINESS_DATA_REQUEST_SUCCESS:
            return { ...state, businessData: { ...state.businessData, ...action.businessData }, loading: false, error: false }

        case actionTypes.COUNTRIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.COUNTRIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.COUNTRIES_LIST_REQUEST_SUCCESS:
            return { ...state, countriesList: { ...action.countriesList }, loading: false, error: false };

        case actionTypes.NATIONALITIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.NATIONALITIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.NATIONALITIES_LIST_REQUEST_SUCCESS:
            return { ...state, nationalitiesList: { ...action.nationalitiesList }, loading: false, error: false };

        case actionTypes.BUSINESS_INFORMATION_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.BUSINESS_INFORMATION_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.BUSINESS_INFORMATION_REQUEST_SUCCESS:
            return { ...state, businessInformation: { ...action.businessInformation }, loading: false, error: false };

        case actionTypes.USER_INFORMATION_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.USER_INFORMATION_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.USER_INFORMATION_REQUEST_SUCCESS:
            return { ...state, userInformation: { ...action.userInformation }, loading: false, error: false };

        case actionTypes.CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, createIndividualStakeHolder: { ...action.createIndividualStakeHolder }, loading: false, error: false };

        case actionTypes.GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, getAllIndividualStakeHolder: { ...action.getAllIndividualStakeHolder }, loading: false, error: false };

        case actionTypes.GET_ALL_COMPANY_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_ALL_COMPANY_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_ALL_COMPANY_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, getAllCompanyStakeHolder: { ...action.getAllCompanyStakeHolder }, loading: false, error: false };

        case actionTypes.OWNERS_INDIVIDUAL_DATA_REQUEST_SUCCESS:
        return { ...state, ownersIndividualData: { ...state.ownersIndividualData, ...action.ownersIndividualData }, loading: false, error: false }

        case actionTypes.OWNERS_COMPANY_DATA_REQUEST_SUCCESS:
        return { ...state, ownersCompanyData: { ...state.ownersCompanyData, ...action.ownersCompanyData }, loading: false, error: false }

        case actionTypes.CREATE_COMPANY_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.CREATE_COMPANY_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.CREATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, createCompanyStakeHolder: { ...action.createCompanyStakeHolder }, loading: false, error: false };

        case actionTypes.CREATE_THIRD_PARTY_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.CREATE_THIRD_PARTY_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.CREATE_THIRD_PARTY_REQUEST_SUCCESS:
            return { ...state, createThirdPartyInfo: { ...action.createThirdPartyInfo }, loading: false, error: false };

        case actionTypes.GET_ALL_THIRD_PARTY_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_ALL_THIRD_PARTY_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_ALL_THIRD_PARTY_REQUEST_SUCCESS:
            return { ...state, getAllThirdPartyInfo: { ...action.getAllThirdPartyInfo }, loading: false, error: false };

        case actionTypes.DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_SUCCESS:
            return { ...state, deleteStakeHolderIndividual: { ...action.deleteStakeHolderIndividual }, loading: false, error: false };

        case actionTypes.DELETE_STAKEHOLDER_COMPANY_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.DELETE_STAKEHOLDER_COMPANY_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.DELETE_STAKEHOLDER_COMPANY_REQUEST_SUCCESS:
            return { ...state, deleteStakeHolderCompany: { ...action.deleteStakeHolderCompany }, loading: false, error: false };

        case actionTypes.DELETE_THIRD_PARTY_INFO_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.DELETE_THIRD_PARTY_INFO_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.DELETE_THIRD_PARTY_INFO_REQUEST_SUCCESS:
            return { ...state, deleteThirdPartyInfo: { ...action.deleteThirdPartyInfo }, loading: false, error: false };

        case actionTypes.GET_INDIVIDUAL_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, getIndividualStakeHolder: { ...action.getIndividualStakeHolder }, loading: false, error: false };

        case actionTypes.UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, updateIndividualStakeHolder: { ...action.updateIndividualStakeHolder }, loading: false, error: false };

        case actionTypes.GET_COMPANY_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_COMPANY_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_COMPANY_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, getCompanyStakeHolder: { ...action.getCompanyStakeHolder }, loading: false, error: false };

        case actionTypes.UPDATE_COMPANY_STAKEHOLDER_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.UPDATE_COMPANY_STAKEHOLDER_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.UPDATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS:
            return { ...state, updateCompanyStakeHolder: { ...action.updateCompanyStakeHolder }, loading: false, error: false };

        case actionTypes.UPDATE_THIRD_PARTY_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.UPDATE_THIRD_PARTY_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.UPDATE_THIRD_PARTY_REQUEST_SUCCESS:
            return { ...state, updateThirdPartyStakeHolder: { ...action.updateThirdPartyStakeHolder }, loading: false, error: false };

        case actionTypes.GET_COMPANY_INFORMATION_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_COMPANY_INFORMATION_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_COMPANY_INFORMATION_REQUEST_SUCCESS:
            return { ...state, companyData: { ...action.getCompanyInformation }, loading: false, error: false };

        case actionTypes.UPLOAD_DOCUMENTS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.UPLOAD_DOCUMENTS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.UPLOAD_DOCUMENTS_REQUEST_SUCCESS:
            return { ...state, uploadDocuments: { ...action.uploadDocuments }, loading: false, error: false };

        case actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_SUCCESS:
            return { ...state, createCompanyDocuments: { ...action.createCompanyDocuments }, loading: false, error: false };

        case actionTypes.CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.CREATE_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS:
            return { ...state, createStakeholderDocuments: { ...action.createStakeholderDocuments }, loading: false, error: false };

        case actionTypes.GET_BUSINESS_INFORMATION_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_BUSINESS_INFORMATION_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_BUSINESS_INFORMATION_REQUEST_SUCCESS:
            return { ...state, businessData: { ...action.getBusinessInformation }, loading: false, error: false };

        case actionTypes.GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS:
            return { ...state, getAllIndividualStakeHolderDocuments: { ...action.getAllIndividualStakeHolderDocuments }, loading: false, error: false };

        case actionTypes.GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS:
            return { ...state, getAllCompanyStakeHolderDocuments: { ...action.getAllCompanyStakeHolderDocuments }, loading: false, error: false };

        case actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS:
            return { ...state, getCompanyDocuments: { ...action.getCompanyDocuments }, loading: false, error: false };

        case actionTypes.APPLICATION_SUBMISSION_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.APPLICATION_SUBMISSION_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.APPLICATION_SUBMISSION_REQUEST_SUCCESS:
            return { ...state, applicationSubmission: { ...action.applicationSubmission }, loading: false, error: false };
        case actionTypes.APPLICATION_METADATA_SUBMISSION_REQUEST_SUCCESS:
            return { ...state, applicationSubmissionMetaData: { ...action.applicationSubmissionMetaData }, loading: false, error: false };

        case actionTypes.COMPLETE_STAGE_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.COMPLETE_STAGE_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.COMPLETE_STAGE_REQUEST_SUCCESS:
            return { ...state, completeStage: { ...action.completeStage }, loading: false, error: false };

        case actionTypes.PAYMENT_COUNTRIES_LIST_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.PAYMENT_COUNTRIES_LIST_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.PAYMENT_COUNTRIES_LIST_REQUEST_SUCCESS:
            return { ...state, paymentCountriesList: { ...action.paymentCountriesList }, loading: false, error: false };

        case actionTypes.COUNTRIES_DIAL_CODES_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.COUNTRIES_DIAL_CODES_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.COUNTRIES_DIAL_CODES_REQUEST_SUCCESS:
            return { ...state, countryDialCodeList: { ...action.countryDialCodeList }, loading: false, error: false };

        case actionTypes.PHONE_VALIDATION_OTPS_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.PHONE_VALIDATION_OTPS_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.PHONE_VALIDATION_OTPS_REQUEST_SUCCESS:
            return { ...state, phoneOtpInformation: { ...action.phoneOtpInformation }, loading: false, error: false };

        case actionTypes.UPADTE_PHONE_REQUEST_START:
            return { ...state, loading: true };
        case actionTypes.UPADTE_PHONE_REQUEST_FAIL:
            return { ...state, error: action.error, loading: false };
        case actionTypes.UPADTE_PHONE_REQUEST_SUCCESS:
            return { ...state, updatePhoneVerification: { ...action.updatePhoneVerification }, loading: false, error: false };
        default: return state;

    }
}
export default reducer;
