import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImageWrapper from '../GatsByImageWrapper';
import '../../../styles/pages/blogTemplate/blogCustomTable.scss';
const CustomTable = ({ slice }) => {
  return (
    <div className="my-8 blog-table blog-custom-table">
      <table style={{ width: '100%' }}>
        <tbody>
          {slice?.items.map((tableData, index) => {
            return (
              <tr
                key={index}
                className={`${tableData.is_heading && 'bg-coral-500 text-white'} ${
                  index < slice?.items.length - 1 && 'border-b border-coral-500'
                }`}
              >
                {(tableData?.column_one?.html || tableData?.column_one_image?.url) && (
                  <td style={{ width: slice?.primary?.column_one_width_percentage?.text }}>
                    {tableData?.column_one_image?.url ? (
                      <GatsbyImageWrapper
                        image={{
                          url: tableData?.column_one_image?.url,
                          width: tableData?.width_of_image ? tableData?.width_of_image : 100,
                          height: tableData?.height_of_image ? tableData?.height_of_image : 100
                        }}
                        alt={tableData?.column_one_image?.alt}
                        loading="lazy"
                        objectFit="contain"
                        width={tableData?.width_of_image ? tableData?.width_of_image : 100}
                        height={tableData?.height_of_image ? tableData?.height_of_image : 100}
                      />
                    ) : (
                      <div
                        style={{ marginBottom: 0, textAlign: tableData?.center_the_content && 'center' }}
                        className={tableData.is_heading && 'text-bold'}
                        dangerouslySetInnerHTML={{ __html: tableData?.column_one?.html }}
                      />
                    )}
                  </td>
                )}
                {(tableData?.column_two?.html || tableData?.column_two_image?.url) && (
                  <td style={{ width: slice?.primary?.column_two_width_percentage?.text }}>
                    {tableData?.column_two_image?.url ? (
                      <GatsbyImageWrapper
                        image={{
                          url: tableData?.column_two_image?.url,
                          width: tableData?.width_of_image ? tableData?.width_of_image : 100,
                          height: tableData?.height_of_image ? tableData?.height_of_image : 100
                        }}
                        alt={tableData?.column_two_image?.alt}
                        loading="lazy"
                        objectFit="contain"
                        width={tableData?.width_of_image ? tableData?.width_of_image : 100}
                        height={tableData?.height_of_image ? tableData?.height_of_image : 100}
                      />
                    ) : (
                      <div
                        style={{ marginBottom: 0, textAlign: tableData?.center_the_content && 'center' }}
                        className={tableData.is_heading && 'text-bold'}
                        dangerouslySetInnerHTML={{ __html: tableData?.column_two?.html }}
                      />
                    )}
                  </td>
                )}
                {(tableData?.column_three?.html || tableData?.column_three_image?.url) && (
                  <td style={{ width: slice?.primary?.column_three_width_percentage?.text }}>
                    {tableData?.column_three_image?.url ? (
                      <GatsbyImageWrapper
                        image={{
                          url: tableData?.column_three_image?.url,
                          width: tableData?.width_of_image ? tableData?.width_of_image : 100,
                          height: tableData?.height_of_image ? tableData?.height_of_image : 100
                        }}
                        alt={tableData?.column_three_image?.alt}
                        loading="lazy"
                        objectFit="contain"
                        width={tableData?.width_of_image ? tableData?.width_of_image : 100}
                        height={tableData?.height_of_image ? tableData?.height_of_image : 100}
                      />
                    ) : (
                      <div
                        style={{ marginBottom: 0, textAlign: tableData?.center_the_content && 'center' }}
                        className={tableData.is_heading && 'text-bold'}
                        dangerouslySetInnerHTML={{ __html: tableData?.column_three?.html }}
                      />
                    )}
                  </td>
                )}
                {(tableData?.column_four?.html || tableData?.column_four_image?.url) && (
                  <td style={{ width: slice?.primary?.column_four_width_percentage?.text }}>
                    {tableData?.column_four_image?.url ? (
                      <GatsbyImageWrapper
                        image={{
                          url: tableData?.column_four_image?.url,
                          width: tableData?.width_of_image ? tableData?.width_of_image : 100,
                          height: tableData?.height_of_image ? tableData?.height_of_image : 100
                        }}
                        alt={tableData?.column_four_image?.alt}
                        loading="lazy"
                        objectFit="contain"
                        width={tableData?.width_of_image ? tableData?.width_of_image : 100}
                        height={tableData?.height_of_image ? tableData?.height_of_image : 100}
                      />
                    ) : (
                      <div
                        style={{ marginBottom: 0, textAlign: tableData?.center_the_content && 'center' }}
                        className={tableData.is_heading && 'text-bold'}
                        dangerouslySetInnerHTML={{ __html: tableData?.column_four?.html }}
                      />
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  slice: PropTypes.array
};
