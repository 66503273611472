export const GENERATE_RESET_PASSWORD_OTP_START = 'GENERATE_RESET_PASSWORD_OTP_START';
export const GENERATE_RESET_PASSWORD_OTP_FAIL = 'GENERATE_RESET_PASSWORD_OTP_FAIL';
export const GENERATE_RESET_PASSWORD_OTP_SUCCESS = 'GENERATE_RESET_PASSWORD_OTP_SUCCESS';

export const VERIFY_RESET_PASSWORD_OTP_START = 'VERIFY_RESET_PASSWORD_OTP_START';
export const VERIFY_RESET_PASSWORD_OTP_FAIL = 'VERIFY_RESET_PASSWORD_OTP_FAIL';
export const VERIFY_RESET_PASSWORD_OTP_SUCCESS = 'VERIFY_RESET_PASSWORD_OTP_SUCCESS';

export const RESET_PASSWORD_ACTION_START = 'RESET_PASSWORD_ACTION_START';
export const RESET_PASSWORD_ACTION_FAIL = 'RESET_PASSWORD_ACTION_FAIL';
export const RESET_PASSWORD_ACTION_SUCCESS = 'RESET_PASSWORD_ACTION_SUCCESS';
