import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getExampleImageData } from '../../helper/helpers';
import PropTypes from 'prop-types';

export default function GatsbyImageWrapper({
  image,
  width,
  height,
  layout,
  backgroundColor,
  sizes,
  aspectRatio,
  options,
  formats,
  objectFit,
  ...props
}) {
  const imageData = getExampleImageData({
    image,
    width,
    height,
    layout,
    backgroundColor,
    sizes,
    aspectRatio,
    options,
    formats
  });

  return <GatsbyImage image={imageData} {...props} objectFit={objectFit} />;
}

GatsbyImageWrapper.propTypes = {
  image: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  layout: PropTypes.string,
  backgroundColor: PropTypes.string,
  sizes: PropTypes.any,
  aspectRatio: PropTypes.any,
  options: PropTypes.any,
  formats: PropTypes.array,
  objectFit: PropTypes.string
};
GatsbyImageWrapper.defaultProps = {
  formats: ['auto', 'webp'],
  objectFit: 'cover'
};
