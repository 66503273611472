import React from 'react';
const cross = ({ stroke }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 23.5L23.5 8.5"
        stroke={`${stroke ? stroke : '#2D0D45'}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 23.5L8.5 8.5"
        stroke={`${stroke ? stroke : '#2D0D45'}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default cross;
