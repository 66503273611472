import React from 'react';
import BaseText from '../ui/typography/BaseText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import LeaveReview from './LeaveReview';
import StarRating from '../ui/star-rating/StarRating';

export default function companyDetails({ reviewData }) {
  const reviewDetails = reviewData?.data;
  const services = reviewDetails?.servicelist?.text?.split(',');
  const countReducer = (accumulator, currentValue) => accumulator + currentValue;
  let totalRating;
  if (reviewDetails?.avearge?.length > 0) {
    totalRating = reviewDetails?.avearge
      .map((review) => {
        if (!isNaN(review?.rating)) {
          return review?.rating;
        }
      })
      .reduce(countReducer);
  } else {
    totalRating = 0;
  }
  const isLastCustomerReview = (index) => {
    return index === reviewDetails?.customer_review?.length - 1;
  };
  function removeMailtoPrefix(inputString) {
    return inputString.replace(/^mailto:/i, '');
  }
  return (
    <div className="mt-12 flex md:flex-row flex-col md:justify-between justify-center pb-16">
      <div className="company-sec-right-info md:mx-0 mx-auto">
        {reviewDetails?.long_description?.text ? (
          <div className="border-gray-400 border px-8 pt-8 pb-4 bg-white rounded-lg mb-4">
            <MDSubtitleText fontWeight="text-bold" className="mb-6" title={`About ${reviewDetails?.title?.text}`} />
            <div className="blog">
              <BaseText
                className="company-sec-article"
                title={<div dangerouslySetInnerHTML={{ __html: reviewDetails?.long_description?.html }}></div>}
              />
            </div>
          </div>
        ) : (
          <BaseText
            className={`${reviewDetails?.description?.text && 'mt-6 mb-16 blog'}`}
            title={reviewDetails?.description?.text}
          />
        )}
        {!totalRating && (
          <div className="w-full flex flex-col bg-yellow-400 rounded-lg p-8 lg:gap-4 gap-10 mb-4">
            <div className="flex gap-4">
              <div className="w-8">
                <img src="../../../yellowInfo.svg" alt={'IconInformationCircle'} className="w-8" />
              </div>
              <div className="w-full">
                <BaseText fontWeight="text-bold" title={'This Company Secretary does not have any reviews yet.'} />
                <BaseText className="my-4" fontWeight="text-bold" title="Why is that?" />
                <BaseText
                  title={
                    <div>
                      The absence of reviews may have two different causes:
                      <ul className="list-disc mx-6 mt-4">
                        <li>No Statrys client has ever used this Company Secretary&apos;s services;</li>
                        or
                        <li>
                          One or more Statrys clients are using this Company Secretary&apos;s services, but none are
                          willing to provide a review.
                        </li>
                      </ul>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        )}
        {!reviewDetails?.long_description?.text && !reviewDetails?.description?.text && totalRating && (
          <div className="w-full flex flex-col bg-yellow-400 rounded-lg p-8 lg:gap-4 gap-10 mb-4">
            <BaseText fontWeight="text-bold" title={`About ${reviewDetails?.title?.text}`} />
            <div className="flex gap-4">
              <div className="w-8">
                <img src="../../../yellowInfo.svg" alt={'IconInformationCircle'} className="w-8" />
              </div>
              <div className="w-full">
                <BaseText
                  fontWeight="text-bold"
                  className="mr-6"
                  title={`No other information about ${reviewDetails?.title?.text}’s services is available.`}
                />
                <BaseText className="my-4" fontWeight="text-bold" title="Why is that?" />
                <BaseText
                  className="mr-6"
                  title={
                    'The Company Secretary has disregarded our request to obtain information about the scope of their services. We will update the page as soon as the information is received.'
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`${
            reviewDetails?.customer_review?.[0]?.review_description?.text ||
            reviewDetails?.customer_review?.[0]?.customer_name?.text
              ? 'block'
              : 'hidden'
          } border border-gray-400 rounded-lg p-8 w-full bg-white mb-4`}
        >
          <MDSubtitleText fontWeight="text-bold mb-5" title="What People Say" />
          {reviewDetails?.customer_review?.map((reviewData, index) => {
            return (
              <div key={index}>
                <StarRating value={reviewData?.rating_value} />
                <BaseText
                  className="mt-4 review-description-line-height"
                  title={reviewData?.review_description?.text}
                />
                <BaseText fontWeight="text-bold" className="mt-4 capitalize" title={reviewData?.customer_name?.text} />
                <XSText className="mt-1" title={reviewData?.published_on} />
                {!isLastCustomerReview(index) && <hr className="company-info-divider my-4" />}
              </div>
            );
          })}
        </div>
        <LeaveReview companySecName={reviewDetails?.title?.text} />
      </div>
      <div>
        <div className="company-sec-left-info md:mx-0 mx-auto">
          <div className="p-6">
            <MDSubtitleText className="mb-8" fontWeight="text-bold" title="Company Info" />
            <div>
              {reviewDetails?.website?.url ? (
                <a target="_blank" rel="noreferrer" href={reviewDetails?.website?.url}>
                  <div className="flex gap-2 items-center">
                    <img src="../../../house-chimney.svg" alt="IconHouseChimney" />
                    <XSText
                      textColor="text-coral-500"
                      title={
                        reviewDetails?.company_website?.text
                          ? reviewDetails?.company_website?.text
                          : reviewDetails?.website?.url
                      }
                    />
                  </div>
                </a>
              ) : (
                <div className="flex gap-2 items-center">
                  <img src="../../../house-chimney.svg" alt="IconHouseChimney" />
                  <XSText title={'Information not provided'} />
                </div>
              )}
              <hr className="company-info-divider my-4" />
            </div>
            <div>
              {reviewDetails?.email?.url ? (
                <a target="_blank" rel="noreferrer" href={`mailto:${removeMailtoPrefix(reviewDetails?.email?.url)}`}>
                  <div className="flex gap-2 items-center">
                    <img src="../../../email-action-at-black.svg" alt="IconEmailAction" />
                    <XSText textColor="text-coral-500" title={removeMailtoPrefix(reviewDetails?.email?.url)} />
                  </div>
                </a>
              ) : (
                <div className="flex gap-2 items-center">
                  <img src="../../../email-action-at-black.svg" alt="IconEmailAction" />
                  <XSText title={'Information not provided'} />
                </div>
              )}
              <hr className="company-info-divider my-4" />
            </div>
            <div>
              {reviewDetails?.phone_number?.text ? (
                <a target="_blank" rel="noreferrer" href={`tel:${reviewDetails?.phone_number?.text}`}>
                  <div className="flex gap-2 items-center">
                    <img src="../../../phone.svg" alt="IconPhone" />
                    <XSText textColor="text-coral-500" title={reviewDetails?.phone_number?.text} />
                  </div>
                </a>
              ) : (
                <div className="flex gap-2 items-center">
                  <img src="../../../phone.svg" alt="IconPhone" />
                  <XSText title={'Information not provided'} />
                </div>
              )}
              <hr className="company-info-divider my-4" />
            </div>
            <div>
              <div className="flex gap-2">
                <img src="../../../location.svg" alt="IconPinLocation" />
                <XSText
                  title={
                    reviewDetails?.company_address?.text
                      ? reviewDetails?.company_address?.text
                      : 'Information not provided'
                  }
                />
              </div>
              <hr className="company-info-divider my-4" />
            </div>
            <div>
              <div className="flex gap-2">
                <img src="../../../translate-black.svg" className="w-4 h-4" alt="IconTranslate" />
                <XSText
                  title={
                    reviewDetails?.language?.text
                      ? reviewDetails?.language?.text?.split(',').join(', ')
                      : 'Information not provided'
                  }
                />
              </div>
            </div>
            {reviewDetails?.website?.url ? (
              <div className="mt-8">
                <a
                  rel="nofollow"
                  className="bg-coral-500 rounded-lg text-base px-6 py-3 text-white hover:text-white hover:no-underline"
                  href={reviewDetails?.website?.url}
                >
                  Contact
                </a>
              </div>
            ) : (
              <PrimaryButton
                urlLink={reviewDetails?.website?.url ? reviewDetails?.website?.url : '/contact'}
                type="signup"
                className="mt-8 w-auto"
                caption="Contact"
              />
            )}
          </div>
          <hr className="company-info-divider my-2" />
          <div className="p-6">
            <MDSubtitleText fontWeight="text-bold" className="mb-6" title="Services" />
            <div className="w-full flex flex-row flex-wrap justify-start items-start gap-1">
              {services?.[0]?.length > 0 ? (
                services
                  ?.filter((i) => i.length > 0)
                  ?.map((item, idx) => (
                    <span className="company-sec-filtered md:w-auto px-4 py-2" key={'_' + idx}>
                      {item}
                    </span>
                  ))
              ) : (
                <div>
                  <BaseText title="Information not provided" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
