import React from 'react';
import PropTypes from 'prop-types';

function CustomHtml({ content }) {
  return <div className="mx-auto" dangerouslySetInnerHTML={{ __html: content?.custom_html?.text }}></div>;
}

CustomHtml.propTypes = {
  content: PropTypes?.object
};

export default CustomHtml;
