import React from 'react';
import PropTypes from 'prop-types';

function H1HeaderText({ title, className, textColor, textAlign, fontWeight, fontSize, style }) {
  return (
    <div className={`${className}`}>
      <h1 className={` ${fontWeight} ${textColor} ${textAlign} ${fontSize}`} style={style}>
        {title}
      </h1>
    </div>
  );
}

export default H1HeaderText;

H1HeaderText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.string,
  style: PropTypes.object
};

H1HeaderText.defaultProps = {
  textColor: 'text-coral-500',
  textAlign: 'text-left',
  fontWeight: 'text-bold',
  fontSize: ''
};
