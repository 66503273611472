import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../constants/enum';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BaseText from '../ui/typography/BaseText';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function LinkCta({ slice, key, post }) {
  const stringWithN = slice?.primary?.linked_cta?.document?.data?.description1.text.split('/n');
  return (
    <div
      key={key}
      style={{
        backgroundImage:
          slice?.primary?.linked_cta?.document?.data?.background_image?.url &&
          `url('${slice?.primary?.linked_cta?.document?.data?.background_image?.url}')`,
        backgroundPosition: 'center',
        backgroundColor: slice?.primary?.linked_cta?.document?.data?.background_color
          ? slice?.primary?.linked_cta?.document?.data?.background_color
          : MUI_COLORS.WHITE_SMOKE,
        objectFit: 'cover',
        minHeight: '340px'
      }}
      className={`my-8 rounded-lg ${post?.is_report_article_blog && 'padding-for-report-article mx-auto'}`}
    >
      <div
        className={`flex md:flex-row flex-col md:gap-0 gap-4 md:${
          slice?.primary?.linked_cta?.document?.data?.cta_image?.url ? 'pl-8' : 'px-8'
        } ${slice?.primary?.linked_cta?.document?.data?.cta_image?.url ? 'px-auto' : ''}  items-center justify-between`}
      >
        <div
          className={`md:w-10/12 w-full md:px-0 px-4 md:pt-0 pt-4  ${
            !slice?.primary?.linked_cta?.document?.data?.cta_image?.url ? 'mb-6' : 'mb-0'
          }`}
        >
          <BaseText
            title={slice?.primary?.linked_cta?.document?.data?.title1?.text}
            fontWeight="text-bold"
            style={{
              color: slice?.primary?.linked_cta?.document?.data?.text_color
                ? slice?.primary?.linked_cta?.document?.data?.text_color
                : MUI_COLORS.CHARCOAL,
              fontSize: slice?.primary?.linked_cta?.document?.data?.title_font_size
                ? slice?.primary?.linked_cta?.document?.data?.title_font_size
                : '32px',
              lineHeight: '110%'
            }}
          />
          <div className="mb-4">
            {stringWithN?.map((description, index) => {
              return (
                <BaseText
                  key={index}
                  title={description.replace(/\n/g, '')}
                  className="whitespace-pre-line blog-cta-description"
                  style={{
                    color: slice?.primary?.linked_cta?.document?.data?.text_color
                      ? slice?.primary?.linked_cta?.document?.data?.text_color
                      : MUI_COLORS.CHARCOAL
                  }}
                />
              );
            })}
          </div>
          <div className="flex md:flex-row flex-col md:gap-4 gap-2">
            <PrimaryButton
              urlLink={slice?.primary?.linked_cta?.document?.data?.button_url?.url}
              caption={slice?.primary?.linked_cta?.document?.data?.cta_button?.text}
              className={`${
                slice?.primary?.linked_cta?.document?.data?.cta_image?.url ? 'block' : 'hidden'
              }  md:mb-6 mb-0 w-full`}
              type="medium-button"
              target="_blank"
              isBorderRequired={true}
              style={{
                backgroundColor: slice?.primary?.linked_cta?.document?.data?.button_color
                  ? slice?.primary?.linked_cta?.document?.data?.button_color
                  : MUI_COLORS.CORAL,
                color: slice?.primary?.linked_cta?.document?.data?.button_text_color
                  ? slice?.primary?.linked_cta?.document?.data?.button_text_color
                  : MUI_COLORS.WHITE,
                height: 56
              }}
            />
            {slice?.primary?.linked_cta?.document?.data?.secondary_button_url?.url &&
              slice?.primary?.linked_cta?.document?.data?.secondary_button?.text && (
                <PrimaryButton
                  urlLink={slice?.primary?.linked_cta?.document?.data?.secondary_button_url?.url}
                  caption={slice?.primary?.linked_cta?.document?.data?.secondary_button?.text}
                  className={`${
                    slice?.primary?.linked_cta?.document?.data?.cta_image?.url ? 'block' : 'hidden'
                  } md:mb-6 mb-0 w-full`}
                  type="medium-button"
                  target="_blank"
                  isBorderRequired={true}
                  style={{
                    backgroundColor: slice?.primary?.linked_cta?.document?.data?.secondary_button_color
                      ? slice?.primary?.linked_cta?.document?.data?.secondary_button_color
                      : MUI_COLORS.CORAL,
                    color: slice?.primary?.linked_cta?.document?.data?.secondary_text_color
                      ? slice?.primary?.linked_cta?.document?.data?.secondary_text_color
                      : MUI_COLORS.WHITE,
                    height: 56
                  }}
                />
              )}
          </div>
        </div>

        {slice?.primary?.linked_cta?.document?.data?.cta_image?.url && (
          <GatsbyImageWrapper
            image={{
              url: slice?.primary?.linked_cta?.document?.data?.cta_image?.url,
              width: 340,
              height: 340
            }}
            alt={slice?.primary?.linked_cta?.document?.data?.cta_image?.alt}
            className="blog-cta-image self-end"
            loading="lazy"
          />
        )}

        {!slice?.primary?.linked_cta?.document?.data?.cta_image?.url && (
          <PrimaryButton
            urlLink={slice?.primary?.linked_cta?.document?.data?.button_url?.url}
            caption={slice?.primary?.linked_cta?.document?.data?.cta_button?.text}
            className="md:w-28 w-full md:mb-0 mb-4 "
            type="medium-button"
            target="_blank"
            isBorderRequired={true}
            style={{
              backgroundColor: slice?.primary?.linked_cta?.document?.data?.button_color
                ? slice?.primary?.linked_cta?.document?.data?.button_color
                : MUI_COLORS.CORAL,
              color: slice?.primary?.linked_cta?.document?.data?.button_text_color
                ? slice?.primary?.linked_cta?.document?.data?.button_text_color
                : MUI_COLORS.WHITE
            }}
          />
        )}
      </div>
    </div>
  );
}

LinkCta.propTypes = {
  slice: PropTypes?.object,
  key: PropTypes?.number,
  post: PropTypes?.object
};
