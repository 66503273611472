import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../constants/enum';

const modal = {
  position: 'fixed',
  zIndex: 1000,
  left: '0',
  right: '0',
  top: '0',
  width: 'auto',
  height: '100vh',
  overflow: 'auto',
  background: 'rgba(61, 39, 39, 0.5)'
};

const close = {
  position: 'fixed',
  top: 0,
  right: 16,
  color: MUI_COLORS?.WHITE,
  fontSize: 32,
  cursor: 'pointer'
};

const modalContent = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  margin: 'auto'
};

export const ModalContent = ({ onClose, children }) => {
  return (
    <div style={modal} onClick={onClose}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
      <div style={modalContent}>{children}</div>
    </div>
  );
};

ModalContent.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any
};
